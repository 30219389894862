import { CounterMetric } from '@teamflow/lib'

/**
 * Metrics for call client problems.
 *
 * @namespace
 */
export const metrics = {
    /**
     * Interrupted socket connections
     */
    socketClosed: new CounterMetric(),
}
