import Head from 'next/head'

export const TransactionTraceparentHeader = ({
    traceparent,
}: {
    traceparent?: string
}) => {
    if (!traceparent) return null

    return (
        <Head>
            {/*
                https://www.w3.org/TR/trace-context/
                Set the `traceparent` in the server's HTML template code. It should be
                dynamically generated server side to have the server's request trace Id,
                a parent span Id that was set on the server's request span, and the trace
                flags to indicate the server's sampling decision
                (01 = sampled, 00 = notsampled).
                '{version}-{traceId}-{spanId}-{sampleDecision}'
                */}
            <meta
                key="traceparent"
                name="traceparent"
                content={traceparent}
            ></meta>
        </Head>
    )
}
