import { useCallback, useEffect, useState } from 'react'

import { Events } from '@teamflow/types'

import { sharedInstance as webAudio } from '../../../../verse/audio/WebAudioService'

export const useWebAudioSuspended = () => {
    const [suspended, setSuspended] = useState(webAudio.isSuspended)
    const setResumed = useCallback(() => setSuspended(false), [])
    useEffect(() => {
        webAudio.on(Events.ContextResumed, setResumed)
        return () => {
            webAudio.off(Events.ContextResumed, setResumed)
        }
    }, [setResumed])
    return suspended
}
