import { memo } from 'react'

import { Modal, ModalContent, ModalHeader } from '@teamflow/design'
import { AVDeviceError } from '@teamflow/types'

import { BrowserType } from '../helpers/getBrowserName'

import { Content } from './Content'
import { Title } from './Title'

interface Props {
    open: boolean
    permissionsPending: boolean
    browser: BrowserType
    micErrorCode?: AVDeviceError
    camErrorCode?: AVDeviceError
    onDismiss: () => void
}

const modalContentStyle = {
    maxWidth: 524,
    minHeight: 160,
}

export const ModalComponent = memo(
    ({
        open,
        browser,
        permissionsPending,
        micErrorCode,
        camErrorCode,
        onDismiss,
    }: Props) => (
        <Modal
            open={open}
            onClose={onDismiss}
            verticallyCentered
            isContentScrollable={false}
        >
            <ModalContent
                data-testid={
                    permissionsPending
                        ? 'av-request-modal'
                        : 'av-permissions-modal'
                }
                style={modalContentStyle}
            >
                <ModalHeader
                    closeButtonTestId={
                        permissionsPending
                            ? 'av-request-modal-dismiss'
                            : 'av-permissions-modal-dismiss'
                    }
                >
                    <Title
                        permissionsPending={permissionsPending}
                        micErrorCode={micErrorCode}
                        camErrorCode={camErrorCode}
                    />
                </ModalHeader>
                <Content
                    browser={browser}
                    permissionsPending={permissionsPending}
                    micErrorCode={micErrorCode}
                    camErrorCode={camErrorCode}
                    onDismiss={onDismiss}
                />
            </ModalContent>
        </Modal>
    )
)
