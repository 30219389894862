import { Room as LiveKitRoom, Track } from 'livekit-client'

import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import { getActualDeviceId } from '../helpers/getActualDeviceId'

import { deselectSpecialEffectsCamera } from './deselectSpecialEffectsCamera'

export async function getUpdatedInputDevices(
    room: LiveKitRoom | undefined,
    type: 'initial' | 'selected' = 'selected'
) {
    const inputDevices: t.AVInputDevices = {
        micId: rootStore.audioVideo.micId,
        cameraId: rootStore.audioVideo.cameraId,
    }
    if (!room) return inputDevices

    const micTrack = room.localParticipant.getTrack(Track.Source.Microphone)
    let micId = (await micTrack?.audioTrack?.getDeviceId()) ?? null

    const cameraTrack = room.localParticipant.getTrack(Track.Source.Camera)
    let cameraId = (await cameraTrack?.videoTrack?.getDeviceId()) ?? null

    const audioinputDevices = await LiveKitRoom.getLocalDevices('audioinput')
    const videoinputDevices = await LiveKitRoom.getLocalDevices('videoinput')

    if (cameraId && type === 'initial') {
        cameraId = await deselectSpecialEffectsCamera(
            room,
            videoinputDevices,
            cameraId
        )
    }

    // make sure we have the actual id of the device
    micId = getActualDeviceId(micId, 'audioinput', audioinputDevices)
    cameraId = getActualDeviceId(cameraId, 'videoinput', videoinputDevices)

    if (micId && micId !== rootStore.audioVideo.micId) {
        inputDevices.micId = micId
    }
    if (cameraId && cameraId !== rootStore.audioVideo.cameraId) {
        inputDevices.cameraId = cameraId
    }

    return inputDevices
}
