import { useCallback, useState } from 'react'

import { ModalComponent } from '../../../AVPermissionsModal/components/ModalComponent'

export const AVSetupRequestModal = () => {
    const [dismissed, setDismissed] = useState(false)

    const handleDismiss = useCallback(() => setDismissed(true), [])

    return (
        <ModalComponent
            open={!dismissed}
            browser="chrome"
            onDismiss={handleDismiss}
            permissionsPending
        />
    )
}
