import { DailyCall } from '@daily-co/daily-js'

import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import { getActualDeviceId } from '../helpers/getActualDeviceId'

import { deselectSpecialEffectsCamera } from './deselectSpecialEffectsCamera'
import { getCallInputDevices } from './getCallInputDevices'

export async function getUpdatedInputDevices(
    call: DailyCall | undefined | null,
    type: 'initial' | 'selected' = 'selected'
): Promise<t.AVInputDevices> {
    if (!call || !navigator.mediaDevices)
        return {
            micId: rootStore.audioVideo.micId,
            cameraId: rootStore.audioVideo.cameraId,
        }

    const devices = await navigator.mediaDevices.enumerateDevices()

    let { micId, cameraId } = await getCallInputDevices(call)

    if (cameraId && type === 'initial') {
        cameraId = await deselectSpecialEffectsCamera(call, devices, cameraId)
    }

    // make sure we have the actual id of the device
    micId =
        getActualDeviceId(micId, 'audioinput', devices) ??
        rootStore.audioVideo.micId
    cameraId =
        getActualDeviceId(cameraId, 'videoinput', devices) ??
        rootStore.audioVideo.cameraId

    return {
        micId,
        cameraId,
    }
}
