import { debounce, DebouncedFunc } from 'lodash'

import { RateLimit } from '@teamflow/lib'
import rootStore from '@teamflow/store'

import { MediaPlaybackManager } from './MediaPlaybackManager'

const dummySetSinkId = () => Promise.resolve()

let sinkIdSupported = false

let actualSetSinkId: (sinkId: string) => Promise<void> = () => Promise.resolve()

/**
 * Prevent Daily from calling setSinkId by replacing it with a dummy function
 * and making actualSetSinkId point to the original function.
 */
function setUpSinkIdHack() {
    sinkIdSupported = 'setSinkId' in (HTMLMediaElement as any).prototype
    if (!sinkIdSupported) return

    // no-op if already set up
    if ((HTMLMediaElement as any).prototype.setSinkId === dummySetSinkId) return

    actualSetSinkId = (HTMLMediaElement as any).prototype.setSinkId
    ;(HTMLMediaElement as any).prototype.setSinkId = dummySetSinkId
}

// Used to limit audio plays to mitigate CPU spikes. A small
// interval should be sufficient to spread out the CPU cost.
// Some burst is nice for the typical case with a few streams.
let _audioPlayLimiter: RateLimit | undefined

/**
 * Delaying calling RateLimit constructor to avoid SSR issues.
 */
function getAudioPlayLimiter() {
    if (!_audioPlayLimiter) {
        _audioPlayLimiter = new RateLimit({
            burst: 5,
            ratePerInterval: 1,
            intervalMillis: 10,
            maxQueuedRequests: Infinity,
        })
    }

    return _audioPlayLimiter
}

export function resetAudioPlayLimiter() {
    getAudioPlayLimiter().reset()
}

/**
 * Time to wait before retrying setSinkId() if it gets aborted.
 */
const SET_SINK_ID_DELAY = 1000

/**
 * In addition to what MediaPlaybackManager does, this class exposes
 * changeVolume() and setSinkId(). Under the hood, setSinkId() is async,
 * so this class relies on `MediaPlaybackManager` to make sure that play() and
 * setSinkId() are never called at the same time, and that only one setSinkId()
 * is in progress at a time.
 *
 * Ideally, changeVolume() and setSinkId() should both be called to initialize
 * the audio element before changeTrack() is called (which calls play()).
 */
export class AudioPlaybackManager extends MediaPlaybackManager<HTMLAudioElement> {
    private pendingSinkId: string | null = null

    private readonly debouncedSetSinkId: DebouncedFunc<(sinkId: string) => void>

    constructor(
        audioEl: HTMLAudioElement,
        autoplay: boolean,
        participantId: string | undefined,
        type: 'screen' | 'participant' | 'sfx'
    ) {
        super(audioEl, autoplay, getAudioPlayLimiter(), {
            action: 'Audio@Play',
            peer: participantId,
            type,
        })

        this.debouncedSetSinkId = debounce(
            this.setSinkId.bind(this),
            SET_SINK_ID_DELAY,
            { leading: false, trailing: true }
        )
        this.disposers.push(() => {
            this.debouncedSetSinkId.cancel()
        })

        setUpSinkIdHack()
    }

    /**
     * Sets the `muted` and `volume` properties of `mediaEl` according to `volume`.
     */
    changeVolume(volume: number) {
        if (volume < 0) volume = 0
        if (volume > 1) volume = 1

        this.mediaEl.muted = volume === 0
        this.mediaEl.volume = volume
    }

    protected flushPending() {
        if (this.pendingSinkId != null) {
            const sinkId = this.pendingSinkId
            this.pendingSinkId = null
            void this.setSinkId(sinkId)
        } else {
            super.flushPending()
        }
    }

    setSinkId(sinkId: string) {
        this.debouncedSetSinkId.cancel()

        if (this.busy) {
            this.pendingSinkId = sinkId
            return
        }

        // using .then to avoid making this function async
        // (to abstract away the race condition handling)
        void this.attemptSetSinkId(sinkId).then(() => {
            if (this.canceled) return
            this.flushPending()
        })
    }

    get sinkIdSupported() {
        return sinkIdSupported
    }

    /**
     * This function will resolve even if an error occurs.
     */
    private async attemptSetSinkId(sinkId: string) {
        this.logger.debug('Calling setSinkId', sinkId)

        this.busy = true

        try {
            await actualSetSinkId.call(this.mediaEl, sinkId)
        } catch (error: any) {
            if (this.canceled) return

            if (this.isSinkIdAbortError(error)) {
                // this error can happen if `mediaEl` is not ready to be played,
                // so we'll retry again later
                this.logger.error(
                    'Abort error while calling setSinkId - retrying after a delay',
                    sinkId,
                    error?.message
                )

                this.debouncedSetSinkId(sinkId)
            } else {
                // otherwise, the device isn't found or playback isn't allowed
                this.logger.error(
                    'Error while calling setSinkId',
                    sinkId,
                    error?.message
                )

                rootStore.audioVideo.addProblem({
                    message: `Audio output error (${error?.message})`,
                    link: 'settings',
                    throttleMs: 5000,
                })
            }
        }

        this.busy = false
    }

    private isSinkIdAbortError(error: any) {
        return error instanceof DOMException && error.name === 'AbortError'
    }
}
