import { LogManager } from '@teamflow/lib'

const logger = LogManager.createLogger('traceMediaTrack')

const _audioTracks = new Set<string>()
const _videoTracks = new Set<string>()

export function getCurrentAudioTracks() {
    return [..._audioTracks]
}

export function getCurrentVideoTracks() {
    return [..._videoTracks]
}

export function traceMediaTracks() {
    if (typeof navigator === 'undefined' || !navigator.mediaDevices) {
        return
    }

    logger.info({
        action: 'TraceMediaTracks@Initialize',
    })

    const originalStop = MediaStreamTrack.prototype.stop

    MediaStreamTrack.prototype.stop = function (...args) {
        logger.info({
            action: 'TraceMediaTracks@Stop',
            message: 'Media stream track stopped',
            kind: this.kind,
            id: this.id,
        })

        if (this.kind === 'video') {
            _videoTracks.delete(this.id)
        }

        if (this.kind === 'audio') {
            _audioTracks.delete(this.id)
        }

        originalStop.call(this, ...args)
    }

    const originalGetUserMedia = navigator.mediaDevices.getUserMedia

    navigator.mediaDevices.getUserMedia = async function (...args) {
        const stream = await originalGetUserMedia.call(this, ...args)

        stream.getTracks().forEach((track) => {
            logger.info({
                action: 'TraceMediaTracks@Create',
                message: 'Media stream track created',
                kind: track.kind,
                id: track.id,
            })

            if (track.kind === 'video') {
                _videoTracks.add(track.id)
            }

            if (track.kind === 'audio') {
                _audioTracks.add(track.id)
            }
        })

        return stream
    }
}
