import { useEffect, useState } from 'react'

import { RateLimit } from '@teamflow/lib'

import { MediaPlaybackManager } from '../helpers/MediaPlaybackManager'

// Used to limit video plays to mitigate CPU spikes. A small
// interval should be sufficient to spread out the CPU cost.
// Some burst is nice for the typical case with a few streams.
let _videoPlayLimiter: RateLimit | undefined

/**
 * Delaying calling RateLimit constructor to avoid SSR issues.
 */
function getVideoPlayLimiter() {
    if (!_videoPlayLimiter) {
        _videoPlayLimiter = new RateLimit({
            burst: 5,
            ratePerInterval: 1,
            intervalMillis: 10,
            maxQueuedRequests: Infinity,
        })
    }

    return _videoPlayLimiter
}

export function resetVideoPlayLimiter() {
    getVideoPlayLimiter().reset()
}

/**
 * In addition to what MediaPlaybackManager does, this class sets some
 * video-specific properties on the media element.
 */
class VideoPlaybackManager extends MediaPlaybackManager<HTMLVideoElement> {
    constructor(
        videoEl: HTMLVideoElement,
        participantId: string,
        isScreen: boolean
    ) {
        super(videoEl, true, getVideoPlayLimiter(), {
            action: 'Video@Play',
            peer: participantId,
            type: isScreen ? 'screen' : 'participant',
        })
    }

    protected setupMediaEl() {
        super.setupMediaEl()

        // we render audio in a separate element
        // (also, a muted element should be allowed to autoplay)
        this.mediaEl.muted = true
        // don't make the video go full screen on mobile devices
        this.mediaEl.playsInline = true
    }
}

export function useVideoAutoPlay({
    videoEl,
    track,
    participantId,
    isScreen,
}: {
    videoEl: HTMLVideoElement | null | void
    track: MediaStreamTrack | undefined
    // for logging
    participantId: string
    isScreen: boolean
}) {
    const [manager, setManager] = useState<VideoPlaybackManager | null>(null)

    useEffect(() => {
        if (!videoEl) {
            setManager(null)
            return
        }

        const newManager = new VideoPlaybackManager(
            videoEl,
            participantId,
            isScreen
        )
        setManager(newManager)

        return () => {
            newManager.destroy()
        }
    }, [isScreen, participantId, videoEl])

    useEffect(() => {
        if (manager) {
            manager.changeTrack(track)
        }
    }, [manager, track])
}
