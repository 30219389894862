import { useTranslation } from 'next-i18next'

import { Toast, ToastCenter } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

import { useWebAudioSuspended } from '../hooks/useWebAudioSuspended'

const translationPage = 'signup'
const tKeys = allTKeys.signup.avSetup

export const ClickToActivateModal = ({ embedded }: { embedded: boolean }) => {
    const { t } = useTranslation(translationPage)
    const suspended = useWebAudioSuspended()

    return (
        <ToastCenter offset={embedded ? 4 : 24}>
            <Toast
                id="clickToEnableAudio"
                open={suspended}
                text={t(tKeys.clickToEnableAudio)}
                duration={Infinity}
            />
        </ToastCenter>
    )
}
