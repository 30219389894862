import { TimeUnit } from '@teamflow/lib'

export const LOADING_AV_TAKING_TOO_LONG_THRESHOLD_MS = 8000
export const HD_VIDEO_HEIGHT_THRESHOLD = 360
export const FACELESS_VIDEO_EL_ID = 'tf-local-participant-video'
export const VIDEO_TIMEOUT_MS = TimeUnit.SECONDS.toMillis(5)

export enum TrackingConstants {
    JoinedCall = 'joined-call',
    ScreenShareStarted = 'screen-share-started',
    NetworkQualityLow = 'network-quality-low',
}
