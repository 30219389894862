import { CSSProperties, useLayoutEffect, useState } from 'react'

import { LogManager } from '@teamflow/lib'
interface Props {
    freeze: boolean
    videoEl: HTMLVideoElement | null | void
    style: CSSProperties
}

const logger = LogManager.createLogger('UI@VideoFreezeFrame', {
    critical: true,
    call: true,
})

export function VideoFreezeFrame({ freeze, videoEl, style }: Props) {
    const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement | null>(null)

    // update canvas right after it's rendered
    useLayoutEffect(() => {
        const context = canvasEl?.getContext('2d')
        if (!videoEl || !canvasEl || !context) return

        if (freeze) {
            const width = videoEl.videoWidth
            const height = videoEl.videoHeight

            if (!width || !height) {
                logger.warn(
                    `VideoFreezeFrame abort: invalid video dimensions ${width}x${height}`
                )
                return
            }

            // dimensions of canvas must match video
            canvasEl.width = width
            canvasEl.height = height

            // draw the current video frame onto the canvas
            // so that if the video track becomes unavailable because
            // it is swapped to 'staged' or loading in, we display the
            // frozen frame
            try {
                context.drawImage(videoEl, 0, 0, width, height)
            } catch (error) {
                const message =
                    error instanceof Error ? error.message : 'Unknown'
                logger.error(`VideoFreezeFrame error: ${message}`)
            }
        }
    }, [canvasEl, freeze, videoEl])

    if (freeze) {
        return <canvas ref={setCanvasEl} style={style} />
    }

    return null
}
