import Router from 'next/router'
import { useEffect } from 'react'

import { api } from '@teamflow/client-api'

export function useMaintenanceMode() {
    useEffect(() => {
        async function getMaintenanceMode() {
            try {
                const { data } = await api.config.get()
                if (data && data.maintenanceMode) {
                    await Router.replace('/maintenance')
                }
            } catch {
                /* noop */
            }
        }
        void getMaintenanceMode()
    }, [])
}
