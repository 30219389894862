import { ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
    Box,
    BoxProps,
    Button,
    Divider,
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
    Icon,
    Stack,
    Text,
} from '@teamflow/design'
import allTKeys from '@teamflow/translations'

const translationPage = 'common'
const tKeys = allTKeys.common.huddleDropdownMenu

export type HuddleDropdownMenuOption = { label: string } & (
    | { value: string; asGroupHeaderKey?: never }
    | { value?: never; asGroupHeaderKey: string }
)

interface Props {
    label?: string
    icon?: ReactNode
    options: HuddleDropdownMenuOption[]
    value: string | undefined
    onChange: (value: string) => any
    loading?: boolean
    themeMode?: 'dark' | 'light'
    labelNoOptions?: string
}

const inputStyle = {
    width: '100%',
    backgroundColor: 'transparent',
    textOverflow: 'ellipsis',
}

const buttonStyle = {
    width: '100%',
    height: 40,
    borderRadius: 8,
    display: 'block',
}

export default function HuddleDropdownMenu({
    label,
    icon,
    options,
    value,
    onChange,
    loading = false,
    themeMode = 'dark',
    labelNoOptions,
    ...boxProps
}: Props & Omit<BoxProps, 'onChange'>) {
    const { t } = useTranslation(translationPage)

    const boxRef = useRef<HTMLElement>(null)
    const [width, setWidth] = useState<number | undefined>()

    useEffect(() => {
        setWidth(boxRef.current?.clientWidth)
    }, [loading]) // loading state can change button width

    const darkMode = themeMode === 'dark'
    const pleaseSelect = t(tKeys.pleaseSelect).toString()

    const buttonText =
        options.find((opt) => opt.value === value)?.label ??
        (options.length > 0 ? pleaseSelect : labelNoOptions || pleaseSelect)

    // hack: using <input> forces text onto one line without causing the
    // element's width to expand
    const textElement = (
        <input type="text" readOnly value={buttonText} style={inputStyle} />
    )

    return (
        <Box ref={boxRef} {...boxProps}>
            <Stack space="space12">
                {label && (
                    <Text
                        color={darkMode ? 'neutral10' : 'neutral'}
                        size="textSize14"
                        weight="600"
                        selectable={false}
                    >
                        {label}
                    </Text>
                )}

                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button
                            level="neutral"
                            kind={darkMode ? 'fill' : 'outline'}
                            size="sm"
                            leadingIcon={icon}
                            trailingIcon={
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    alignItems="center"
                                >
                                    <Icon
                                        name="chevron-down"
                                        size="size20"
                                        color={
                                            darkMode ? 'neutral10' : 'neutral90'
                                        }
                                    />
                                </Box>
                            }
                            isLoading={loading}
                            loadingText={textElement}
                            style={buttonStyle}
                        >
                            {textElement}
                        </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent minWidth={width}>
                        {options.map((option) =>
                            option.asGroupHeaderKey != null ? (
                                <DropdownMenuItem
                                    key={option.asGroupHeaderKey}
                                    style={{
                                        marginTop: 4,
                                        marginBottom: -4,
                                        marginLeft: 24,
                                        pointerEvents: 'none',
                                    }}
                                >
                                    <Text
                                        width="fill"
                                        size="textSize14"
                                        weight="500"
                                        color="neutral60"
                                        selectable={false}
                                    >
                                        {option.label}
                                    </Text>
                                    <Divider
                                        color="neutral40"
                                        marginTop="space8"
                                    />
                                </DropdownMenuItem>
                            ) : (
                                <DropdownMenuItem
                                    key={option.value}
                                    before={
                                        <Icon
                                            name="menuCheck"
                                            size="size16"
                                            color="neutral90"
                                            opacity={
                                                option.value === value ? 1 : 0
                                            }
                                        />
                                    }
                                    onClick={() => {
                                        onChange(option.value)
                                    }}
                                >
                                    <Text
                                        width="fill"
                                        size="textSize14"
                                        weight="500"
                                        color="neutral90"
                                        selectable={false}
                                    >
                                        {option.label}
                                    </Text>
                                </DropdownMenuItem>
                            )
                        )}
                    </DropdownMenuContent>
                </DropdownMenu>
            </Stack>
        </Box>
    )
}
