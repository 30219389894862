export function mapDevicesToOptions(
    firstOption: { label: string; value: string },
    devices?: ReadonlyArray<MediaDeviceInfo>
): { label: string; value: string }[] {
    if (!devices?.length) return []
    return [
        firstOption,
        ...devices.map((device) => ({
            label: device.label,
            value: device.deviceId,
        })),
    ]
}
