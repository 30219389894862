import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Link } from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import {
    track,
    Signup,
    eventWithFlow,
} from '@teamflow/web/src/helpers/analytics'

import { HELP_CONFIGURE_AUDIO_VIDEO_URL } from '../../../../constants'

const translationPage = 'signup'
const tKeys = allTKeys.signup.avSetup

export const AVSetupHelp = ({ source }: { source: string }) => {
    const trackHelpClicked = useCallback(() => {
        track(eventWithFlow(Signup.AVHelpClicked, source))
    }, [source])
    const { t } = useTranslation(translationPage)
    return (
        <Box alignSelf="center">
            <Link
                onClick={trackHelpClicked}
                href={HELP_CONFIGURE_AUDIO_VIDEO_URL}
                level="neutral"
                isExternal
            >
                {t(tKeys.havingTroubleWithAV)}
            </Link>
        </Box>
    )
}
