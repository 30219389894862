import { MAX_WIDTH_SCREEN } from '@teamflow/lib'
import { AVFeatures } from '@teamflow/types'
import * as t from '@teamflow/types'

export const defaultFeatures: AVFeatures = {
    screenShareMaxWidth: MAX_WIDTH_SCREEN,
    screenShareSimulcast: false,
    screenShareSecondLayer: false,
    screenShareAudio: false,
    screenShareAudioElectron: false,
}

export default async function getFeatures(
    featureFlagService: t.IFeatureFlagService
): Promise<AVFeatures> {
    const screenShareMaxWidth = await featureFlagService.value(
        t.RemoteConfig.ScreenshareMaxWidth
    )
    const screenShareSimulcast = await featureFlagService.isEnabled(
        t.Feature.ScreenShareSimulcast
    )
    const screenShareSecondLayer = await featureFlagService.isEnabled(
        t.Feature.ScreenShareSecondLayer
    )
    const screenShareAudio = await featureFlagService.isEnabled(
        t.Feature.ScreenShareAudio
    )
    const screenShareAudioElectron = await featureFlagService.isEnabled(
        t.Feature.ScreenShareAudioElectron
    )
    return {
        screenShareMaxWidth,
        screenShareSimulcast,
        screenShareSecondLayer,
        screenShareAudio,
        screenShareAudioElectron,
    }
}
