import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LockedRoomGuestWaitingProps } from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import { Box, Text } from '@teamflow/design'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import * as types from '@teamflow/types'

import { LoadingFunnel, track } from '../../helpers/analytics'

import HuddleHeader from '../common/HuddleHeader'
import HuddleHeading from '../common/HuddleHeading'
import HuddleLoader from '../common/HuddleLoader'

const translationPage = 'signup'
const tKeys = allTKeys.signup.guest

const POLLING_INTERVAL = 5000

function useKnocking(
    userId: string,
    orgSlug: string,
    roomSlug: string | undefined,
    setAccess: (access: types.JoinAccess | null) => void
) {
    const [removedFromWaitlist, setRemovedFromWaitlist] = useState(false)

    useEffect(() => {
        // TODO: window.onunload only works in Chromium browsers
        window.onunload = function () {
            // if user closes tab while knocking, remove them from the waitlist
            if (roomSlug && userId) {
                void api.room.waitlist.remove({
                    roomSessionId: roomSlug,
                    userOrgId: userId,
                })
            }
        }

        let intervalId = 0
        if (roomSlug) {
            intervalId = window.setInterval(async () => {
                const { data } = await api.room.permissions.get({
                    slug: roomSlug,
                })

                if (data && data.isInWaitlist === false) {
                    setRemovedFromWaitlist(true)
                }
                if (data?.access) {
                    rootStore.users.updateLocalFloorAccess(false)
                    setAccess(types.JoinAccess.Granted)
                }
            }, POLLING_INTERVAL)
        }

        return () => {
            window.clearInterval(intervalId)
        }
    }, [userId, orgSlug, roomSlug, setAccess])

    return { removedFromWaitlist }
}

export default function LockedRoomGuestWaiting({
    userId,
    orgSlug,
    roomSlug,
    setAccess,
}: LockedRoomGuestWaitingProps) {
    const { t } = useTranslation(translationPage)

    const { removedFromWaitlist } = useKnocking(
        userId,
        orgSlug,
        roomSlug,
        setAccess
    )

    useEffect(() => {
        track(LoadingFunnel.HitGuestKnocking)
    }, [])

    if (removedFromWaitlist) {
        return (
            <>
                <HuddleHeader />
                <Box
                    display="flex"
                    flexDirection="column"
                    marginTop="space16"
                    alignItems="center"
                    data-testid="knock-denied"
                >
                    <HuddleHeading>
                        {t(tKeys.yourRequestToJoinWasDeclinedByTheHost)}
                    </HuddleHeading>
                    <Text color="neutral70">
                        {t(
                            tKeys.ifYouWouldLikeToKnockAgainPleaseRefreshThePage
                        )}
                    </Text>
                </Box>
            </>
        )
    }

    return (
        <>
            <HuddleHeader />
            <HuddleLoader />
            <Box
                display="flex"
                flexDirection="column"
                marginTop="space16"
                alignItems="center"
                data-testid="knocking"
            >
                <HuddleHeading>
                    {t(tKeys.waitingForSomeoneToLetYouIn)}
                </HuddleHeading>
                <Text color="neutral70">
                    {t(tKeys.yourMeetingWillStartShortly)}
                </Text>
            </Box>
        </>
    )
}
