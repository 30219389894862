import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, Text } from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import { AVDeviceError } from '@teamflow/types'

import { BrowserType } from '../helpers/getBrowserName'

const translationPage = 'common'
const tKeys = allTKeys.common.avPermissions

const tErrorKeys: Record<
    AVDeviceError,
    Record<BrowserType, string[]> | string[]
> = {
    [AVDeviceError.PermissionDenied]: {
        chrome: [
            tKeys.teamflowRequiresAccessToYourCameraAndMicrophone,
            tKeys.clickOnTheBlockedIconInYourBrowsersAddressBar,
        ],
        edge: [
            tKeys.teamflowRequiresAccessToYourCameraAndMicrophone,
            tKeys.clickOnTheBlockedIconInYourBrowsersAddressBar,
        ],
        firefox: [
            tKeys.teamflowRequiresAccessToYourCameraAndMicrophone,
            tKeys.clickOnTheBlockedIconInYourBrowsersAddressBar,
        ],
        electron: [
            tKeys.teamflowRequiresAccessToYourCameraAndMicrophone,
            tKeys.navigateToSystemPreferencesSecurityPrivacyMicrophone,
            tKeys.allowTeamflowAccessToYourMicrophoneByCheckingTheBoxEnableYourCameraTheSameWay,
        ],
        safari: [
            tKeys.teamflowRequiresAccessToYourCameraAndMicrophone,
            tKeys.clickOnSafariInTheMenuBarAndNavigateToPreferences,
            tKeys.setAppTeamflowhqComToAllowAndThenRefreshYourPage,
        ],
    },
    [AVDeviceError.InUse]: [
        tKeys.pleaseCloseTheOtherApplication,
        tKeys.externalCameraDisconnectReconnect,
        tKeys.ifThisDoesNotFixTheIssueTryToReloadTeamflow,
    ],
    [AVDeviceError.NotFound]: [
        tKeys.pleaseCheckYourDeviceConnections,
        tKeys.ifThisDoesNotFixTheIssueTryToReloadTeamflow,
    ],
    [AVDeviceError.Unsupported]: [
        tKeys.pleaseCheckYourDeviceConnections,
        tKeys.ifThisDoesNotFixTheIssueTryToReloadTeamflow,
    ],
    [AVDeviceError.Other]: [
        tKeys.pleaseCheckYourDeviceConnections,
        tKeys.ifThisDoesNotFixTheIssueTryToReloadTeamflow,
    ],
}

interface Props {
    browser: BrowserType
    permissionsPending: boolean
    micErrorCode?: AVDeviceError
    camErrorCode?: AVDeviceError
}

export function Steps({
    browser,
    permissionsPending,
    micErrorCode,
    camErrorCode,
}: Props) {
    const { t } = useTranslation(translationPage)

    const text = useMemo(() => {
        if (permissionsPending) {
            return t(tKeys.teamflowNeedsAccessToYourCameraAndMicrophone)
        }
        const errorCode = camErrorCode || micErrorCode
        if (errorCode) {
            const tKeysError = tErrorKeys[errorCode] ?? []
            const tKeysSteps = Array.isArray(tKeysError)
                ? tKeysError
                : tKeysError[browser]
            return tKeysSteps.map((tKey) => t(tKey)).join(' ')
        }
    }, [permissionsPending, camErrorCode, micErrorCode, t, browser])

    if (!text) return null

    return (
        <Stack space="space16">
            <Text color="neutral80" size="textSize14" weight="400">
                {text}
            </Text>
        </Stack>
    )
}
