// Returns an array of PeerConnection objects currently in use in the session.
//
// In peer-to-peer mode, there is one peer connection per other participant.
//
// In sfu mode, there is one PeerConnection for sending media to the SFU, and
// one PeerConnection for receiving media from the SFU. This function returns
// the sending peer connection as the first element of the array, and the receiving
// peer connection as the second element of the array
//
import { DailyCall } from '@daily-co/daily-js'

export function getPeerConnections(): RTCPeerConnection[] {
    try {
        const rtcpeers = (window as any).rtcpeers
        return rtcpeers.currentlyPreferred.rtcPeerConnections
            ? Object.values(rtcpeers.currentlyPreferred.rtcPeerConnections)
            : [
                  rtcpeers.currentlyPreferred.sendTransport._handler._pc,
                  rtcpeers.currentlyPreferred.recvTransport._handler._pc,
              ]
    } catch (e) {
        return []
    }
}

export const getSendersByInboundKeys = (call: DailyCall) => async () => {
    try {
        const rtcpeers = (window as any).rtcpeers

        return Object.fromEntries(
            (
                await Promise.all(
                    Object.values(rtcpeers.currentlyPreferred.consumers).map(
                        async (c: any) => {
                            const senderPeerId = c.appData.peerId
                            const sr = await c.rtpReceiver.getStats()
                            const inboundKey = Array.from(sr.keys()).find(
                                (k: any) => k.startsWith('RTCInboundRTP')
                            )
                            const senderUserId =
                                call.participants()[senderPeerId as string]
                                    ?.user_name

                            if (inboundKey && senderUserId)
                                return [inboundKey as string, senderUserId]
                            else return null
                        }
                    )
                )
            ).filter((x): x is [string, string] => x !== null)
        )
    } catch {
        return {}
    }
}
