import { UAParser } from 'ua-parser-js'

import { sharedInstance as electron } from '../../../verse/services/ElectronService'

export type BrowserType = 'electron' | 'firefox' | 'safari' | 'edge' | 'chrome'

export function getBrowserName(): BrowserType {
    if (electron.available) {
        return 'electron'
    }
    const detected = new UAParser().getBrowser().name?.toLowerCase()
    if (
        detected === 'firefox' ||
        detected === 'safari' ||
        detected === 'edge' ||
        detected === 'chrome'
    ) {
        return detected
    }
    return 'chrome'
}
