import { AnimatePresence, motion } from 'framer-motion'
import { observer } from 'mobx-react'
import { CSSProperties, useMemo } from 'react'

import { Box, tokens } from '@teamflow/design'
import rootStore, { Participant } from '@teamflow/store'

const WIDTH = (8 * 19) / 12

const SELECTED_COLOR = '#FFD850'

const ON_SCALE = 1
const OFF_SCALE = 0.87

interface Props {
    avatarDiameter: number
    participant: Participant
}

export const SpeakingIndicator = ({
    className,
    bgColor,
    offset,
    diameter,
    borderRadius = '50%',
    scale,
    selected,
    ...rest
}: {
    className?: string
    bgColor: string
    offset?: number | string
    diameter: number | string
    borderRadius?: string
    scale: number
    selected: boolean
}) => {
    const style = useMemo<CSSProperties>(
        () => ({
            background: bgColor,
            position: 'absolute',
            top: offset,
            left: offset,
            width: diameter,
            height: diameter,
            borderRadius: borderRadius,
            overflow: 'hidden',
            pointerEvents: 'none',
            transform: `matrix(${scale}, 0, 0, ${scale}, 0, 0)`,
            transition: 'transform 200ms, background 200ms ease',
            willChange: 'transform',
            boxShadow: selected
                ? `0px 0px 40px rgba(255, 216, 80, 0.5)`
                : 'none',
        }),
        [bgColor, borderRadius, diameter, offset, scale, selected]
    )
    return <Box className={className} style={style} {...rest} />
}

const AudioMeter = observer(({ avatarDiameter, participant }: Props) => {
    const localParticipant = rootStore.participants.localParticipant
    const isLocalParticipant = participant.id === localParticipant?.id

    const speaking = participant.showAsSpeaking
    const muted = participant.audioIsMuted

    const diameter = avatarDiameter + WIDTH * 2

    const selected = participant.isSelected

    let loading =
        (participant.media?.videoState === 'loading' &&
            !participant.media.videoTrack) ||
        (rootStore.audioVideo.cameraUpdating &&
            participant.id === rootStore.users.localUserId) ||
        !participant.avIsConnected

    if (!isLocalParticipant && !rootStore.audioVideo.isConnected) {
        loading = false
    }

    let scale: number
    let bgColor: string

    if (!isLocalParticipant && !rootStore.audioVideo.isConnected) {
        // local use isn't connected to AV, so we don't know the remote user's AV state
        scale = ON_SCALE
        bgColor = tokens.gradient.gradientUnknown
    } else if (loading) {
        scale = ON_SCALE
        bgColor = tokens.gradient.gradientLoading
    } else if (selected) {
        scale = ON_SCALE
        bgColor = SELECTED_COLOR
    } else if (muted) {
        scale = ON_SCALE
        bgColor = tokens.gradient.gradientOff
    } else {
        scale = speaking ? ON_SCALE : OFF_SCALE
        bgColor = tokens.gradient.gradientOn
    }

    const borderRadius = participant.isOnShittyInternet
        ? `${16 + WIDTH * 2}px`
        : '50%'

    return (
        <AnimatePresence>
            <motion.div>
                <SpeakingIndicator
                    data-testid={`participant-audio-meter-${participant.id}`}
                    className={
                        loading && !participant.isOnShittyInternet
                            ? 'spinning-loader'
                            : ''
                    }
                    bgColor={bgColor}
                    diameter={`${diameter}px`}
                    offset={`${0 - WIDTH}px`}
                    borderRadius={borderRadius}
                    scale={scale}
                    selected={selected}
                />
            </motion.div>
        </AnimatePresence>
    )
})

export default AudioMeter
