import { LogManager } from '@teamflow/lib'
import {
    IAudioService,
    Events,
    IAudioBufferSound,
    ISoundOptions,
} from '@teamflow/types'

import BaseSound from './BaseSound'

enum States {
    Stopped,
    Playing,
}

export default class AudioBufferSound
    extends BaseSound<AudioBufferSourceNode>
    implements IAudioBufferSound
{
    private audioService: IAudioService
    private state = States.Stopped

    get volume() {
        return this.gainNode.gain.value
    }

    set volume(v: number) {
        if (Number.isNaN(v)) {
            LogManager.global.warn('Attempt to set volume with NaN value')
            return
        }
        this.gainNode.gain.value = Math.max(0, Math.min(1, v))
    }

    constructor(
        key: string,
        path: string,
        audio: IAudioService,
        options: ISoundOptions = {}
    ) {
        super(
            audio.sharedContext.createBufferSource(),
            audio.sharedContext,
            options
        )

        this.audioService = audio

        void audio.load(key, path).then((buffer) => {
            this.source.buffer = buffer
        })
    }

    play(loop = false, waitIfSuspended = false) {
        if (this.audioService.isSuspended && waitIfSuspended) {
            this.audioService.once(Events.ContextResumed, () => {
                this.play(loop)
            })
            return
        }

        this.source.start(0)
        this.source.loop = loop
        this.state = States.Playing
    }

    stop() {
        if (this.state !== States.Playing) {
            return
        }

        this.source.stop()
        this.state = States.Stopped
    }

    destroy() {
        if (this.state === States.Playing) {
            this.source.stop()
        }
        this.source.disconnect()
    }
}
