import { DailyCall } from '@daily-co/daily-js'

import { isSpecialEffectsCamera } from '../helpers/isSpecialEffectsCamera'

export async function deselectSpecialEffectsCamera(
    call: DailyCall,
    devices: MediaDeviceInfo[],
    cameraId: string | null
): Promise<string | null> {
    // cycle through cameras if initial camera is a special fx camera
    let camera = devices.find((d) => d.deviceId === cameraId)
    let count = 0
    while (isSpecialEffectsCamera(camera)) {
        const { device } = await call.cycleCamera()
        if (device) {
            camera = device
        }

        if (!isSpecialEffectsCamera(camera)) {
            // exit on first found non-fx camera
            break
        }

        ++count

        if (count >= 5) {
            // maximum look 5 times in case all you have
            // are special fx cameras...
            break
        }
    }
    return camera?.deviceId ?? null
}
