import noop from 'lodash/noop'
import { memo, useCallback, useEffect, useState } from 'react'

import { checkDeviceAccess, DeviceCheck, LogManager } from '@teamflow/lib'
import { AVDeviceError } from '@teamflow/types'

import {
    AUDIO_INPUT_BLOCKED,
    AUDIO_INPUT_UNBLOCKED,
    track,
    VIDEO_INPUT_BLOCKED,
    VIDEO_INPUT_UNBLOCKED,
} from '../../helpers/analytics'
import { useMounted } from '../../hooks/useMounted'

import { ModalComponent } from './components/ModalComponent'
import { getBrowserName } from './helpers/getBrowserName'

interface Props {
    onDismiss?: (result: DeviceCheck) => void
}

const logger = LogManager.createLogger('UI@AVPermissionsModal')

export const AVPermissionsModal = memo(({ onDismiss = noop }: Props) => {
    const [deviceCheck, setDeviceCheck] = useState<DeviceCheck | null>(null)
    const [dismissed, setDismissed] = useState(false)
    const [isPermissionError, setIsPermissionError] = useState(false)

    const micBlocked = !deviceCheck?.audio.access
    const camBlocked = !deviceCheck?.video.access
    const anyBlocked = micBlocked || camBlocked

    useEffect(() => {
        if (camBlocked) {
            track(VIDEO_INPUT_BLOCKED, {
                noStream: true,
            })
        } else {
            track(VIDEO_INPUT_UNBLOCKED)
        }
    }, [camBlocked])

    useEffect(() => {
        if (micBlocked) {
            track(AUDIO_INPUT_BLOCKED, {
                noStream: true,
            })
        } else {
            track(AUDIO_INPUT_UNBLOCKED)
        }
    }, [micBlocked])

    const runDeviceCheck = useCallback(async () => {
        // request audio and video streams individually
        // to find out if only one or both are blocked
        const audio = await checkDeviceAccess('audio')
        if (audio.error) {
            logger.error(`Retry audio error`, audio.error)
        }
        const video = await checkDeviceAccess('video')
        if (video.error) {
            logger.error(`Retry video error`, video.error)
        }
        // make sure both devices appear in the allow
        // access menu by requesting both streams
        if (audio.error || video.error) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: true,
                })
                stream.getTracks().forEach((t) => t.stop())
            } catch {
                /* noop */
            }
        }
        return {
            audio,
            video,
        }
    }, [])

    const handleContinue = useCallback(() => {
        setDismissed(true)
        if (deviceCheck) {
            onDismiss(deviceCheck)
        }
    }, [deviceCheck, onDismiss])

    const isMounted = useMounted()

    useEffect(() => {
        logger.debug('AV permissions denied screen shown')

        async function check() {
            const result = await runDeviceCheck()
            if (isMounted()) {
                setDeviceCheck(result)
            }
        }
        void check()

        navigator.mediaDevices?.addEventListener('devicechange', check)

        const intervalId = setInterval(() => {
            void check()
        }, 1_000)

        return () => {
            navigator.mediaDevices?.removeEventListener('devicechange', check)
            clearInterval(intervalId)
        }
    }, [runDeviceCheck, isMounted])

    useEffect(() => {
        if (!anyBlocked) {
            handleContinue()
        }
    }, [anyBlocked, handleContinue])

    useEffect(() => {
        if (
            !isPermissionError &&
            (deviceCheck?.audio.error?.code ===
                AVDeviceError.PermissionDenied ||
                deviceCheck?.video.error?.code ===
                    AVDeviceError.PermissionDenied)
        ) {
            setIsPermissionError(true)
        }
    }, [isPermissionError, deviceCheck])

    const browser = getBrowserName()

    return (
        <ModalComponent
            open={!dismissed}
            browser={browser}
            micErrorCode={deviceCheck?.audio.error?.code}
            camErrorCode={deviceCheck?.video.error?.code}
            onDismiss={handleContinue}
            permissionsPending={false}
        />
    )
})
