import * as Sentry from '@sentry/react'

import * as t from '@teamflow/types'

import { LOADING_AV_TAKING_TOO_LONG_THRESHOLD_MS } from '../const'

export const startTimerForJoiningAVTakingTooLong = (provider: t.AVProvider) => {
    const timeoutRef = setTimeout(() => {
        Sentry.captureMessage(
            `Joining AV took more than ${LOADING_AV_TAKING_TOO_LONG_THRESHOLD_MS}ms`,
            {
                tags: {
                    informative_error: true,
                    av_provider: provider,
                },
            }
        )
    }, LOADING_AV_TAKING_TOO_LONG_THRESHOLD_MS)

    return () => clearTimeout(timeoutRef)
}
