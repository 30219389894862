import { IReactionDisposer, reaction } from 'mobx'

import { featureFlags } from '@teamflow/bootstrap'
import { ParticipantSpeakingCommand } from '@teamflow/client-realtime'
import rootStore from '@teamflow/store'
import { IRealtimeService, RemoteConfig } from '@teamflow/types'

import AudioMeter from '../../audio/AudioMeter'

// If the level of sound is above this
// threshold then the meter will go active.
// active = decibles > threshold
export const AUDIO_ACTIVE_THRESHOLD_DEFAULT = 0.03

/**
 * Monitors audio level and updates state if it changes above/below
 * the threshold so we know what participants are talking
 *
 * @group Participants
 */
export class AudioDetector {
    private realtime: IRealtimeService
    private lastPeak = 0
    private audioActiveThreshold = AUDIO_ACTIVE_THRESHOLD_DEFAULT
    private speaking = false
    private audioMeter: AudioMeter
    private disposeReaction?: IReactionDisposer

    constructor(realtime: IRealtimeService) {
        this.realtime = realtime
        this.audioMeter = new AudioMeter()

        void featureFlags
            .getConfigValue(RemoteConfig.AudioActiveThreshold)
            .then((value) => {
                this.audioActiveThreshold =
                    parseFloat(value) ?? AUDIO_ACTIVE_THRESHOLD_DEFAULT
            })

        this.disposeReaction = reaction(
            () => rootStore.participants.localParticipant?.media?.audioTrack,
            (audioTrack) => {
                if (audioTrack && audioTrack instanceof MediaStreamTrack) {
                    this.audioMeter.connect(new MediaStream([audioTrack]))
                } else {
                    this.audioMeter.disconnect()
                }
            }
        )

        const audioTrack =
            rootStore.participants.localParticipant?.media?.audioTrack
        if (audioTrack && audioTrack instanceof MediaStreamTrack) {
            this.audioMeter.connect(new MediaStream([audioTrack]))
        }
    }

    update() {
        const participant = rootStore.participants.localParticipant
        const isOn = participant?.media?.audioIsOn ?? false

        let speaking = false
        if (isOn) {
            let peak = this.audioMeter.peak
            if (peak < 0.1) peak = 0
            if (peak > 0.8) peak = 0.8

            this.lastPeak = this.lastPeak + (peak - this.lastPeak) * 0.08
            speaking = this.lastPeak > this.audioActiveThreshold
        }

        if (speaking === this.speaking) return

        this.realtime.dispatch(new ParticipantSpeakingCommand(speaking))
        participant?.update({ speaking })

        this.speaking = speaking
    }

    destroy() {
        this.audioMeter.destroy()
        this.disposeReaction?.()
    }
}
