import { isSafari } from '@teamflow/lib'

import { sharedInstance as webAudio } from '../audio/WebAudioService'

function getPeak(values: Float32Array) {
    let peak = 0
    for (let i = 0; i < values.length; i++) {
        const value = Math.abs(values[i])
        if (value > peak) {
            peak = value
        }
    }
    return peak
}

function getRms(values: Float32Array) {
    let totalSquared = 0
    for (let i = 0; i < values.length; i++) {
        const value = values[i]
        totalSquared += value * value
    }
    return Math.sqrt(totalSquared / values.length)
}

export default class AudioMeter {
    private _source?: MediaStreamAudioSourceNode
    private _analyserNode: AnalyserNode
    private _muteNode: GainNode
    private _sampleBuffer: Float32Array
    private _byteSampleBuffer: Uint8Array
    private _floatSupported: boolean

    protected get source() {
        return this._source
    }

    get peak() {
        return getPeak(this.updateWaveform())
    }

    get rms() {
        return getRms(this.updateWaveform())
    }

    constructor() {
        this._analyserNode = webAudio.sharedContext.createAnalyser()
        this._analyserNode.fftSize = 256
        this._analyserNode.minDecibels = -90
        this._analyserNode.maxDecibels = -10
        this._floatSupported =
            typeof this._analyserNode.getFloatTimeDomainData === 'function'

        this._byteSampleBuffer = new Uint8Array(this._analyserNode.fftSize)
        this._sampleBuffer = new Float32Array(this._analyserNode.fftSize)

        this._muteNode = webAudio.sharedContext.createGain()
        this._muteNode.gain.value = 0

        this._analyserNode.connect(this._muteNode)
        this._muteNode.connect(webAudio.sharedContext.destination)
    }

    connect(stream: MediaStream) {
        this._source = webAudio.sharedContext.createMediaStreamSource(stream)
        this._source.connect(this._analyserNode)
    }

    disconnect() {
        this._source?.disconnect()
        this._source = undefined
    }

    updateWaveform() {
        // Looks like safari just added this not too long ago, but it's not working
        // super well.  It's not very senstivie and requires you to yell at your
        // computer to detect if your speaking.  Fall back on the Byte method
        // below.
        if (this._floatSupported && !isSafari()) {
            this._analyserNode.getFloatTimeDomainData(this._sampleBuffer)
        } else {
            // convert from byte to float for safari
            this._analyserNode.getByteTimeDomainData(this._byteSampleBuffer)
            for (let i = 0; i < this._sampleBuffer.length; i++) {
                this._sampleBuffer[i] = (this._byteSampleBuffer[i] - 128) / 64
            }
        }
        return this._sampleBuffer
    }

    destroy() {
        this.disconnect()
        this._analyserNode.disconnect()
        this._muteNode.disconnect()
    }
}
