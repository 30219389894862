import { isString } from 'lodash'

import type {
    DailyEventObjectCameraError,
    DailyEventObjectFatalError,
} from '@daily-co/daily-js'

const UNKNOWN_ERROR = 'unknown error'

export function parseErrorMessage(
    event: DailyEventObjectCameraError | DailyEventObjectFatalError
) {
    if (!event.errorMsg) {
        return UNKNOWN_ERROR
    }

    if (isString(event.errorMsg)) {
        return event.errorMsg as string
    }

    return event.errorMsg.errorMsg ?? UNKNOWN_ERROR
}
