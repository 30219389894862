import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { IRouterParams } from '@teamflow/bootstrap'

import QueryParams from '../helpers/QueryParams'
import parseQuery from '../helpers/parseVerseQueryParams'

export function useParams() {
    const router = useRouter()
    const [params, setParams] = useState<IRouterParams | null>(null)

    useEffect(() => {
        const params = parseQuery(router.query)
        if (params.org) {
            QueryParams.set(params)
            setParams({
                orgSlug: decodeURI(params.org),
                roomSlug: params.slugName,
                inviteCode: params.code,
                invitation: params.invitation,
                locationX: params.locationX,
                locationY: params.locationY,
                mobileWebView: params.mobileWebView,
                hideMobileNavigation: params.hideMobileNavigation,
                scene: params.scene,
            })
        }
    }, [router.query])

    return params
}
