import rootStore from '@teamflow/store'
import { Feature, IFeatureFlagService } from '@teamflow/types'

import { TrackSubscriptionState } from '../types'

export function getScreenSubscriptionVerbose(
    userId: string,
    sharedFlags: IFeatureFlagService,
    microphoneSubscribed: TrackSubscriptionState
): [TrackSubscriptionState, string] {
    const participant = rootStore.participants.findParticipantById(userId)

    if (!participant)
        return [TrackSubscriptionState.UNSUBSCRIBED, 'participant_not_found']

    if (
        rootStore.participants.localParticipant?.locationId !==
        participant.locationId
    )
        return [
            TrackSubscriptionState.UNSUBSCRIBED,
            'participant_not_in_location',
        ]

    const app = rootStore.app.getUserScreenShare(userId)
    if (!app || !app.shouldLoad)
        return [TrackSubscriptionState.UNSUBSCRIBED, 'app_not_found']

    if (participant.shouldPauseScreen && !app.headless)
        return [TrackSubscriptionState.UNSUBSCRIBED, 'should_pause_screen']

    // set track to staged immediately if app hidden or page is in background
    // (in FSM, app counts as hidden if it isn't the one selected)

    const turnOffVideoInBackground = sharedFlags.isEnabledSync(
        Feature.TurnOffVideoInBackground
    )

    if (turnOffVideoInBackground && rootStore.commons.inBackground)
        return [TrackSubscriptionState.STAGED, 'turn_off_video_bg']

    if (app.hiddenAt) return [TrackSubscriptionState.STAGED, 'hidden_at']
    if (app.headless) return [microphoneSubscribed, 'success_headless']

    return [TrackSubscriptionState.SUBSCRIBED, 'success']
}

export function getScreenSubscription(
    userId: string,
    sharedFlags: IFeatureFlagService,
    microphoneSubscribed: TrackSubscriptionState
): TrackSubscriptionState {
    return getScreenSubscriptionVerbose(
        userId,
        sharedFlags,
        microphoneSubscribed
    )[0]
}
