import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Platform, featureFlags, ScreenLoaderProps } from '@teamflow/bootstrap'
import { Box, ShortcutKeysIntoStringWithShortcut } from '@teamflow/design'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import { RemoteConfig } from '@teamflow/types/src/verse/flags'

import HuddleHeader from './HuddleHeader'
import TeachingMomentLoader from './TeachingMomentLoader'

const translationPage = 'common'

const teachingMomentTitleTKeys = allTKeys.common.teachingMomentTitles
const teachingMomentDescriptionTKeys =
    allTKeys.common.teachingMomentDescriptions

const teachingMomentsVirtualOffice = [
    {
        titleTKey: teachingMomentTitleTKeys.busyMode,
        descriptionTKey: teachingMomentDescriptionTKeys.busyMode,
        animationSrc: '/images/teaching-moments/busy_status.png',
    },
    {
        titleTKey: teachingMomentTitleTKeys.fullscreenMode,
        descriptionTKey: teachingMomentDescriptionTKeys.fullscreenMode,
        animationSrc: '/images/teaching-moments/fullscreen_mode.png',
    },
    {
        titleTKey: teachingMomentTitleTKeys.quickSearch,
        descriptionTKey: teachingMomentDescriptionTKeys.quickSearch,
        animationSrc: '/images/teaching-moments/quick_search.png',
    },
    {
        titleTKey: teachingMomentTitleTKeys.goHome,
        descriptionTKey: teachingMomentDescriptionTKeys.goHome,
        animationSrc: '/images/teaching-moments/go_home.png',
    },
    {
        titleTKey: teachingMomentTitleTKeys.tapOnShoulder,
        descriptionTKey: teachingMomentDescriptionTKeys.tapOnShoulder,
        animationSrc: '/images/teaching-moments/tap_on_shoulder.png',
    },
    {
        titleTKey: teachingMomentTitleTKeys.slackIntegration,
        descriptionTKey: teachingMomentDescriptionTKeys.slackIntegration,
        animationSrc: '/images/teaching-moments/slack_integration.png',
    },
]

const teachingMomentsSalesfloor = [
    {
        titleTKey: teachingMomentTitleTKeys.listenMode,
        descriptionTKey: teachingMomentDescriptionTKeys.listenMode,
        animationSrc: '/images/teaching-moments/salesfloor_listen_mode.png',
    },
]

const screenLoaderStyles = {
    width: '100vw',
    height: '100vh',
    zIndex: 1,
}

export const ScreenLoader = observer(function ({
    loaded = false,
    text,
}: ScreenLoaderProps) {
    const { t } = useTranslation(translationPage)
    const hasOrgData = !!rootStore.organization.data

    const teachingMoments = rootStore.organization.isSalesFloor
        ? teachingMomentsSalesfloor
        : teachingMomentsVirtualOffice
    const selectedTeachingMoment = useMemo(
        () =>
            teachingMoments[Math.floor(Math.random() * teachingMoments.length)],
        [teachingMoments]
    )

    useEffect(() => {
        const loadingTimeout = featureFlags.valueSync(
            RemoteConfig.LoadingWarningThreshold
        )
        const TIMEOUT_ID = setTimeout(() => {
            Sentry.captureException(
                new Error(`Loading the verse took more than ${loadingTimeout}`),
                {
                    tags: {
                        informative_error: true,
                    },
                }
            )
        }, loadingTimeout)

        return () => {
            clearTimeout(TIMEOUT_ID)
        }
    }, [loaded])

    return (
        <>
            {!loaded && (
                <Box
                    background="neutral10"
                    position="absolute"
                    top="none"
                    left="none"
                    __cssOverrides={screenLoaderStyles}
                >
                    <HuddleHeader />
                    {selectedTeachingMoment !== null && hasOrgData && (
                        <TeachingMomentLoader
                            title={t(selectedTeachingMoment.titleTKey)}
                            description={
                                <ShortcutKeysIntoStringWithShortcut
                                    platform={Platform}
                                    text={t(
                                        selectedTeachingMoment.descriptionTKey
                                    )}
                                />
                            }
                            animationSrc={selectedTeachingMoment.animationSrc}
                            text={text}
                        />
                    )}
                </Box>
            )}
        </>
    )
})
