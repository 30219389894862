import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Platform } from '@teamflow/bootstrap'
import { Box, Text } from '@teamflow/design'
import { getPublicUrl } from '@teamflow/lib'
import allTKeys from '@teamflow/translations'

import LoadingBar from './ui/LoadingBar'

const translationPage = 'common'
const teachingMntTitleTKeys = allTKeys.common.teachingMomentTitles

const LOADBAR_PERCENT_UPDATE_TIMEOUT = 1250

type TeachingMoment = {
    title: string
    description: string | JSX.Element
    animationSrc: string
    text: string | null
}

export default function TeachingMomentLoader({
    title,
    description,
    animationSrc,
    text,
}: TeachingMoment) {
    const { t } = useTranslation(translationPage)
    const animationUrl = getPublicUrl(animationSrc)

    const [loadPercent, setLoadPercent] = useState(5)

    // Some temporary fake logic for changing the load percentage
    useEffect(() => {
        let intervalId: number | undefined = undefined

        intervalId = window.setTimeout(() => {
            const loadAmt = 5 + Math.random() * 20
            if (loadPercent >= 85) {
                setLoadPercent(loadPercent + 0.2 * (100 - loadPercent))
            } else {
                setLoadPercent(Math.min(loadAmt + loadPercent, 85))
            }
        }, LOADBAR_PERCENT_UPDATE_TIMEOUT)

        return () => {
            if (intervalId) window.clearTimeout(intervalId)
        }
    }, [loadPercent])

    return (
        <Box
            display="flex"
            flexDirection="column"
            placeItems="center"
            position="absolute"
            height="fill"
            width="fill"
            top="none"
            data-testid="tf.common.loading-spinner"
        >
            <Box
                position="absolute"
                style={{ top: Platform.isPhone() ? '35vh' : '25vh' }}
            >
                <img
                    style={{
                        maxHeight: '50vh',
                        maxWidth: 'min(95vw, 650px)',
                    }}
                    src={animationUrl}
                    alt={`${title} Animation`}
                />
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                placeItems="center"
                position="absolute"
                marginX="space16"
                style={{ maxWidth: '640px', top: '10vh' }}
            >
                <div
                    style={{
                        background: '#D5EBF8',
                        padding: '8px 16px',
                        borderRadius: '23px',
                    }}
                >
                    <Text weight="500">{t(teachingMntTitleTKeys.proTip)}</Text>
                </div>
                <Box width="content" marginTop="space20">
                    <Text align="center" weight="600" size="textSize24">
                        {title}
                    </Text>
                </Box>
                <Box width="content" marginTop="space16">
                    <Text
                        align="center"
                        weight="400"
                        size="textSize16"
                        color="neutral60"
                        __cssOverrides={{
                            lineHeight: '24px',
                        }}
                    >
                        {description}
                    </Text>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection="column"
                position="absolute"
                placeItems="center"
                style={{ bottom: '10vh' }}
            >
                <Text
                    align="center"
                    weight="500"
                    color="neutral60"
                    size="textSize16"
                >
                    {text}
                </Text>
                <Box marginTop="space16">
                    <LoadingBar percentage={loadPercent} />
                </Box>
            </Box>
        </Box>
    )
}
