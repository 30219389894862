// this is tracking for Optimizely Web; use OptimizelyTestService for fullstack
export function track(event: string, payload: Record<string, unknown> = {}) {
    if (!window || !window['optimizely']) {
        return
    }

    window['optimizely'].push({
        type: 'event',
        eventName: event,
        ...payload,
    })
}
