import { DeviceCheck } from '@teamflow/lib'
import rootStore from '@teamflow/store'

import { AVPermissionsModal } from '../../../AVPermissionsModal'

export const AVSetupPermissions = ({
    setDeviceAccess,
}: {
    setDeviceAccess: (access: { audio: boolean; video: boolean }) => void
}) => {
    function handlePermissionsModalDismiss({ audio, video }: DeviceCheck) {
        setDeviceAccess({
            audio: !!audio.access,
            video: !!video.access,
        })
        void rootStore.audioVideo.toggleMicEnabled(!!audio.access)
        void rootStore.audioVideo.toggleCameraEnabled(!!video.access)
    }

    return <AVPermissionsModal onDismiss={handlePermissionsModalDismiss} />
}
