import { useEffect, useState } from 'react'

import AudioMeter from '../../../../verse/audio/AudioMeter'

import HuddleMeter from '../../../common/HuddleMeter'

interface Props {
    audioMeter: AudioMeter
    micEnabled?: boolean
    ms?: number
}

export default function AVSetupAudioMeter({
    audioMeter,
    micEnabled = true,
    ms = 1000 / 20,
    ...props
}: Props) {
    const [peak, setPeak] = useState(0)

    useEffect(() => {
        if (!micEnabled) {
            setPeak(0)
            return
        }
        const interval = setInterval(() => {
            setPeak(Math.min(audioMeter.peak, 1))
        }, ms)

        return () => {
            clearInterval(interval)
        }
    }, [audioMeter, micEnabled, ms])

    return <HuddleMeter {...props} themeMode="light" value={peak} />
}
