import { useEffect, useMemo } from 'react'

import AudioMeter from '../../../../verse/audio/AudioMeter'

export function useAudioMeter(stream?: MediaStream) {
    const audioMeter = useMemo(() => new AudioMeter(), [])

    useEffect(() => {
        if (stream) {
            audioMeter.connect(stream)
        } else {
            audioMeter.disconnect()
        }
        return () => {
            audioMeter.destroy()
        }
    }, [audioMeter, stream])

    return audioMeter
}
