import { observer } from 'mobx-react'
import { CSSProperties, useState } from 'react'

import { useVideoAutoPlay } from '@teamflow/client-call'
import { Participant } from '@teamflow/store'

import { FACELESS_VIDEO_EL_ID } from '../const'
import { useAutoVideoSimulcastLayer } from '../hooks/useAutoVideoSimulcastLayer'

import { VideoFreezeFrame } from './VideoFreezeFrame'

const style: CSSProperties = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    // necessary because for some reason, <video> intercepts scroll events
    // when hovered, preventing video ribbons from being scrolled
    pointerEvents: 'none',
}

export const Video = observer(function Video({
    participant,
}: {
    participant: Participant
}): JSX.Element {
    const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>(null)

    const track = participant.media?.videoTrack

    useAutoVideoSimulcastLayer(participant.id, videoEl)

    useVideoAutoPlay({
        videoEl,
        track,
        participantId: participant.id,
        isScreen: false,
    })

    return (
        <>
            <video
                ref={setVideoEl}
                data-testid={`${FACELESS_VIDEO_EL_ID}-${participant.id}`}
                style={style}
            />
            <VideoFreezeFrame
                freeze={participant.freezeVideoTrack}
                videoEl={videoEl}
                style={style}
            />
        </>
    )
})
