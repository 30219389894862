export default function LoadingBar({ percentage }: { percentage: number }) {
    const barWidth = 320
    return (
        <div style={{ width: barWidth }}>
            <div style={barStyles}>
                <div style={{ ...filledBarStyles, width: `${percentage}%` }} />
            </div>
        </div>
    )
}

const barStyles = {
    background: '#f2f4f7',
    borderRadius: '8px',
    height: '8px',
    width: '100%',
}

const filledBarStyles = {
    ...barStyles,
    background: '#7253f0',
    transition: 'width 1s cubic-bezier(.1,.25,.25,1)',
}
