const DEFAULT_ID = 'default'
const COMMS_ID = 'communications'

export function getActualDeviceId(
    id: string | null,
    kind: MediaDeviceKind,
    devices: MediaDeviceInfo[]
) {
    if (id === DEFAULT_ID || id === COMMS_ID) {
        const device = devices.find((d) => d.kind === kind && d.deviceId === id)
        const deviceWithId =
            device &&
            devices.find(
                (d) =>
                    d.deviceId !== DEFAULT_ID &&
                    d.deviceId !== COMMS_ID &&
                    d.kind === device.kind &&
                    d.groupId === device.groupId &&
                    device.label.includes(d.label)
            )
        if (deviceWithId) {
            id = deviceWithId.deviceId
        }
    }
    return id
}
