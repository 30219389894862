import { Instrumentation, LogManager } from '@teamflow/lib'

const logger = LogManager.createLogger('WebRTCInstrumentation', {
    call: true,
    critical: true,
})

export class WebRTCInstrumentation {
    constructor(
        public readonly id: string,
        private readonly stats: Array<{
            report: RTCPeerConnection
            direction: 'send' | 'recv' | 'both'
        }>,
        private readonly senderPeerIdMapping: () => Promise<
            Record<string, string>
        >
    ) {}

    start() {
        logger.debug('Beginning instrumentation for call')
        Instrumentation.add(this.sample)
    }

    stop() {
        Instrumentation.delete(this.sample)
    }

    private sample = async () => {
        const senderPeerIdsByTrack = await this.senderPeerIdMapping()
        await Promise.all(
            this.stats.map((stats) =>
                stats.report
                    .getStats()
                    .then((report) =>
                        this.sampleReport(
                            report,
                            stats.direction,
                            senderPeerIdsByTrack
                        )
                    )
            )
        )
    }

    private sampleReport = (
        stats: RTCStatsReport,
        direction: 'both' | 'recv' | 'send',
        senderPeerIdsByTrack: Record<string, string>
    ) => {
        for (const key of stats.keys()) {
            if (key.startsWith('RTCIceCandidatePair_')) {
                const pairStats = stats.get(key) as RTCIceCandidatePairStats

                logger.sample({
                    action: 'Call@' + pairStats.id,
                    [Instrumentation.VALUE]: {
                        ...(direction !== 'recv' && {
                            availableOutgoingBitrate:
                                pairStats.availableOutgoingBitrate,
                        }),
                        ...(direction !== 'send' &&
                            pairStats.availableIncomingBitrate !== // Daily doesn't support it
                                undefined && {
                                availableIncomingBitrate:
                                    pairStats.availableIncomingBitrate,
                            }),
                    },
                })
            } else if (key.startsWith('RTCOutboundRTPAudioStream_')) {
                const outboundAudioStreamStats = stats.get(
                    key
                ) as RTCOutboundRtpStreamStats

                logger.sample({
                    action: 'Call@' + outboundAudioStreamStats.id,
                    ssrc: outboundAudioStreamStats.ssrc,
                    [Instrumentation.VALUE]: {
                        retransmittedPacketsSent: (
                            outboundAudioStreamStats as any
                        ).retransmittedPacketsSent,
                        bytesSent: outboundAudioStreamStats.bytesSent ?? -1,
                    },
                })
            } else if (key.startsWith('RTCOutboundRTPVideoStream_')) {
                const outboundVideoStreamStats = stats.get(
                    key
                ) as RTCOutboundRtpStreamStats

                logger.sample({
                    action: 'Call@' + outboundVideoStreamStats.id,
                    ssrc: outboundVideoStreamStats.ssrc,
                    frameWidth: (outboundVideoStreamStats as any).frameWidth,
                    frameHeight: (outboundVideoStreamStats as any).frameHeight,
                    [Instrumentation.VALUE]: {
                        pliCount: outboundVideoStreamStats.pliCount ?? -1,
                        framesEncoded:
                            outboundVideoStreamStats.framesEncoded ?? -1,
                        retransmittedPacketsSent:
                            (outboundVideoStreamStats as any)
                                .retransmittedPacketsSent ?? -1,
                    },
                })
            } else if (key.startsWith('RTCInboundRTPAudioStream_')) {
                const inboundAudioStreamStats = stats.get(
                    key
                ) as RTCInboundRtpStreamStats

                logger.sample({
                    action: 'Call@' + inboundAudioStreamStats.id,
                    ssrc: inboundAudioStreamStats.ssrc,
                    peer: senderPeerIdsByTrack[key],
                    [Instrumentation.VALUE]: {
                        concealedSamples:
                            (inboundAudioStreamStats as any).concealedSamples ??
                            -1,
                        packetsLost: inboundAudioStreamStats.packetsLost ?? -1,
                        packetsDiscarded:
                            inboundAudioStreamStats.packetsDiscarded ?? -1,
                        packetsReceived:
                            inboundAudioStreamStats.packetsReceived ?? -1,
                    },
                })
            } else if (key.startsWith('RTCInboundRTPVideoStream_')) {
                const inboundVideoStreamStats = stats.get(
                    key
                ) as RTCInboundRtpStreamStats

                logger.sample({
                    action: 'Call@' + inboundVideoStreamStats.id,
                    ssrc: inboundVideoStreamStats.ssrc,
                    peer: senderPeerIdsByTrack[key],
                    frameWidth: (inboundVideoStreamStats as any).frameWidth,
                    frameHeight: (inboundVideoStreamStats as any).frameHeight,
                    [Instrumentation.VALUE]: {
                        pliCount: inboundVideoStreamStats.pliCount ?? -1,
                        framesDecoded:
                            inboundVideoStreamStats.framesDecoded ?? -1,
                        keyFramesDecoded:
                            (inboundVideoStreamStats as any).keyFramesDecoded ??
                            -1,
                    },
                })
            }
        }
    }

    static from(
        id: string,
        sendConn: RTCPeerConnection,
        recvConn: RTCPeerConnection,
        senderPeerIdMapping: () => Promise<Record<string, string>>
    ) {
        return new WebRTCInstrumentation(
            id,
            [
                {
                    report: sendConn,
                    direction: 'send',
                },
                {
                    report: recvConn,
                    direction: 'recv',
                },
            ],
            senderPeerIdMapping
        )
    }
}
