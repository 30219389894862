import { RoomConnectOptions } from 'livekit-client'

import { api } from '@teamflow/client-api'

export interface LiveKitOptions {
    url: string
    token: string
    options?: RoomConnectOptions
}

export async function getConnectOptions(
    shardId?: string
): Promise<LiveKitOptions> {
    const { data, error } = await api.userOrg.getLiveKitMeetingToken({
        shardId,
    })
    if (!data?.url || !data?.token) {
        throw new Error(
            'Failed to fetch livekit url or token - ' + error?.message
        )
    }
    return {
        url: data.url,
        token: data.token,
        options: {
            autoSubscribe: false,
        },
    }
}
