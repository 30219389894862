import { observer } from 'mobx-react'
import { useCallback, useEffect } from 'react'

import { Participant, rootStore } from '@teamflow/store'
import { AVDeviceError } from '@teamflow/types'

import { Audio } from './Audio'

export const ParticipantAudio = observer(
    ({ participant }: { participant: Participant }) => {
        const isLocal =
            rootStore.participants.localParticipant?.id === participant.id

        // Listen to local audio track `ended` to identify some rare device errors
        useEffect(() => {
            const track = participant.media?.audioTrack
            if (!isLocal || !track) {
                return
            }

            function handler() {
                rootStore.audioVideo.updateMicError(AVDeviceError.Other)
            }

            track.addEventListener('ended', handler)
            return () => {
                track.removeEventListener('ended', handler)
            }
        }, [isLocal, participant.media?.audioTrack])

        const getVolume = useCallback(() => {
            return participant.audioVolume
        }, [participant])

        return (
            <Audio
                participantId={participant.id}
                audioTrack={participant.media?.audioTrack}
                getVolume={getVolume}
                isScreen={false}
                data-testid={`audio-${participant.id}`}
            />
        )
    }
)
