import { observer } from 'mobx-react'
import { useEffect, useMemo } from 'react'
import { createHtmlPortalNode, InPortal } from 'react-reverse-portal'

import rootStore, { Participant } from '@teamflow/store'
import { AVDeviceError } from '@teamflow/types'

import { Video } from './Video'

export const ParticipantVideo = observer(
    ({ participant }: { participant: Participant }) => {
        const isLocal =
            rootStore.participants.localParticipant?.id === participant.id

        const portalNode = useMemo(() => createHtmlPortalNode(), [])

        useEffect(() => {
            participant.updateAVNode(portalNode)

            return () => {
                if (participant.avNode === portalNode)
                    participant.updateAVNode(undefined)
            }
        }, [participant, portalNode])

        // Listen to local video track `ended` to identify some rare device errors
        useEffect(() => {
            const track = participant.media?.videoTrack
            if (!isLocal || !track) {
                return
            }

            function handler() {
                rootStore.audioVideo.updateCameraError(AVDeviceError.Other)
            }

            track.addEventListener('ended', handler)
            return () => {
                track.removeEventListener('ended', handler)
            }
        }, [isLocal, participant.media?.videoTrack])

        return (
            <InPortal node={portalNode}>
                <Video participant={participant} />
            </InPortal>
        )
    }
)
