export enum TrackSubscriptionState {
    UNSUBSCRIBED = 'unsubscribed',
    SUBSCRIBED = 'subscribed',
    STAGED = 'staged',
}

export interface ParticipantTrackSubscriptions {
    audio: TrackSubscriptionState
    video: TrackSubscriptionState
    screen: TrackSubscriptionState
}
