import { styled } from '@stitches/react'
import { observer } from 'mobx-react'
import { useEffect, useMemo, useState } from 'react'

import {
    Box,
    Center,
    Columns,
    Icon,
    Stack,
    Spinner,
    tokens,
} from '@teamflow/design'
import { VIDEO_OFFSET_PERC, VIDEO_SCALE_PERC } from '@teamflow/lib'
import rootStore from '@teamflow/store'

import {
    AV_SETUP_AUDIO_TOGGLE,
    AV_SETUP_VIDEO_TOGGLE,
    track,
} from '../../../../helpers/analytics'
import AudioMeter from '../../../../verse/audio/AudioMeter'
import { AUDIO_ACTIVE_THRESHOLD_DEFAULT } from '../../../../verse/components/audio/AudioDetector'

import Avatar from '../../../common/Avatar'
import { DockButtonTrigger } from '../../../dock/DockButtonTrigger'
import { SpeakingIndicator } from '../../../participants/AudioMeter'

const previewSize = { mobile: 'size240', desktop: 'size320' } as const

const Video = styled('video', {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
})

const SpinnerContainer = styled(Box, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
})

const NoAVContainer = styled(Box, {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    flexGrow: 0,
})

export interface HuddleMediaPreviewProps {
    audioMeter: AudioMeter
    stream?: MediaStream
    pending?: boolean
    ms?: number
    profilePictureUrl?: string
    hasCamera: boolean
}

const styles = {
    width: {
        mobile: 'fill',
        desktop: 'content',
    },
    paddingX: {
        mobile: 'none',
        desktop: 'space96',
    },
    paddingY: {
        mobile: 'space16',
        desktop: 'space32',
    },
    videoSize: {
        width: VIDEO_SCALE_PERC + '%',
        height: VIDEO_SCALE_PERC + '%',
        left: VIDEO_OFFSET_PERC + '%',
        top: VIDEO_OFFSET_PERC + '%',
    },
    micOnIcon: { marginRight: '1px' },
    avatar: {
        width: '100%',
        height: '100%',
    },
} as const

export const AVSetupPreview = observer(
    ({
        audioMeter,
        stream,
        pending,
        ms = 1000 / 20,
        profilePictureUrl,
        hasCamera,
    }: HuddleMediaPreviewProps) => {
        const [peak, setPeak] = useState(0)
        const [videoEl, setVideoEl] = useState<HTMLVideoElement | null>()
        const { micEnabled, cameraEnabled } = rootStore.audioVideo
        const audioEnabled = micEnabled
        const videoEnabled = hasCamera && cameraEnabled
        const { flipCamera } = rootStore.settings
        const { localUser } = rootStore.users

        function handleToggleAudio() {
            track(AV_SETUP_AUDIO_TOGGLE, {
                enabled: !micEnabled,
            })
            void rootStore.audioVideo.toggleMicEnabled(!micEnabled)
        }

        function handleToggleVideo() {
            track(AV_SETUP_VIDEO_TOGGLE, {
                enabled: !cameraEnabled,
            })
            void rootStore.audioVideo.toggleCameraEnabled(!cameraEnabled)
        }

        useEffect(() => {
            const interval = setInterval(() => {
                setPeak(Math.min(audioMeter.peak, 1))
            }, ms)

            return () => {
                clearInterval(interval)
            }
        }, [audioMeter, ms])

        useEffect(() => {
            if (!videoEl) {
                return
            }
            if (stream && videoEnabled) {
                videoEl.autoplay = true
                videoEl.muted = true
                videoEl.srcObject = stream
            } else {
                videoEl.srcObject = null
            }
            return () => {
                stream?.getTracks().forEach((t) => t.stop())
                if (videoEl) videoEl.srcObject = null
            }
        }, [videoEl, stream, videoEnabled])

        const videoWrapperStyle = useMemo(
            () => ({
                borderRadius: '50%',
                transform: flipCamera
                    ? 'scaleX(-1) translateZ(0)'
                    : 'translateZ(0)',
            }),
            [flipCamera]
        )

        const videoCSS = useMemo(
            () => ({
                background: !videoEnabled
                    ? profilePictureUrl
                        ? `url(${profilePictureUrl})`
                        : 'black'
                    : 'transparent',
                backgroundPosition: profilePictureUrl ? 'center' : undefined,
                backgroundSize: profilePictureUrl ? 'contain' : undefined,
            }),
            [videoEnabled, profilePictureUrl]
        )

        return (
            <Box
                borderWidth="borderWidth1"
                borderStyle="solid"
                borderColor="neutral30"
                borderRadius="borderRadius12"
                flexShrink={0}
                height="content"
                width={styles.width}
                paddingX={styles.paddingX}
                paddingY={styles.paddingY}
            >
                <Box height="size32" />
                <Stack alignX="center" space="space24">
                    <Box position="relative">
                        <SpeakingIndicator
                            bgColor={
                                audioEnabled
                                    ? tokens.gradient.gradientOn
                                    : tokens.gradient.gradientOff
                            }
                            diameter={'100%'}
                            selected={false}
                            scale={
                                peak > AUDIO_ACTIVE_THRESHOLD_DEFAULT
                                    ? 1.1
                                    : 1.05
                            }
                        />
                        {pending && (
                            <SpinnerContainer
                                width={previewSize}
                                height={previewSize}
                            >
                                <Spinner size="xl" />
                            </SpinnerContainer>
                        )}
                        {!pending && (!stream || !videoEnabled) && (
                            <NoAVContainer
                                data-testid="av-setup.preview.no-av-container"
                                width={previewSize}
                                height={previewSize}
                            >
                                {localUser?.fullName ? (
                                    <Avatar
                                        size="xxl"
                                        name={localUser.fullName}
                                        src={localUser.profilePictureUrl}
                                        variant="participant"
                                        style={styles.avatar}
                                    />
                                ) : (
                                    <Center
                                        width="fill"
                                        height="fill"
                                        background="neutral90"
                                        borderRadius="full"
                                        paddingTop="space12"
                                    >
                                        <Icon
                                            name="videoDisabled"
                                            size="size64"
                                            color="neutral60"
                                        />
                                    </Center>
                                )}
                            </NoAVContainer>
                        )}
                        {!pending && stream && videoEnabled && (
                            <Box
                                width={previewSize}
                                height={previewSize}
                                overflow="hidden"
                                position="relative"
                                __cssOverrides={videoWrapperStyle}
                            >
                                <Box
                                    position="absolute"
                                    __cssOverrides={styles.videoSize}
                                >
                                    <Video
                                        data-testid="av-setup.preview.video"
                                        playsInline
                                        muted
                                        ref={setVideoEl}
                                        css={videoCSS}
                                    />
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Center>
                        <Columns space="space8">
                            <DockButtonTrigger
                                data-testid="av-setup.audio-toggle"
                                label="Microphone"
                                onSelect={handleToggleAudio}
                                icon={
                                    audioEnabled ? (
                                        <Icon
                                            name="microphone"
                                            color="neutral60"
                                            style={styles.micOnIcon}
                                        />
                                    ) : (
                                        <Icon
                                            name="microphoneDisabled"
                                            color="neutral60"
                                        />
                                    )
                                }
                            />
                            <DockButtonTrigger
                                data-testid="av-setup.video-toggle"
                                label="Camera"
                                onSelect={handleToggleVideo}
                                icon={
                                    videoEnabled ? (
                                        <Icon name="video" color="neutral60" />
                                    ) : (
                                        <Icon
                                            name="videoDisabled"
                                            color="neutral60"
                                        />
                                    )
                                }
                            />
                        </Columns>
                    </Center>
                </Stack>
                <Box height="size8" />
            </Box>
        )
    }
)
