import { useEffect } from 'react'

import { track } from '@teamflow/bootstrap'
import * as optimizely from '@teamflow/web/src/helpers/optimizely'
import useQueryParams from '@teamflow/web/src/hooks/useQueryParams'

import { FOLLOW_CALENDAR_LINK } from '../helpers/analytics'

export function useTrackCalendar() {
    const { source } = useQueryParams()

    useEffect(() => {
        if (source) {
            if (source === 'calendar') {
                optimizely.track('follow-calendar-link')
                track(FOLLOW_CALENDAR_LINK)
            }
        }
    }, [source])
}
