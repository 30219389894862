import livekit from 'livekit-client'

import { isSpecialEffectsCamera } from '../helpers/isSpecialEffectsCamera'

export async function deselectSpecialEffectsCamera(
    room: livekit.Room,
    devices: MediaDeviceInfo[],
    cameraId: string | null
): Promise<string | null> {
    // ensure that the camera is not a special effects camera
    let camera = devices.find((d) => d.deviceId === cameraId)
    if (!isSpecialEffectsCamera(camera)) {
        return camera?.deviceId ?? null
    }

    for (const device of devices) {
        camera = device
        if (!isSpecialEffectsCamera(device)) {
            break
        }
    }
    if (camera?.deviceId && camera?.deviceId !== cameraId) {
        await room.switchActiveDevice('videoinput', camera.deviceId)
    }
    return camera?.deviceId ?? null
}
