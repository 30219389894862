import { autorun } from 'mobx'
import { useCallback, useEffect, useRef } from 'react'

import { useResizeObserver } from '@teamflow/design'
import { AVATAR_SIZE, HD_VIDEO_HEIGHT_THRESHOLD } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { AVState, ParticipantSimulcastLayer } from '@teamflow/types'

export function useAutoVideoSimulcastLayer(
    participantId: string,
    videoEl: HTMLVideoElement | null
) {
    const videoHeightRef = useRef(0)

    const setSimulcastLayer = useCallback(() => {
        const height = videoHeightRef.current
        if (height === 0) return

        const { exceedMaxHighResCameraTracks } = rootStore.performance

        void rootStore.audioVideo.setParticipantVideoSimulcastLayer(
            participantId,
            height >= HD_VIDEO_HEIGHT_THRESHOLD && !exceedMaxHighResCameraTracks
                ? ParticipantSimulcastLayer.High
                : rootStore.audioVideo.defaultVideoLayer
        )
    }, [participantId])

    const callConnected = rootStore.audioVideo.state === AVState.Connected

    useEffect(() => {
        if (!callConnected) return

        // run every time `exceedMaxHighResCameraTracks` changes
        return autorun(setSimulcastLayer)
    }, [callConnected, setSimulcastLayer])

    // If the video is for a participant avatar, size is determined by
    // viewport zoom. Otherwise, it is determined by a resize observer on the
    // video element.
    // This is because in the space, video avatar sizes are set using
    // CSS transform, which resize observer doesn't monitor.
    const isVideoAvatar =
        !rootStore.layout.fullScreenMode &&
        !rootStore.layout.spatialRibbonActive

    useResizeObserver(
        // only enable resize observer if video is not for a participant avatar
        callConnected && !isVideoAvatar ? videoEl : null,
        useCallback(
            (_w: number, h: number) => {
                videoHeightRef.current = h
                setSimulcastLayer()
            },
            [setSimulcastLayer]
        )
    )

    useEffect(() => {
        if (!callConnected) return

        if (isVideoAvatar) {
            // respond to viewport zoom
            return autorun(() => {
                const zoom = rootStore.layout.zoomScaleLevel ?? 1
                videoHeightRef.current = zoom * AVATAR_SIZE
                setSimulcastLayer()
            })
        }
        return undefined
    }, [callConnected, isVideoAvatar, setSimulcastLayer])
}
