import React, { useCallback, useState, CSSProperties } from 'react'

import { Spinner } from '@teamflow/design'

import { SharedProps } from './DockButton'
import { dockButtonTriggerStyles } from './DockButtonTrigger.css'

export type DockButtonTriggerProps = {
    onSelect?: (
        e:
            | React.MouseEvent<HTMLButtonElement>
            | React.TouchEvent<HTMLButtonElement>
    ) => void
    maxAppsReached?: boolean
    noPointerEvents?: boolean
} & SharedProps

export const DockButtonTrigger = React.memo(
    React.forwardRef<HTMLButtonElement | null, DockButtonTriggerProps>(
        function DockButtonTrigger(
            {
                backgroundColor,
                variant,
                maxAppsReached,
                noPointerEvents = false,
                label,
                onSelect,
                icon,
                isLoading = false,
                spinnerKind = 'dark',
                style = {},
                className = '',
                ...props
            },
            ref
        ) {
            const [touchStarted, setTouchStarted] = useState(false)

            const handleTouchStart = useCallback(() => {
                setTouchStarted(true)
            }, [])

            const handleTouchEnd = useCallback(
                (e: React.TouchEvent<HTMLButtonElement>) => {
                    e.preventDefault()

                    if (touchStarted && onSelect) {
                        onSelect(e)
                    }

                    setTouchStarted(false)
                },
                [touchStarted, onSelect]
            )

            const content = isLoading ? (
                <Spinner size="sm" kind={spinnerKind} />
            ) : (
                icon
            )

            const styles: CSSProperties = {
                background: backgroundColor ?? '#F4F4F4',
                pointerEvents: noPointerEvents ? 'none' : undefined,
                ...style,
            }

            if (variant === 'app' && maxAppsReached) {
                style.opacity = 0.6
            }

            return (
                <button
                    disabled={isLoading}
                    className={className + ' ' + dockButtonTriggerStyles}
                    style={styles}
                    aria-label={label}
                    onClick={onSelect}
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    ref={ref}
                    {...props}
                >
                    {content}
                </button>
            )
        }
    )
)
