import { observer } from 'mobx-react'
import { Fragment } from 'react'

import rootStore from '@teamflow/store'

import { ParticipantAudio } from './ParticipantAudio'
import { ParticipantVideo } from './ParticipantVideo'

export const CallMedia = observer(function CallMedia() {
    const { localParticipant } = rootStore.participants

    // This ensures we don't play call media when the local user is not in the virtual world, present to
    // other people, which can occur because of a disconnection to the Colyseus server.
    const localReady = localParticipant?.ready

    const participants =
        rootStore.participants.participantsInSameAVConnectedSpace

    return (
        <>
            {localParticipant && (
                <ParticipantVideo participant={localParticipant} />
            )}
            {localReady &&
                participants
                    .filter((p) => p !== localParticipant)
                    .map((p) => (
                        <Fragment key={p.id}>
                            <ParticipantVideo participant={p} />
                            <ParticipantAudio participant={p} />
                        </Fragment>
                    ))}
        </>
    )
})
