import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Text } from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import { AVDeviceError } from '@teamflow/types'

const translationPage = 'common'
const tKeys = allTKeys.common.avPermissions

interface Props {
    permissionsPending: boolean
    micErrorCode?: AVDeviceError
    camErrorCode?: AVDeviceError
}

export const Title = memo(
    ({ permissionsPending, micErrorCode, camErrorCode }: Props) => {
        const { t } = useTranslation(translationPage)

        const tKey = useMemo(() => {
            if (permissionsPending) {
                return tKeys.allowTeamflowToUseYourCameraAndMicrophone
            }
            if (micErrorCode && camErrorCode) {
                return tKeys.yourCameraAndMicAreBlocked
            }
            if (micErrorCode) {
                return tKeys.yourMicIsBlocked
            }
            if (camErrorCode === AVDeviceError.InUse) {
                return tKeys.yourCameraIsInUseByAnotherApplication
            }
            if (camErrorCode) {
                return tKeys.yourCameraIsBlocked
            }
        }, [permissionsPending, micErrorCode, camErrorCode])

        if (!tKey) return null

        return (
            <Box marginTop="space4">
                <Text color="neutral90" size="textSize16" weight="600">
                    {t(tKey)}
                </Text>
            </Box>
        )
    }
)
