import { IVerseParamsProvider } from '@teamflow/types'

import type { VerseParams } from './parseVerseQueryParams'

class UrlQueryParam implements IVerseParamsProvider {
    private params?: VerseParams
    constructor(params?: VerseParams) {
        this.params = params
    }

    get() {
        return this.params
    }

    set(params: VerseParams) {
        const pushType = this.params?.pushType
        const pushSenderId = this.params?.pushSenderId
        const mobileWebView = Boolean(this.params?.mobileWebView)
        const hideMobileNavigation = Boolean(this.params?.hideMobileNavigation)

        this.params = params

        this.params.pushType = params.pushType ?? pushType
        this.params.pushSenderId = params.pushSenderId ?? pushSenderId
        this.params.mobileWebView =
            Boolean(params.mobileWebView) ?? mobileWebView
        this.params.hideMobileNavigation =
            Boolean(params.hideMobileNavigation) ?? hideMobileNavigation

        if (params.scene) {
            this.params.scene = params.scene
        }
    }

    removeMobilePushParams() {
        delete this.params?.pushType
        delete this.params?.pushSenderId
    }
}

export default new UrlQueryParam()
