const keys = [
    'id',
    'kind',
    'label',
    'enabled',
    'muted',
    'readyState',
    'contentHint',
] as const

/**
 * Useful for logging an audio or video element's media track.
 */
export function getTrackAsPlainObj(
    mediaEl: HTMLMediaElement
): Pick<MediaStreamTrack, typeof keys[number]> | null {
    if (!(mediaEl.srcObject instanceof MediaStream)) return null

    const track = mediaEl.srcObject.getTracks()[0]
    if (!track) return null

    return castTrackAsPlainObj(track)
}

/**
 * See {@link getTrackAsPlainObj} but better
 */
export function castTrackAsPlainObj(
    track: MediaStreamTrack
): Pick<MediaStreamTrack, typeof keys[number]> | null {
    const obj: any = {}
    keys.forEach((key) => {
        obj[key] = track[key]
    })
    return obj
}
