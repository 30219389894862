import { DailyCall } from '@daily-co/daily-js'

import { LogManager, retryWithTimeout } from '@teamflow/lib'
import { AVInputDevices } from '@teamflow/types'

export async function getCallInputDevices(
    call?: DailyCall
): Promise<AVInputDevices> {
    if (!call) {
        return Promise.resolve({
            micId: null,
            cameraId: null,
        })
    }
    return retryWithTimeout<AVInputDevices>({
        action: async function getInputDevices() {
            const { mic, camera } = await call.getInputDevices()
            const micId = 'deviceId' in mic ? mic.deviceId : null
            const cameraId = 'deviceId' in camera ? camera.deviceId : null
            return { micId, cameraId }
        },
        times: 5,
        timeout: 2000,
        timeoutMaxValue: 6000,
        timeoutMultiplier: 1.5,
        cooldown: 0,
        shouldRetry: (error) => {
            LogManager.global.error(
                'getCallInputDevices: Got an error while retrieving input devices from Daily',
                { error, critical: true, call: true }
            )
            return true
        },
    })
}
