import {
    TrackPublishOptions,
    VideoCaptureOptions,
    VideoPreset,
} from 'livekit-client'

import * as t from '@teamflow/types'

import { getVideoParameters } from '../helpers/getVideoParameters'

export function getVideoOptions(
    featureFlagService: t.IFeatureFlagService,
    reduceUploadBandwidth: boolean
): {
    options: VideoCaptureOptions
    publishOptions: TrackPublishOptions
} {
    const { videoKbps, videoFps, videoResolution, videoLowScaleDown } =
        getVideoParameters(featureFlagService)

    const highVideoPreset = new VideoPreset(
        videoResolution.width,
        videoResolution.height,
        videoKbps.high * 1000,
        videoFps.high
    )

    const lowVideoPreset = new VideoPreset(
        Math.floor(videoResolution.width / videoLowScaleDown),
        Math.floor(videoResolution.height / videoLowScaleDown),
        videoKbps.low * 1000,
        videoFps.low
    )

    if (reduceUploadBandwidth) {
        return {
            options: {
                resolution: lowVideoPreset.resolution,
            },
            publishOptions: {
                simulcast: false,
                videoEncoding: lowVideoPreset.encoding,
            },
        }
    }

    return {
        options: {
            resolution: highVideoPreset.resolution, // (1)
        },
        publishOptions: {
            simulcast: true,
            videoEncoding: highVideoPreset.encoding, // (2)

            // LiveKit automatically adds an 'original' simulcast layer
            // based on video capture resolution (1) and video encoding (2),
            // so we shouldn't put 'highVideoPreset' into this list
            videoSimulcastLayers: [lowVideoPreset],
        },
    }
}
