import { featureFlags } from '@teamflow/bootstrap'
import rootStore from '@teamflow/store'
import { Feature } from '@teamflow/types'

/**
 * Get adjusted volume based on master volume and FF.
 */
export const getGlobalVolumeMultiplier = () => {
    const masterVolumeFeatureEnabled = featureFlags.isEnabledSync(
        Feature.MasterVolumeControl
    )

    return masterVolumeFeatureEnabled ? rootStore.settings.masterVolume : 1
}
