import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import newrelic from 'newrelic'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useEffect, useMemo, useState } from 'react'

import { useLoadTeamflow, useFeatureFlag } from '@teamflow/bootstrap'
import { getPublicUrl } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { Feature } from '@teamflow/types'
import { ScreenLoader } from '@teamflow/web/src/components/common/ScreenLoader'
import GuestLogin from '@teamflow/web/src/components/signup/GuestLogin'
import DeniedScreen from '@teamflow/web/src/components/signup/denied'
import LockedRoomGuestWaiting from '@teamflow/web/src/components/signup/lockedRoomGuestWaiting'
import { LoadingFunnel, track } from '@teamflow/web/src/helpers/analytics'
import { useMaintenanceMode } from '@teamflow/web/src/hooks/useMaintenanceMode'
import { useParams } from '@teamflow/web/src/hooks/useParams'
import { useTrackCalendar } from '@teamflow/web/src/hooks/useTrackCalendar'
import { useTrackFlow, Flow, Events } from '@teamflow/web/src/hooks/userFlow'

import { TransactionTraceparentHeader } from '../components/TransactionTraceparentHeader'
import { configureTranslations, initializeFlutterWebview } from '../src/util'

initializeFlutterWebview()

configureTranslations(['common', 'signup'])

const Verse = dynamic(
    () => {
        track(LoadingFunnel.VerseDynamicLoadingInitiated)
        return import('@teamflow/web')
    },
    {
        ssr: false,
    }
)

export async function getServerSideProps() {
    const transaction = newrelic.getTransaction()
    const headers = {} as Record<string, string>
    transaction.insertDistributedTraceHeaders(headers)

    return {
        props: {
            traceparent: headers['traceparent'] ?? null,
        },
    }
}

export default observer(function VersePage({
    traceparent,
}: {
    traceparent?: string
}): JSX.Element {
    const user = rootStore.users.localUser

    const { enabled: chatNotificationFavicon } = useFeatureFlag({
        flag: Feature.ChatNotificationFavicon,
    })

    const params = useParams()

    useTrackCalendar()

    useTrackFlow(Flow.Login, Events.OrgPage)

    useMaintenanceMode()

    const [chatAlertFavicon, setChatAlertFavicon] = useState(false)
    const favicon = chatAlertFavicon
        ? getPublicUrl('/favicon_alert.ico')
        : getPublicUrl('/favicon.ico')

    useEffect(() => {
        if (!chatNotificationFavicon) return
        return autorun(() => {
            const avConnectedSpace = rootStore.commons.avConnectedSpace
            const chatsById = rootStore.chat.chatsById
            const chatSessionAvConnectedTo =
                avConnectedSpace === ''
                    ? 'floor'
                    : rootStore.rooms.roomsById.get(avConnectedSpace)
                          ?.sessionId ?? ''
            const chat = chatsById['room']?.[chatSessionAvConnectedTo]
            const hasUnreadsInCurrentSpace = (chat?.badgeCount ?? 0) > 0
            setChatAlertFavicon(hasUnreadsInCurrentSpace)
        })
    }, [chatNotificationFavicon])

    const userInMeeting = rootStore.commons.userInMeetingRoom

    const tabTitle = useMemo(
        () =>
            userInMeeting
                ? 'Meeting'
                : user?.currentOrgDisplayName ?? 'Loading...',
        [user?.currentOrgDisplayName, userInMeeting]
    )

    const { TeamflowComponent } = useLoadTeamflow({
        VerseComponent: Verse,
        ScreenLoaderComponent: ScreenLoader,
        GuestLoginComponent: GuestLogin,
        DeniedScreenComponent: DeniedScreen,
        LockedRoomGuestWaitingComponent: LockedRoomGuestWaiting,
        HeadElement: (
            <Head>
                <title>Teamflow - {tabTitle}</title>
                <link rel="icon" href={favicon} />
                <script src="//fast.appcues.com/111398.js"></script>
            </Head>
        ),
        track,
        params,
    })

    return (
        <>
            <TransactionTraceparentHeader traceparent={traceparent} />
            {TeamflowComponent}
        </>
    )
})
