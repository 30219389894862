import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import {
    MouseEvent,
    PropsWithChildren,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useAudio } from '@teamflow/client-call'
import {
    Box,
    Button,
    Column,
    Columns,
    Icon,
    Label,
    Stack,
    tokens,
    Tooltip,
} from '@teamflow/design'
import { getPublicUrlSfx } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'

import {
    AV_SETUP_AUDIO_DEVICE_CHANGE,
    AV_SETUP_SPEAKER_DEVICE_CHANGE,
    AV_SETUP_VIDEO_DEVICE_CHANGE,
    track,
} from '../../../../helpers/analytics'
import AudioMeter from '../../../../verse/audio/AudioMeter'

import HuddleDropdownMenu from '../../../common/HuddleDropdownMenu'
import { MenuDivider } from '../../../common/MenuDivider'

import { mapDevicesToOptions } from '../helpers/mapDevicesToOptions'
import { useWebAudioSuspended } from '../hooks/useWebAudioSuspended'

import AVSetupAudioMeter from './AVSetupAudioMeter'

const translationPage = 'signup'
const tKeys = allTKeys.signup.avSetup

const testSpeakerStyle = {
    position: 'absolute',
    top: -6,
    right: 0,
} as const

const StackToColumns = ({
    isMobileWidth,
    children,
}: PropsWithChildren<{ isMobileWidth: boolean }>) => {
    return isMobileWidth ? (
        <Stack space="space24">{children}</Stack>
    ) : (
        <Columns space="space24">{children}</Columns>
    )
}

export const AVDeviceSelection = observer(
    ({
        isMobileWidth,
        isMediaPending,
        isMediaDenied,
        hasCamera,
        audioMeter,
    }: {
        isMobileWidth: boolean
        isMediaPending: boolean
        isMediaDenied: boolean
        hasCamera: boolean
        audioMeter: AudioMeter
    }) => {
        const [isMicWorking, setIsMicWorking] = useState(true)

        const { t } = useTranslation(translationPage)
        const suspended = useWebAudioSuspended()

        const {
            micId,
            speakerId,
            cameraId,
            mics,
            cameras,
            speakers,
            trackSystemMic,
            trackSystemCamera,
            trackSystemSpeaker,
            micEnabled,
        } = rootStore.audioVideo

        const hasMic = isMediaPending ? true : mics.length > 0
        const hasSpeaker = isMediaPending ? true : speakers.length > 0

        const SAME_AS_SYSTEM_OPTION = useMemo(
            () => ({
                label: t(tKeys.sameAsSystem),
                value: 'sameAsSystem',
            }),
            [t]
        )

        // Automatically detect if we are receiving sound through the user's microphone
        useEffect(() => {
            let audioDetected = false
            let timeoutId = 0

            const interval = setInterval(() => {
                if (audioMeter.peak > 0.005) {
                    audioDetected = true

                    if (!isMicWorking) {
                        window.clearTimeout(timeoutId)
                        setIsMicWorking(true)
                    }
                }
            }, 200)

            if (micEnabled && !isMediaPending && !isMediaDenied && !suspended) {
                window.clearTimeout(timeoutId)
                timeoutId = window.setTimeout(() => {
                    if (!audioDetected) {
                        setIsMicWorking(false)
                    }
                }, 5000)
            }

            return () => {
                clearInterval(interval)
                window.clearTimeout(timeoutId)
            }
        }, [
            audioMeter,
            isMediaPending,
            isMediaDenied,
            isMicWorking,
            micId,
            micEnabled,
            suspended,
        ])

        const { play: playTestSpeakerAudio, sinkIdSupported } = useAudio(
            getPublicUrlSfx('shoulder_tap.mp3'),
            true
        )

        function handleAudioInChange(value: string) {
            const trackSystem = value === SAME_AS_SYSTEM_OPTION.value
            const deviceId = trackSystem ? mics[0].deviceId : value
            const device = mics.find((device) => device.deviceId === deviceId)

            track(AV_SETUP_AUDIO_DEVICE_CHANGE, {
                device: device?.label,
            })
            runInAction(() => {
                rootStore.audioVideo.micId = deviceId
                rootStore.audioVideo.preferredMicId = deviceId
                rootStore.audioVideo.trackSystemMic = trackSystem
            })
        }

        async function handleAudioOutChange(value: string) {
            const trackSystem = value === SAME_AS_SYSTEM_OPTION.value
            const deviceId = trackSystem ? speakers[0].deviceId : value
            const device = speakers.find(
                (device) => device.deviceId === deviceId
            )

            track(AV_SETUP_SPEAKER_DEVICE_CHANGE, {
                device: device?.label,
            })
            runInAction(() => {
                rootStore.audioVideo.speakerId = deviceId
                rootStore.audioVideo.preferredSpeakerId = deviceId
                rootStore.audioVideo.trackSystemSpeaker = trackSystem
            })
        }

        function handleVideoInChange(value: string) {
            const trackSystem = value === SAME_AS_SYSTEM_OPTION.value
            const deviceId = trackSystem ? cameras[0].deviceId : value
            const device = cameras.find(
                (device) => device.deviceId === deviceId
            )

            track(AV_SETUP_VIDEO_DEVICE_CHANGE, {
                device: device?.label,
            })
            runInAction(() => {
                rootStore.audioVideo.cameraId = deviceId
                rootStore.audioVideo.preferredCameraId = deviceId
                rootStore.audioVideo.trackSystemCamera = trackSystem
            })
        }

        async function handleAudioOutputTest(
            event: MouseEvent<HTMLButtonElement>
        ) {
            event.preventDefault()
            event.stopPropagation()

            playTestSpeakerAudio()
        }

        return (
            <>
                <Stack space="space20">
                    <StackToColumns isMobileWidth={isMobileWidth}>
                        <Column>
                            <Stack space="space4" width="fill">
                                <Tooltip
                                    open={!isMicWorking}
                                    arrow
                                    side="bottom"
                                    animate
                                    content={
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            gap="space8"
                                        >
                                            <Icon
                                                name="warning"
                                                size="size20"
                                            />
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                gap="space4"
                                            >
                                                <Box>
                                                    {t(tKeys.cantHearYou)}
                                                </Box>
                                                <Box>
                                                    {t(tKeys.switchYourInput)}
                                                </Box>
                                            </Box>
                                        </Box>
                                    }
                                    backgroundColor={tokens.color.danger}
                                >
                                    <HuddleDropdownMenu
                                        data-testid="select-microphone"
                                        width="fill"
                                        label={t(tKeys.microphone)}
                                        icon={
                                            <Icon
                                                color="neutral50"
                                                name="microphone"
                                            />
                                        }
                                        loading={isMediaPending}
                                        themeMode="light"
                                        options={mapDevicesToOptions(
                                            SAME_AS_SYSTEM_OPTION,
                                            mics
                                        )}
                                        value={
                                            trackSystemMic
                                                ? SAME_AS_SYSTEM_OPTION.value
                                                : micId ?? undefined
                                        }
                                        onChange={handleAudioInChange}
                                        labelNoOptions={
                                            hasMic
                                                ? undefined
                                                : t(tKeys.noneAvailable)
                                        }
                                    />
                                </Tooltip>
                            </Stack>
                        </Column>
                        {sinkIdSupported && hasSpeaker && (
                            <Column position="relative">
                                <Stack space="space4" width="fill">
                                    <HuddleDropdownMenu
                                        data-testid="select-speakers"
                                        width="fill"
                                        label={t(tKeys.speakers)}
                                        icon={
                                            <Icon
                                                color="neutral50"
                                                name="audio"
                                            />
                                        }
                                        loading={isMediaPending}
                                        themeMode="light"
                                        options={mapDevicesToOptions(
                                            SAME_AS_SYSTEM_OPTION,
                                            speakers
                                        )}
                                        value={
                                            trackSystemSpeaker
                                                ? SAME_AS_SYSTEM_OPTION.value
                                                : speakerId ?? undefined
                                        }
                                        onChange={handleAudioOutChange}
                                        labelNoOptions={
                                            hasSpeaker
                                                ? undefined
                                                : t(tKeys.noneAvailable)
                                        }
                                    />
                                    <Button
                                        size="xs"
                                        kind="link"
                                        level="neutral"
                                        onClick={handleAudioOutputTest}
                                        __cssOverrides={testSpeakerStyle}
                                    >
                                        {t(tKeys.test)}
                                    </Button>
                                </Stack>
                            </Column>
                        )}
                    </StackToColumns>
                    <Stack space="space12" width="fill">
                        <Label>{t(tKeys.testYourInput)}</Label>
                        <AVSetupAudioMeter
                            audioMeter={audioMeter}
                            micEnabled={micEnabled}
                        />
                    </Stack>
                </Stack>
                <MenuDivider />
                <Stack space="space4">
                    <HuddleDropdownMenu
                        data-testid="select-camera"
                        label={t(tKeys.video)}
                        icon={<Icon color="neutral50" name="video" />}
                        loading={isMediaPending}
                        themeMode="light"
                        options={mapDevicesToOptions(
                            SAME_AS_SYSTEM_OPTION,
                            cameras
                        )}
                        value={
                            trackSystemCamera
                                ? SAME_AS_SYSTEM_OPTION.value
                                : cameraId ?? undefined
                        }
                        onChange={handleVideoInChange}
                        labelNoOptions={
                            hasCamera ? undefined : t(tKeys.noneAvailable)
                        }
                    />
                </Stack>
                <Box />
            </>
        )
    }
)
