/* Remove after AV settings becomes permanent */
import { useCallback, useEffect, useState } from 'react'

import { GuestLoginProps } from '@teamflow/bootstrap'
import { Box } from '@teamflow/design'
import rootStore from '@teamflow/store'
import { FlowType, JoinAccess } from '@teamflow/types'
import { useRejectFlow, Flow, Events } from '@teamflow/web/src/hooks/userFlow'

import {
    LoadingFunnel,
    track,
    Flow as AnalyticsFlow,
} from '../../helpers/analytics'
import { signupToOrgs } from '../../requests'

import Page from '../common/Page'
import Root from '../common/Root'

import AVSettingsInterstitial from './AVSettingsInterstitial'

import { ChooseInviteFromEmail } from '.'

const styles = {
    container: {
        zIndex: 1,
    },
} as const

export default function GuestLogin({
    orgDisplayName,
    setAccess,
    orgSlug,
    roomSlug,
    inviteCode,
    invitation,
}: GuestLoginProps) {
    const [signupEmail, setSignupEmail] = useState<string | undefined>()
    const [signupDismissed, setSignupDismissed] = useState(false)
    useRejectFlow(Flow.Login, Events.GuestLogin)

    useEffect(() => {
        track(LoadingFunnel.HitGuestLogin)
    }, [])

    const resendChooseInviteEmail = useCallback(() => {
        void signupToOrgs(signupEmail)
    }, [signupEmail])

    const dismissSignup = useCallback(() => {
        setSignupDismissed(true)
    }, [])

    const handleSubmit = useCallback(
        (access?: JoinAccess, email?: string) => {
            rootStore.audioVideo.setIsSetup(true)
            if (access === JoinAccess.Signup && email) {
                setSignupEmail(email)
            } else if (access) {
                setAccess(access)
            }
        },
        [setAccess, setSignupEmail]
    )

    return (
        <Root>
            <AVSettingsInterstitial
                checkDomain={!signupDismissed}
                orgName={orgDisplayName}
                orgSlug={orgSlug}
                roomSlug={roomSlug}
                inviteCode={inviteCode}
                invitation={invitation}
                onSubmit={handleSubmit}
                signup="guest"
                source={AnalyticsFlow.Guest}
                flowType={FlowType.Normal}
            />
            {signupEmail && !signupDismissed && (
                <Box
                    position="absolute"
                    top="none"
                    left="none"
                    background="neutral10"
                    width="fill"
                    height="fill"
                    __cssOverrides={styles.container}
                >
                    <Page showLogin>
                        <ChooseInviteFromEmail
                            email={signupEmail}
                            kind="guest"
                            org={orgDisplayName}
                            resendChooseInviteEmail={resendChooseInviteEmail}
                            dismissSignup={dismissSignup}
                        />
                    </Page>
                </Box>
            )}
        </Root>
    )
}
