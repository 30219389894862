import {
    ConnectionQuality,
    Participant as LiveKitParticipant,
} from 'livekit-client'

import {
    AV_LOW_QUALITY_THRESHOLD,
    AV_MEDIUM_QUALITY_THRESHOLD,
} from '@teamflow/lib'
import { INetworkQuality, QualityThreshold } from '@teamflow/types'

export function getNetworkQuality(p: LiveKitParticipant): INetworkQuality {
    const quality = p.connectionQuality

    const nq: INetworkQuality = {
        quality: 0,
        qualityThreshold: QualityThreshold.UNKNOWN,
        packetLoss: false,
        receivePacketLoss: 0,
        sendPacketLoss: 0,
    }

    switch (quality) {
        case ConnectionQuality.Excellent:
            nq.quality = 100
            nq.qualityThreshold = QualityThreshold.HIGH
            break
        case ConnectionQuality.Good:
            nq.quality = AV_MEDIUM_QUALITY_THRESHOLD
            nq.qualityThreshold = QualityThreshold.MEDIUM
            nq.packetLoss = true
            break
        case ConnectionQuality.Poor:
            nq.quality = AV_LOW_QUALITY_THRESHOLD
            nq.qualityThreshold = QualityThreshold.LOW
            nq.packetLoss = true
            break
    }
    return nq
}
