import { clamp, LogManager } from '@teamflow/lib'
import { ISound, ISoundOptions } from '@teamflow/types'

function getPeak(values: Float32Array) {
    let peak = 0
    for (let i = 0; i < values.length; i++) {
        const value = Math.abs(values[i])
        if (value > peak) {
            peak = value
        }
    }
    return peak
}

function getRms(values: Float32Array) {
    let totalSquared = 0
    for (let i = 0; i < values.length; i++) {
        const value = values[i]
        totalSquared += value * value
    }
    return Math.sqrt(totalSquared / values.length)
}

type AudioSourceType =
    | AudioBufferSourceNode
    | MediaStreamAudioSourceNode
    | MediaElementAudioSourceNode

export default abstract class BaseSound<SourceType extends AudioSourceType>
    implements ISound
{
    private _source: SourceType
    private _gainNode: GainNode
    private _stereoPannerNode?: StereoPannerNode
    private _analyserNode?: AnalyserNode
    private _muteNode?: GainNode
    private _sampleBuffer?: Float32Array

    protected get source() {
        return this._source
    }

    protected get gainNode() {
        return this._gainNode
    }

    protected get stereoPannerNode() {
        return this._stereoPannerNode
    }

    get volume() {
        return this._gainNode.gain.value
    }

    set volume(v: number) {
        if (Number.isNaN(v)) {
            LogManager.global.warn('Attempt to set volume with NaN value')
            return
        }
        this._gainNode.gain.value = clamp(v, 0, 1)
    }

    get stereoPosition() {
        if (!this._stereoPannerNode) {
            return 0
        }
        return this._stereoPannerNode.pan.value
    }

    set stereoPosition(v: number) {
        if (!this._stereoPannerNode) {
            return
        }
        this._stereoPannerNode.pan.value = clamp(v, -1, 1)
    }

    get peak() {
        return getPeak(this.updateWaveform())
    }

    get rms() {
        return getRms(this.updateWaveform())
    }

    constructor(
        source: SourceType,
        context: AudioContext,
        options: ISoundOptions = {}
    ) {
        this._source = source
        this._gainNode = context.createGain()

        const {
            stereoPan = true,
            analyser = false,
            isLocalUser = false,
        } = options

        let finalNode: AudioNode = this._gainNode

        if (analyser) {
            this._analyserNode = context.createAnalyser()
            this._analyserNode.fftSize = 512
            this._source.connect(this._analyserNode)
            this._analyserNode.connect(this._gainNode)
        } else {
            this._source.connect(this._gainNode)
        }

        if (!isLocalUser && stereoPan && 'createStereoPanner' in context) {
            this._stereoPannerNode = context.createStereoPanner()
            this._gainNode.connect(this._stereoPannerNode)
            finalNode = this._stereoPannerNode
        }

        if (isLocalUser) {
            this._muteNode = context.createGain()
            this._muteNode.gain.value = 0
            finalNode.connect(this._muteNode)
            finalNode = this._muteNode
        }

        finalNode.connect(context.destination)
    }

    updateWaveform() {
        if (!this._analyserNode) {
            return new Float32Array()
        }

        if (
            !this._sampleBuffer ||
            this._sampleBuffer.length !== this._analyserNode.fftSize
        ) {
            this._sampleBuffer = new Float32Array(this._analyserNode.fftSize)
        }

        this._analyserNode.getFloatTimeDomainData(this._sampleBuffer)

        return this._sampleBuffer
    }

    destroy() {
        this._source.disconnect()
        this._gainNode.disconnect()
        if (this._analyserNode) this._analyserNode.disconnect()
        if (this._stereoPannerNode) this._stereoPannerNode.disconnect()
        if (this._muteNode) this._muteNode.disconnect()
    }
}
