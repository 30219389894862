import { autorun } from 'mobx'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'

import { useMounted } from '../../../../hooks/useMounted'

export enum UserMediaDeviceKind {
    AudioInput = 'audioinput',
    AudioOutput = 'audiooutput',
    VideoInput = 'videoinput',
}

export enum UserMediaErrorReason {
    Denied = 'denied',
    Undetected = 'undetected',
}

export interface UserMediaController {
    error?: Error
    audioStream?: MediaStream
    videoStream?: MediaStream
    pending: boolean
}

export async function requestUserMedia(
    constraints?: MediaStreamConstraints
): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia(
        constraints ?? { audio: true, video: true }
    )
}

export async function detectMediaDevices() {
    return navigator.mediaDevices.enumerateDevices()
}

export async function detectCamera() {
    const devices = await detectMediaDevices()
    return !!devices.find((d) => d.kind === 'videoinput')
}

export function stopTracks(stream?: MediaStream) {
    stream?.getTracks().forEach((t) => t.stop())
}

export function useUserMedia(deviceAccess?: {
    audio: boolean
    video: boolean
}): UserMediaController {
    const [error, setError] = useState<Error>()
    const [streamAcquired, setStreamAcquired] = useState(false)
    const [audioStream, setAudioStream] = useState<MediaStream>()
    const [videoStream, setVideoStream] = useState<MediaStream>()

    const streamAudioInput = useCallback(async (deviceId?: string) => {
        if (!deviceId) {
            setAudioStream((current) => {
                stopTracks(current)
                return undefined
            })
            return
        }

        try {
            const mediaStream = await requestUserMedia({
                audio: { deviceId },
                video: false,
            })
            setAudioStream((current) => {
                stopTracks(current)
                return mediaStream
            })
        } catch (error) {
            setAudioStream((current) => {
                stopTracks(current)
                return undefined
            })
            setError(new Error(UserMediaErrorReason.Denied))
        }
    }, [])

    useEffect(
        () =>
            autorun(() => {
                const micId = rootStore.audioVideo.micId
                const micEnabled = rootStore.audioVideo.micEnabled
                const deviceId = micEnabled && micId ? micId : undefined
                void streamAudioInput(deviceId)
            }),
        [streamAudioInput]
    )

    const streamVideoInput = useCallback(async (deviceId?: string) => {
        if (!deviceId) {
            setVideoStream((current) => {
                stopTracks(current)
                return undefined
            })
            return
        }

        try {
            const mediaStream = await requestUserMedia({
                audio: false,
                video: { deviceId },
            })
            setVideoStream((current) => {
                stopTracks(current)
                return mediaStream
            })
        } catch (error) {
            setVideoStream((current) => {
                stopTracks(current)
                return undefined
            })
            setError(new Error(UserMediaErrorReason.Denied))
        }
    }, [])

    useEffect(
        () =>
            autorun(() => {
                const cameraId = rootStore.audioVideo.cameraId
                const cameraEnabled = rootStore.audioVideo.cameraEnabled
                const deviceId =
                    cameraEnabled && cameraId ? cameraId : undefined
                void streamVideoInput(deviceId)
            }),
        [streamVideoInput]
    )

    useEffect(() => {
        async function asyncEffect() {
            try {
                if (
                    deviceAccess ||
                    rootStore.audioVideo.micEnabled ||
                    rootStore.audioVideo.cameraEnabled
                ) {
                    const constraints: MediaStreamConstraints = {}

                    if (deviceAccess?.audio !== false) {
                        const micId = rootStore.audioVideo.micId
                        const micEnabled = rootStore.audioVideo.micEnabled
                        const deviceId = micEnabled && micId ? micId : undefined

                        constraints.audio = { deviceId }
                    }

                    if (
                        deviceAccess?.video !== false &&
                        rootStore.audioVideo.cameraEnabled &&
                        (await detectCamera())
                    ) {
                        const cameraId = rootStore.audioVideo.cameraId
                        const cameraEnabled = rootStore.audioVideo.cameraEnabled
                        const deviceId =
                            cameraEnabled && cameraId ? cameraId : undefined

                        constraints.video = { deviceId }
                    }

                    const stream = await requestUserMedia(constraints)

                    const [audioTrack] = stream.getAudioTracks()
                    if (rootStore.audioVideo.micEnabled && audioTrack) {
                        setAudioStream((current) => {
                            stopTracks(current)
                            return new MediaStream([audioTrack])
                        })
                    } else {
                        audioTrack?.stop()
                        setAudioStream((current) => {
                            stopTracks(current)
                            return undefined
                        })
                    }

                    const [videoTrack] = stream.getVideoTracks()
                    if (rootStore.audioVideo.cameraEnabled && videoTrack) {
                        setVideoStream((current) => {
                            stopTracks(current)
                            return new MediaStream([videoTrack])
                        })
                    } else {
                        videoTrack?.stop()
                        setVideoStream((current) => {
                            stopTracks(current)
                            return undefined
                        })
                    }
                }
                setError(undefined)
                setStreamAcquired(true)
                try {
                    await rootStore.audioVideo.requestDevices()
                } catch (error) {
                    LogManager.global.error(
                        'AVSettingsInterstitial updateDeviceMap error',
                        error
                    )
                    setError(new Error(UserMediaErrorReason.Undetected))
                }
            } catch (error) {
                LogManager.global.error(
                    'AVSettingsInterstitial requestUserMedia error',
                    error
                )
                setError(new Error(UserMediaErrorReason.Denied))
            }
        }
        void asyncEffect()
    }, [deviceAccess])

    const isMounted = useMounted()

    useEffect(() => {
        return () => {
            if (!isMounted()) {
                stopTracks(audioStream)
                stopTracks(videoStream)
            }
        }
    }, [audioStream, isMounted, videoStream])

    const pending = !(error || streamAcquired)

    return useMemo(
        () => ({
            error,
            audioStream,
            videoStream,
            pending,
        }),
        [error, audioStream, videoStream, pending]
    )
}
