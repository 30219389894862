import rootStore, { Participant } from '@teamflow/store'
import { IFeatureFlagService } from '@teamflow/types'

import { VIDEO_TIMEOUT_MS } from '../const'
import { ParticipantTrackSubscriptions, TrackSubscriptionState } from '../types'

import { getScreenSubscription } from './getScreenSubscription'

export const getParticipantSubscriptions = (
    participant: Participant,
    sharedFlags: IFeatureFlagService
): ParticipantTrackSubscriptions => {
    const { softDisconnect, allAudioEnabled, allVideoEnabled } =
        rootStore.audioVideo
    const { proximal, audio, video } = participant.spatial

    let audioSubscription = TrackSubscriptionState.UNSUBSCRIBED

    if (
        rootStore.commons.ready &&
        participant.ready &&
        allAudioEnabled &&
        audio.required
    ) {
        audioSubscription = participant.shouldHearAudio
            ? TrackSubscriptionState.SUBSCRIBED
            : TrackSubscriptionState.STAGED
    }

    const videoReady =
        rootStore.commons.ready &&
        participant.ready &&
        allVideoEnabled &&
        proximal

    let videoSubscription: TrackSubscriptionState =
        TrackSubscriptionState.UNSUBSCRIBED

    if (videoReady) {
        if (video.required) {
            videoSubscription = TrackSubscriptionState.SUBSCRIBED
        } else if (Date.now() - video.lastModified < VIDEO_TIMEOUT_MS) {
            videoSubscription = TrackSubscriptionState.STAGED
        }
    }

    let screenSubscription = getScreenSubscription(
        participant.id,
        sharedFlags,
        audioSubscription
    )

    if (softDisconnect) {
        if (audioSubscription === TrackSubscriptionState.SUBSCRIBED)
            audioSubscription = TrackSubscriptionState.STAGED
        if (videoSubscription === TrackSubscriptionState.SUBSCRIBED)
            videoSubscription = TrackSubscriptionState.STAGED
        if (screenSubscription === TrackSubscriptionState.SUBSCRIBED)
            screenSubscription = TrackSubscriptionState.STAGED
    }

    return {
        audio: audioSubscription,
        video: videoSubscription,
        screen: screenSubscription,
    }
}
