import { useEffect, useState } from 'react'

import { Box } from '@teamflow/design'

interface Props {
    value: number
    themeMode?: 'dark' | 'light'
}

export default function HuddleMeter({ value, themeMode, ...props }: Props) {
    const [ref, setRef] = useState<HTMLElement | null>(null)
    const [steps, setSteps] = useState<boolean[]>([])

    useEffect(() => {
        const w = ref ? ref.offsetWidth : 0
        setSteps(Array.from({ length: Math.floor(w / 10) }))
    }, [ref])

    const darkMode = themeMode === 'dark'
    const background = darkMode ? 'neutral70' : 'neutral40'

    return (
        <Box
            {...props}
            ref={setRef}
            width="fill"
            display="flex"
            justifyContent="between"
        >
            {steps.map((_, i) => {
                const bg = i / steps.length < value ? 'primary' : background
                return (
                    <Box
                        key={i}
                        borderRadius="borderRadius4"
                        width="size6"
                        height="size16"
                        background={bg}
                    />
                )
            })}
        </Box>
    )
}
