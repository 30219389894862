import {
    DailyCall,
    DailyEventObject,
    DailyEventObjectCameraError,
    DailyEventObjectFatalError,
    DailyEventObjectNetworkConnectionEvent,
    DailyEventObjectNetworkQualityEvent,
    DailyEventObjectParticipant,
    DailyEventObjectTrack,
    DailyInputSettings,
    DailyParticipant,
    DailyParticipantUpdateOptions,
    DailyReceiveSettingsUpdates,
    DailyTrackSubscriptionState,
} from '@daily-co/daily-js'
import * as Sentry from '@sentry/react'
import { EventEmitter } from 'eventemitter3'
import { IReactionDisposer, reaction } from 'mobx'

import {
    event,
    isSafari,
    ListenerRegistrationFn,
    LogManager,
    waitForMilliseconds,
} from '@teamflow/lib'
import rootStore, { Participant, VideoProcessorMode } from '@teamflow/store'
import * as t from '@teamflow/types'
import { AVProvider } from '@teamflow/types'

import { IAudioVideoService } from './IAudioVideoService'
import { WebRTCInstrumentation } from './WebRTCInstrumentation'
import { TrackingConstants } from './const'
import { ParticipantTrackResetter } from './daily/ParticipantTrackResetter'
import { getJoinOptions } from './daily/getJoinOptions'
import { getNetworkQuality } from './daily/getNetworkQuality'
import {
    getPeerConnections,
    getSendersByInboundKeys,
} from './daily/getPeerConnections'
import { getUpdatedInputDevices } from './daily/getUpdatedInputDevices'
import initializeCall from './daily/initializeCall'
import { parseErrorMessage } from './daily/parseErrorMessage'
import getFeatures, { defaultFeatures } from './helpers/getFeatures'
import { getParticipantSubscriptions } from './helpers/getParticipantSubscriptions'
import { getScreenShareStreams } from './helpers/getScreenShareStreams'
import { getVideoParameters } from './helpers/getVideoParameters'
import { startTimerForJoiningAVTakingTooLong } from './helpers/joiningAVTakingTooLong'
import { metrics } from './metrics'
import { ParticipantTrackSubscriptions, TrackSubscriptionState } from './types'

declare global {
    // beta features provided by daily on the window, used for creating multiple screen share streams
    // they said they will update their types but for now we need this
    interface Window {
        betaStartCustomTrack: (config: {
            track: MediaStreamTrack
            trackName: string
        }) => Promise<void>
        betaStopCustomTrack: (config: { mediaTag: string }) => Promise<void>
    }
}

const WAIT_FOR_LOCAL_PARTICIPANT_DEVICE_TIMEOUT = 10_000
const SCREENSHARE_VIDEO_CUSTOM_TRACK_PREFIX = 'screen-share-video-'
const SCREENSHARE_AUDIO_CUSTOM_TRACK_PREFIX = 'screen-share-audio-'

enum InternalEvents {
    localParticipantDeviceUpdated = 'local-participant-device-updated',
}

interface LocalParticipantDeviceUpdate {
    audioIsOn: boolean
    videoIsOn: boolean
}

type DailyTrackKind = keyof DailyParticipant['tracks']

function toDailyTrackSubscriptionState(
    state: TrackSubscriptionState
): DailyTrackSubscriptionState {
    switch (state) {
        case TrackSubscriptionState.SUBSCRIBED:
            return true
        case TrackSubscriptionState.UNSUBSCRIBED:
            return false
        case TrackSubscriptionState.STAGED:
            return 'staged'
    }
}

const logger = LogManager.createLogger('DailyService', {
    critical: true,
    call: true,
})

export class DailyService implements IAudioVideoService {
    // For tests only
    public static readonly logger = logger

    public readonly provider: t.AVProvider = t.AVProvider.Daily

    /** The ID of the current call, which maybe incorrect when the shard-ID is changed but we didn't leave yet */
    private currentCallId: string | null = null

    /**
     * Promise to await before leaving/joining shards. This is to ensure operations are in order join/leave/join/..
     */
    private switchLock?: Promise<void>

    private call?: DailyCall
    private checkErrorTimeout = 0
    private features: t.AVFeatures = defaultFeatures

    private events = new EventEmitter()
    private joinStartTime = 0
    private instrumentation: WebRTCInstrumentation | null = null
    private participantTrackResetter: ParticipantTrackResetter

    private previousCallQualityThreshold:
        | DailyEventObjectNetworkQualityEvent['threshold']
        | null = null

    private reactionDisposers: IReactionDisposer[] = []

    constructor(
        private featureFlagService: t.IFeatureFlagService,
        public analytics: t.IAnalytics,
        private platformInfo: t.IPlatformInfo
    ) {
        rootStore.audioVideo.setAVAdapter({
            supportedFeatures: {
                'video-processor.background-blur': !isSafari(),
                'video-processor.background-image': false, // TODO
            },
            join: this.join.bind(this),
            leave: this.leave.bind(this),
            updateSubscriptions: this.updateSubscriptions.bind(this),
            setMicDevice: this.setMicDevice.bind(this),
            setCameraDevice: this.setCameraDevice.bind(this),
            setMicEnabled: this.setMicEnabled.bind(this),
            setCameraEnabled: this.setCameraEnabled.bind(this),
            setParticipantVideoSimulcastLayer:
                this.setParticipantVideoSimulcastLayer.bind(this),
            setParticipantScreenShareSimulcastLayer:
                this.setParticipantScreenShareSimulcastLayer.bind(this),
            getScreenShareStreams: this.getScreenShareStreams.bind(this),
            startScreenShare: this.startScreenShare.bind(this),
            stopScreenShare: this.stopScreenShare.bind(this),
            getNetworkQuality: this.getNetworkQuality.bind(this),
            checkDevicePermissions: this.checkDevicePermissions.bind(this),
            getDebugData: this.getDebugData.bind(this),
            getBandwidthUsage: this.getBandwidthUsage.bind(this),
            getPeerConnections: this.getPeerConnections.bind(this),
            processVideo: this.processVideo.bind(this),
            requestUpdatedInputState: this.requestUpdatedInputState.bind(this),
        })

        this.handleMeetingState = this.handleMeetingState.bind(this)
        this.handleParticipantsState = this.handleParticipantsState.bind(this)

        this.registerHydrateReaction()

        this.reactionDisposers.push(
            reaction(
                () => rootStore.audioVideo.reduceUploadBandwidth,
                () => this.updateUploadBandwidth()
            )
        )

        this.participantTrackResetter = new ParticipantTrackResetter(
            featureFlagService
        )
    }

    joinCorrectShard(): void {
        const { callId } = rootStore.audioVideo
        if (this.currentCallId === callId) return

        const runJumpShardSequence = () =>
            (async () => {
                if (this.currentCallId === callId) return
                if (callId) {
                    // Cleanly unsubscribe from participants in this call immediately.
                    this.removeParticipants()

                    logger.debug(
                        'DailyService.joinCorrectShard calling leave() (branch A)'
                    )
                    await this.leave()

                    // Don't join call if no shard assigned!
                    logger.info(`DailyService: Join call: ${callId}`)

                    const clearJoiningAVTimeout =
                        startTimerForJoiningAVTakingTooLong(t.AVProvider.Daily)

                    try {
                        await rootStore.audioVideo.join()
                    } finally {
                        clearJoiningAVTimeout()
                    }

                    logger.info(
                        `DailyService: Call joined succesfully: ${callId}`
                    )
                } else {
                    logger.debug(
                        'DailyService.joinCorrectShard calling leave() (branch B)'
                    )
                    await this.leave()
                }
            })().finally(() => {
                // Free lock once sequence ends even in case of error
                this.switchLock = undefined
            })

        if (this.switchLock) {
            void this.switchLock.then(() => {
                // joinCorrectShard may have been called multiple times when the same lock was active. This means
                // we must not overwrite the switch-lock in case another invocation has claimed it after the last
                // lock ended.
                if (!this.switchLock) this.switchLock = runJumpShardSequence()
            })
        } else {
            this.switchLock = runJumpShardSequence()
        }
    }

    private async init() {
        this.features = await getFeatures(this.featureFlagService)

        this.call = initializeCall(this.features, this.featureFlagService)
        this.updateUploadBandwidth()

        for (const state of Object.values(t.MeetingState)) {
            this.call.on(state, this.handleMeetingState)
        }

        for (const event of Object.values(t.NetworkEvent)) {
            this.call.on(event, this.handleNetworkEvent)
        }

        for (const state of Object.values(t.ParticipantState)) {
            this.call.on(state, this.handleParticipantsState)
        }
    }

    private async leave() {
        if (
            !this.call ||
            this.call.meetingState() === 'new' ||
            this.call.meetingState() === t.MeetingState.JoiningMeeting ||
            this.call.meetingState() === t.MeetingState.LeftMeeting
        ) {
            logger.debug('DailyService.leave: abort')
            return
        }

        try {
            await this.stopScreenShare()
            await this.call.leave()
        } catch (e) {
            logger.error(
                `DailyService.leave: ${
                    (e as Error)?.message ?? 'Error leaving call'
                }`
            )
        }

        // TODO: Remove this workaround when Daily fixes it. Still a problem in 0.14.0.
        //
        // This avoids the following error when re-joining a call after
        // having opened a screenshare. Even after closing the screenshare.
        //
        // `Failed to execute 'postMessage' on 'Window': MediaStream object could not be cloned.`
        delete (this.call as any)._preloadCache.screenMediaStream

        logger.debug('DailyService.leave: call left')
    }

    async getCallSessionId(): Promise<string | undefined> {
        if (this.call?.meetingState() !== t.MeetingState.JoinedMeeting) {
            logger.warn(
                `DailyService: getMeetingSession() is only allowed while in a meeting`
            )
            return
        }
        const result = await Promise.race([
            this.call?.getMeetingSession(),
            waitForMilliseconds(500),
        ])
        return result?.meetingSession.id
    }

    private getLocalParticipantSessionId(): string | undefined {
        return this.getLocalParticipant()?.session_id
    }

    private getLocalParticipant(): DailyParticipant | undefined {
        return this.call?.participants().local
    }

    private getParticipants(): DailyParticipant[] {
        if (!this.call) return []
        return Object.values(this.call.participants())
    }

    private updateSubscriptions(participants: Participant[]) {
        if (!this.call) return

        const participantUpdates: {
            [sessionId: string]: DailyParticipantUpdateOptions
        } = {}

        const dailyParticipants = this.call.participants()

        participants.forEach((participant) => {
            if (participant.id === rootStore.users.localUserId) return

            const subs = getParticipantSubscriptions(
                participant,
                this.featureFlagService
            )

            const unsubscribeSubs: ParticipantTrackSubscriptions = {
                audio: TrackSubscriptionState.UNSUBSCRIBED,
                video: TrackSubscriptionState.UNSUBSCRIBED,
                screen: TrackSubscriptionState.UNSUBSCRIBED,
            }

            participant.allAvIds.forEach((avId) => {
                const dailyParticipant = dailyParticipants[avId]
                if (!dailyParticipant) return

                const isCurrent = avId === participant.currentAvId

                const update = this.getTrackSubscriptionUpdate(
                    dailyParticipant,
                    // only media for currentAvId is displayed, so unsubscribe otherwise
                    isCurrent ? subs : unsubscribeSubs
                )
                if (update) {
                    participantUpdates[avId] = update
                }
            })
        })

        if (Object.keys(participantUpdates).length) {
            this.call?.updateParticipants(participantUpdates)
        }
    }

    /**
     * Resolves when a `Events.localParticipantDeviceUpdated` event is emitted
     * and `predicate` returns true. Rejects after a timeout.
     */
    private async waitForLocalParticipantDeviceUpdated(
        predicate: (deviceState: LocalParticipantDeviceUpdate) => boolean
    ) {
        await new Promise<void>((resolve, reject) => {
            const handler = (deviceState: LocalParticipantDeviceUpdate) => {
                if (predicate(deviceState)) {
                    this.events.off(
                        InternalEvents.localParticipantDeviceUpdated,
                        handler
                    )
                    resolve()
                }
            }

            this.events.on(
                InternalEvents.localParticipantDeviceUpdated,
                handler
            )

            setTimeout(() => {
                this.events.off(
                    InternalEvents.localParticipantDeviceUpdated,
                    handler
                )
                reject('waitForLocalParticipantDeviceUpdated timeout')
            }, WAIT_FOR_LOCAL_PARTICIPANT_DEVICE_TIMEOUT)
        })
    }

    private async setMicEnabled(enable: boolean) {
        // NOTE: If meeting state is not joined-meeting, the joined-meeting handler will setLocalAudio
        // to the correct value at that point.
        if (
            !this.call ||
            this.call.meetingState() !== t.MeetingState.JoinedMeeting
        ) {
            throw new Error(
                'DailyService: setMicEnabled called before call joined'
            )
        }

        if (enable === this.call.localAudio()) {
            logger.warn(
                `DailyService: Turning microphone to the same state ('${
                    enable ? 'on' : 'off'
                }')`
            )
            return
        }

        logger.debug(
            `DailyService: Turning microphone ${enable ? 'on' : 'off'}`
        )

        this.call.setLocalAudio(enable)

        await this.waitForLocalParticipantDeviceUpdated(
            ({ audioIsOn }) => audioIsOn === enable
        )
    }

    private async setCameraEnabled(enable: boolean) {
        // NOTE: If meeting state is not joined-meeting, the joined-meeting handler will setLocalAudio
        // to the correct value at that point.
        if (
            !this.call ||
            this.call.meetingState() !== t.MeetingState.JoinedMeeting
        ) {
            throw new Error(
                'DailyService: setCameraEnabled called before call joined'
            )
        }

        if (enable === this.call.localVideo()) {
            logger.warn(
                `DailyService: Turning camera to the same state ('${
                    enable ? 'on' : 'off'
                }')`
            )
            return
        }

        logger.debug(`DailyService: Turning camera ${enable ? 'on' : 'off'}`)

        this.call.setLocalVideo(enable)

        await this.waitForLocalParticipantDeviceUpdated(
            ({ videoIsOn }) => videoIsOn === enable
        )
    }

    private async setMicDevice(deviceId: string) {
        if (!this.call) return

        logger.debug(
            `DailyService.setMicDevice: Setting audioDeviceId=${deviceId}`
        )

        const deviceInfo = await this.call.setInputDevicesAsync({
            audioDeviceId: deviceId,
            videoDeviceId: null,
        })

        if (
            'deviceId' in deviceInfo.mic &&
            deviceInfo.mic.deviceId !== deviceId
        ) {
            throw new Error(
                `mic deviceId not updated to expected value. Expected = ${deviceId}; Actual = ${deviceInfo.mic.deviceId}`
            )
        }

        logger.debug(
            `DailyService.setMicDevice: Mic successfully set to ${deviceId}`
        )
    }

    private async setCameraDevice(deviceId: string) {
        if (!this.call) return

        logger.debug(
            `DailyService.setCameraDevice: Setting videoDeviceId=${deviceId}`
        )
        const deviceInfo = await this.call.setInputDevicesAsync({
            videoDeviceId: deviceId,
            audioDeviceId: null,
        })

        if (
            'deviceId' in deviceInfo.camera &&
            deviceInfo.camera.deviceId !== deviceId
        ) {
            throw new Error(
                `camera deviceId not updated to expected value. Expected = ${deviceId}; Actual = ${deviceInfo.camera.deviceId}`
            )
        }

        logger.debug(
            `DailyService.setCameraDevice: Camera successfully set to ${deviceId}`
        )
    }

    private async getScreenShareStreams(
        preset: t.ScreenSharePreset,
        audioOnlyCapture?: boolean
    ) {
        return getScreenShareStreams(
            this.platformInfo,
            this.features,
            this.featureFlagService,
            preset,
            audioOnlyCapture
        )
    }

    private async startScreenShare(
        screenStreams: MediaStream[],
        preset: t.ScreenSharePreset
    ) {
        await Promise.all(
            // Daily doesn't allow us to configure screen share bitrate and fps
            // according to the preset. So, those configurations are set when
            // first creating the Daily call object (see initializeCall.ts), and
            // we ask the user to reload after changing them.
            screenStreams.map(async (stream, i) => {
                const audioTrack = stream.getAudioTracks()[0]
                if (audioTrack) {
                    await window.betaStartCustomTrack({
                        track: audioTrack,
                        trackName: SCREENSHARE_AUDIO_CUSTOM_TRACK_PREFIX + i,
                    })
                }

                const videoTrack = stream.getVideoTracks()[0]
                if (videoTrack) {
                    await window.betaStartCustomTrack({
                        track: videoTrack,
                        trackName: SCREENSHARE_VIDEO_CUSTOM_TRACK_PREFIX + i,
                    })
                }
            })
        )

        logger.debug({
            action: 'Call@StartScreenShare',
            message: `DailyService: Started screenshares with stream ids: ${screenStreams.map(
                (s) => s.id
            )} and preset: ${preset}`,
        })

        this.analytics.track(TrackingConstants.ScreenShareStarted, {
            screenCount: screenStreams.length,
            preset,
        })
    }

    private async stopScreenShare() {
        logger.debug(`DailyService: Screenshare has stopped`)

        await Promise.all(
            rootStore.audioVideo.screenStreams.map(async (stream, i) => {
                await window.betaStopCustomTrack({
                    mediaTag: SCREENSHARE_VIDEO_CUSTOM_TRACK_PREFIX + i,
                })
                await window.betaStopCustomTrack({
                    mediaTag: SCREENSHARE_AUDIO_CUSTOM_TRACK_PREFIX + i,
                })
                // Clear cached screen share streams
                stream.getTracks().forEach((track) => track.stop())
            })
        )
    }

    async destroy(): Promise<void> {
        for (const state of Object.values(t.MeetingState)) {
            this.call?.off(state, this.handleMeetingState)
        }

        for (const event of Object.values(t.NetworkEvent)) {
            this.call?.off(event, this.handleNetworkEvent)
        }

        for (const state of Object.values(t.ParticipantState)) {
            this.call?.off(state, this.handleParticipantsState)
        }

        this.events.removeAllListeners()

        logger.debug('DailyService.onDestroy calling leave()')
        await this.leave()
        await this.call?.destroy()

        rootStore.audioVideo.updateState(t.AVState.None)

        while (this.reactionDisposers.length) this.reactionDisposers.pop()?.()
    }

    async getNetworkQuality(): Promise<t.INetworkQuality | undefined> {
        const stats = await this.call?.getNetworkStats()
        if (!stats) return

        return getNetworkQuality(stats)
    }

    async getBandwidthUsage() {
        const stats = await this.call?.getNetworkStats()
        const receiveSettings = (await this.call?.getReceiveSettings()) ?? {}
        const participants = this.getParticipants()

        const sources = ['audio', 'video', 'screenVideo'] as const

        const keyMap = {
            audio: 'microphone',
            video: 'camera',
            screenVideo: 'screen',
        } as const

        const bitrateEst = {
            audio: 50,
            video: 200,
            screenVideo: 2000,
        } as const

        const initItem = {
            microphone: 0,
            camera: 0,
            screen: 0,
            total: 0,
        }

        const uploadKbps = Math.round(
            (stats?.stats.latest.sendBitsPerSecond ?? 0) / 1000
        )
        const downloadKbps = Math.round(
            (stats?.stats.latest.recvBitsPerSecond ?? 0) / 1000
        )

        const init: t.AVBandwidthUsage = {
            upload: { ...initItem, total: uploadKbps },
            download: { ...initItem, total: downloadKbps },
            subscriptions: { ...initItem },
            staged: { ...initItem },
            tracks: { ...initItem },
            highResSubscriptions: { ...initItem },
        }

        return participants.reduce((info, p) => {
            for (const source of sources) {
                const pub = p.tracks[source]
                const key = keyMap[source]
                if (p.local) {
                    const bitrate = pub?.off
                        ? 0
                        : Math.min(uploadKbps, bitrateEst[source])
                    info.upload[key] += bitrate
                } else {
                    const hasTrack = !pub?.off && !!pub?.track

                    if (pub?.subscribed === 'staged') {
                        info.staged[key]++
                        info.staged.total++
                    } else if (pub?.subscribed) {
                        info.subscriptions[key]++
                        info.subscriptions.total++
                        if (
                            source === 'video' &&
                            receiveSettings[p.session_id]?.video?.layer ===
                                t.ParticipantSimulcastLayer.High
                        ) {
                            info.highResSubscriptions.camera++
                            info.highResSubscriptions.total++
                        }
                        if (
                            source === 'screenVideo' &&
                            receiveSettings[p.session_id]?.screenVideo
                                ?.layer === t.ParticipantSimulcastLayer.High
                        ) {
                            info.highResSubscriptions.screen++
                            info.highResSubscriptions.total++
                        }

                        if (hasTrack) {
                            info.download[key] += Math.min(
                                downloadKbps,
                                bitrateEst[source]
                            )
                        }
                    }

                    if (hasTrack) {
                        info.tracks[key]++
                        info.tracks.total++
                    }
                }
            }
            return info
        }, init)
    }

    async getDebugData(): Promise<t.AVDebugData> {
        const meetingId = (await this.getCallSessionId()) ?? ''
        const localParticipantAvId = this.getLocalParticipantSessionId() ?? ''

        const dashboardUrl =
            meetingId &&
            localParticipantAvId &&
            `https://dashboard.daily.co/sessions/${meetingId}/${localParticipantAvId}?domain=huddle`

        if (dashboardUrl) {
            logger.info('DailyService: Got dashboard url', dashboardUrl)
        }

        return {
            dashboardUrl,
            provider: this.provider,
            state: this.call?.meetingState() ?? '',
            meetingId,
            localParticipantAvId,
            constraints: getVideoParameters(this.featureFlagService),
            participants: this.getParticipants().map((p) => ({
                name: rootStore.users.getUserById(p.user_id)?.fullName ?? '',
                userId: p.user_id,
                avId: p.session_id,
                isLocalUser: p.user_id === rootStore.users.localUserId,
                isCameraEnabled: p.tracks?.video?.state === 'playable',
                isMicrophoneEnabled: p.tracks?.audio?.state === 'playable',
                isScreenShareEnabled:
                    p.tracks?.screenVideo?.state === 'playable',
                joinedAt: p.joined_at?.toISOString() ?? '',
                subscribed: this.getSubscriptionsDump(p.session_id),
            })),
        }
    }

    getPeerConnections() {
        const conn = getPeerConnections()
        if (conn.length !== 2) return // not subscribed to anyone

        return {
            sendConn: conn[0],
            recvConn: conn[1],
        }
    }

    private async join() {
        this.joinStartTime = Date.now()
        try {
            if (!this.call) {
                await this.init()
            }

            const { callId, shardId } = rootStore.audioVideo
            const { localUserId } = rootStore.users
            if (!localUserId || !callId || !shardId) {
                throw new Error('Cannot join call when callId not assigned')
            }
            if (!this.call) {
                throw new Error('Cannot join call when DailyCall not created')
            }

            const options = await getJoinOptions(callId, localUserId, shardId)
            logger.info('DailyService.joinCall joining call ' + callId)
            logger.debug('DailyService.joinCall calling leave()')
            await this.leave()
            await this.call.join(options)

            this.currentCallId = callId

            if (rootStore.audioVideo.screenStreams.length) {
                try {
                    await rootStore.audioVideo.startScreenShare()
                } catch (error) {
                    logger.error('DailyService.join startScreenShare', {
                        error,
                    })
                }
            }
        } catch (error) {
            logger.error('DailyService: joinCall error', { error })
            Sentry.captureException(error)

            // re-throw
            throw error
        }
    }

    private updateUploadBandwidth() {
        if (!this.call) return

        const { videoKbps } = getVideoParameters(this.featureFlagService)
        const { reduceUploadBandwidth } = rootStore.audioVideo

        const kbs = reduceUploadBandwidth
            ? // allow only the low simulcast layer through
              videoKbps.low
            : // NO_CAP tells Daily to revert any previously set bandwidth limit
              // https://docs.daily.co/reference/daily-js/instance-methods/set-bandwidth
              'NO_CAP'

        logger.info('DailyService.setBandwidth', { kbs })
        this.call.setBandwidth({ kbs })
    }

    private async joinedMeeting() {
        await rootStore.audioVideo.requestDevices()

        this.initPartcipants()

        const avId = this.getLocalParticipant()?.session_id
        this.events.emit(t.MeetingState.JoinedMeeting, avId)

        logger.info({
            action: 'Call@Join',
            rtcPeerId: avId,
        })

        this.analytics.track(TrackingConstants.JoinedCall, {
            avProvider: AVProvider.Daily,
            dailySession: (await this.getCallSessionId()) ?? '',
            dailyParticipant: avId,
            ms: Date.now() - this.joinStartTime,
        })

        // Set background settings from last session
        // only if it was enabled before
        if (
            rootStore.audioVideo.processor.video.supported.length > 0 &&
            rootStore.audioVideo.processor.video.mode.type !== 'none'
        ) {
            // "processVideo" may take a while, so we don't "await" it
            void this.onTrackStarted().then(async () => {
                if (rootStore.audioVideo.processor.video.mode.type === 'none') {
                    return
                }

                const videoMode = await this.processVideo(
                    rootStore.audioVideo.processor.video.mode
                )
                rootStore.audioVideo.processor.video.publish(videoMode)
            })
        }
    }

    private async handleCameraError(event?: DailyEventObjectCameraError) {
        if (!event) return

        const type = event.error?.type
        const msg = parseErrorMessage(event).toLocaleLowerCase()

        // @deprecated_metric
        logger.error(`DailyService: Camera Error: ${msg}`)

        let audioKind: string | null = null
        let videoKind: string | null = null

        // DO NOT RETURN
        switch (type) {
            case t.InUseError.CamInUse:
                rootStore.audioVideo.updateCameraError(t.AVDeviceError.InUse)
                videoKind = t.InUseError.CamInUse
                break
            case t.InUseError.MicInUse:
                audioKind = t.InUseError.MicInUse
                rootStore.audioVideo.updateMicError(t.AVDeviceError.InUse)
                break
            case t.InUseError.CamMicInUse:
                audioKind = t.InUseError.MicInUse
                videoKind = t.InUseError.CamInUse
                rootStore.audioVideo.updateMicError(t.AVDeviceError.InUse)
                // no offense but a spaghetti that we have dup code here lmao
                rootStore.audioVideo.updateCameraError(t.AVDeviceError.InUse)
                break
            default:
                switch (msg) {
                    case t.AccessError.DevicesError: {
                        if (!event.errorMsg?.audioOk) {
                            audioKind = t.AccessError.DevicesError
                            rootStore.audioVideo.updateMicError(
                                t.AVDeviceError.Other
                            )
                        }
                        // Quick story: The following negation was not present for several months!!!
                        if (!event.errorMsg?.videoOk) {
                            videoKind = t.AccessError.DevicesError
                            rootStore.audioVideo.updateCameraError(
                                t.AVDeviceError.Other
                            )
                        }
                        break
                    }
                    case t.AccessError.NotAllowed: {
                        audioKind = videoKind = t.AccessError.NotAllowed
                        await this.handlePermissionDeniedError()
                        break
                    }
                }
                break
        }

        if (audioKind !== null) {
            /* This is a cross-vendor metric!

                @danger
                @metric client-call_device-error
             */
            logger.error({
                action: 'Call@DeviceError',
                type: 'audio',
                kind: audioKind,
                error: msg,
            })
        }
        if (videoKind !== null) {
            /* This is a cross-vendor metric!

                @danger
                @metric client-call_device-error
             */
            logger.error({
                action: 'Call@DeviceError',
                type: 'video',
                kind: videoKind,
                error: msg,
            })
        }
    }

    private async handlePermissionDeniedError() {
        // ignore error if no camera found
        if (!navigator.mediaDevices) return
        const devices = await navigator.mediaDevices.enumerateDevices()
        const hasVideo = !!devices.find((d) => d.kind === 'videoinput')
        if (hasVideo) {
            // this might be because device is 'waking up' wait and check again
            window.clearTimeout(this.checkErrorTimeout)
            this.checkErrorTimeout = window.setTimeout(() => {
                void this.checkDevicePermissions()
            }, 2000)
        }
    }

    private async handleMeetingState(event: DailyEventObject) {
        const state = event.action
        switch (state) {
            case t.MeetingState.JoinedMeeting:
                await this.joinedMeeting()
                break
            case t.MeetingState.LeftMeeting:
                rootStore.audioVideo.updateState(t.AVState.Disconnected)
                this.removeParticipants()
                logger.warn('DailyService: Left meeting')
                break
            case t.MeetingState.CameraError:
                await this.handleCameraError(event)
                break
            case t.MeetingState.Error:
                logger.error(
                    `DailyService: Meeting Error: ${parseErrorMessage(event)}`
                )
                rootStore.audioVideo.updateState(t.AVState.Broken)
                break
            default:
                break
        }
    }

    private handleNetworkEvent = (
        event:
            | undefined
            | DailyEventObjectNetworkConnectionEvent
            | DailyEventObjectNetworkQualityEvent
    ) => {
        if (!event) return

        switch (event.action) {
            case t.NetworkEvent.NetworkConnection: {
                metrics.socketClosed.increment()
                logger.info('DailyService: Network connection changed', event)
                break
            }
            case t.NetworkEvent.NetworkQualityChange: {
                const isDifferentThanPreviousThreshold =
                    this.previousCallQualityThreshold !== event.threshold

                if (isDifferentThanPreviousThreshold) {
                    logger.info('DailyService: Network quality changed', event)
                }

                if (event.threshold === 'very-low') {
                    this.analytics.track(TrackingConstants.NetworkQualityLow)
                }

                if (
                    !this.previousCallQualityThreshold ||
                    isDifferentThanPreviousThreshold
                ) {
                    this.previousCallQualityThreshold = event.threshold
                }

                break
            }
            default:
                break
        }
    }

    private handleParticipantsState(
        event:
            | DailyEventObjectParticipant
            | DailyEventObjectTrack
            | DailyEventObjectFatalError
    ): void {
        switch (event.action) {
            case t.ParticipantState.ParticipantJoined:
                this.initParticipant(event.participant)
                break
            case t.ParticipantState.ParticipantLeft:
                this.removeParticipant(event.participant)
                break
            case t.ParticipantState.ParticipantUpdated:
            case t.ParticipantState.TrackStarted:
            case t.ParticipantState.TrackStopped:
                this.instrument() // no overhead 🤔. if instrumentation started, won't restart
                logger.info(
                    `DailyService: Updating participant ${event.participant?.user_id} / ${event.participant?.session_id}`
                )
                this.updateParticipant(event.participant)
                break
            case t.ParticipantState.Error:
                logger.error(
                    `DailyService: Participant Error: ${parseErrorMessage(
                        event
                    )}`
                )
                break
            default:
                break
        }
    }

    private getParticipantMedia(dailyParticipant: DailyParticipant): t.AVMedia {
        const { audio, video } = dailyParticipant.tracks

        const screenVideoStates: t.TrackState[] = []
        const screenVideoTracks: MediaStreamTrack[] = []
        let screenAudioTrack: MediaStreamTrack | undefined
        let screenAudioState: t.TrackState = 'off'

        for (const [key, value] of Object.entries(dailyParticipant.tracks)) {
            if (key.startsWith(SCREENSHARE_VIDEO_CUSTOM_TRACK_PREFIX)) {
                const track = value.persistentTrack || value.track
                if (track) {
                    screenVideoStates.push(value.state)
                    screenVideoTracks.push(track)
                }
            }

            if (key.startsWith(SCREENSHARE_AUDIO_CUSTOM_TRACK_PREFIX)) {
                // screenshare audio only really works for sharing tabs
                // and multi screen sharing only works for entire screens
                // so for now there should only ever be one screen audio track
                // we just gotta find it if it exists
                screenAudioTrack = value.persistentTrack || value.track
                screenAudioState = value.state
            }
        }

        const audioTrack = audio.persistentTrack || audio.track
        const videoTrack = video.persistentTrack || video.track

        const videoIsOn = !video.blocked && !video.off
        const audioIsOn = !audio.blocked && !audio.off

        return {
            videoTrack,
            audioTrack,
            screenVideoTracks,
            screenAudioTrack,
            videoIsOn,
            audioIsOn,
            videoState: video.state,
            audioState: audio.state,
            screenVideoStates,
            screenAudioState,
        }
    }

    private logTracksOffByBandwidth(dailyParticipant: DailyParticipant) {
        const sources = Object.keys(dailyParticipant.tracks) as DailyTrackKind[]

        sources.forEach((source) => {
            const track = dailyParticipant.tracks[source]
            if (track?.off?.byBandwidth) {
                logger.warn(
                    `DailyService: participant ${dailyParticipant.user_id} ${source} off by bandwidth ` +
                        `(avId: ${dailyParticipant.session_id})`,
                    {
                        action: 'Call@TrackOffByBandwidth',
                        peer: dailyParticipant.user_id,
                        source,
                    }
                )
            }
        })
    }

    private updateParticipant(
        dailyParticipant?: DailyParticipant | null
    ): void {
        if (!dailyParticipant) return

        const participant = rootStore.participants.findParticipantById(
            dailyParticipant.user_id
        )

        const media = this.getParticipantMedia(dailyParticipant)

        participant?.updateMedia({
            avId: dailyParticipant.session_id,
            local: dailyParticipant.local,
            media,
        })

        this.logTracksOffByBandwidth(dailyParticipant)

        if (dailyParticipant.local) {
            const { audioIsOn, videoIsOn } = media
            this.events.emit(InternalEvents.localParticipantDeviceUpdated, {
                audioIsOn,
                videoIsOn,
            })

            logger.debug(
                `DailyService: updateParticipant on local participant, requesting updated input device state`
            )
            void this.requestUpdatedInputState()
        }
    }

    private removeParticipant(dailyParticipant: DailyParticipant) {
        const participant = rootStore.participants.findParticipantById(
            dailyParticipant.user_id
        )

        participant?.resetMedia({
            avId: dailyParticipant.session_id,
        })

        if (dailyParticipant.local) {
            this.events.emit(InternalEvents.localParticipantDeviceUpdated, {
                audioIsOn: false,
                videoIsOn: false,
            })
        }
    }

    private removeParticipants() {
        const { participants } = rootStore.participants
        participants.forEach((participant) => {
            participant.resetAllMedia()
        })
    }

    private registerHydrateReaction() {
        this.reactionDisposers.push(
            reaction(
                () => rootStore.participants.participantsInSameAVConnectedSpace,
                (participants, prevParticipants) => {
                    const dailyParticipantsByUserId = new Map(
                        this.getParticipants().map((dailyParticipant) => [
                            dailyParticipant.user_id,
                            dailyParticipant,
                        ])
                    )

                    const prevIds = new Set(prevParticipants?.map((p) => p.id))
                    const newParticipants = participants.filter(
                        (p) => !prevIds.has(p.id)
                    )

                    newParticipants.forEach((p) =>
                        this.updateParticipant(
                            dailyParticipantsByUserId.get(p.id)
                        )
                    )
                },
                { fireImmediately: true }
            )
        )
    }

    private async checkDevicePermissions() {
        const participant = this.getLocalParticipant()
        if (!participant) return

        const { audio, video } = participant.tracks

        if (audio.blocked?.byPermissions) {
            logger.warn(
                'DailyService: audio.blocked?.byPermissions ' +
                    audio.blocked?.byPermissions
            )
            rootStore.audioVideo.updateMicError(
                t.AVDeviceError.PermissionDenied
            )
        }
        if (video.blocked?.byPermissions) {
            logger.warn(
                'DailyService: video.blocked?.byPermissions ' +
                    video.blocked?.byPermissions
            )
            rootStore.audioVideo.updateCameraError(
                t.AVDeviceError.PermissionDenied
            )
        }
    }

    private initParticipant(p: DailyParticipant) {
        logger.info(
            `DailyService: Initializing media for ${p.user_id} / ${p.session_id}`
        )
        this.updateParticipant(p)
    }

    private initPartcipants(): void {
        const participants = this.getParticipants()
        participants.forEach((p) => this.initParticipant(p))
    }

    public getSubscriptionsDump(avId: string) {
        const dailyParticipant = this.call?.participants()[avId]
        if (!dailyParticipant) return null

        return {
            video: !!dailyParticipant.tracks.video.subscribed,
            audio: !!dailyParticipant.tracks.audio.subscribed,
            screenVideo: !!dailyParticipant.tracks.screenVideo.subscribed,
        }
    }

    private getTrackSubscriptionUpdate(
        dailyParticipant: DailyParticipant,
        sub: ParticipantTrackSubscriptions
    ): DailyParticipantUpdateOptions | null {
        const newTracksSubbed: {
            audio: DailyTrackSubscriptionState
            video: DailyTrackSubscriptionState
        } & {
            [K in string]: DailyTrackSubscriptionState
        } = {
            audio: toDailyTrackSubscriptionState(sub.audio),
            video: toDailyTrackSubscriptionState(sub.video),
        }

        const newScreenSubbed = toDailyTrackSubscriptionState(sub.screen)

        Object.keys(dailyParticipant.tracks).forEach((trackKind) => {
            if (
                trackKind.startsWith(SCREENSHARE_AUDIO_CUSTOM_TRACK_PREFIX) ||
                trackKind.startsWith(SCREENSHARE_VIDEO_CUSTOM_TRACK_PREFIX)
            ) {
                newTracksSubbed[trackKind] = newScreenSubbed
            }
        })

        // Auto-heal ended tracks by setting subscribed=false until track is gone,
        // then letting Daily resubscribe to get new tracks.
        const tracksNeedingReset =
            this.participantTrackResetter.updateAndGetTracksNeedingReset(
                dailyParticipant
            )
        Object.keys(newTracksSubbed).forEach((trackKind) => {
            if (tracksNeedingReset.has(trackKind)) {
                newTracksSubbed[trackKind] = false
            }
        })

        const hasSubChange = Object.keys(newTracksSubbed).some(
            (trackKind) =>
                newTracksSubbed[trackKind] !==
                dailyParticipant.tracks[trackKind as DailyTrackKind]?.subscribed
        )

        if (!hasSubChange) return null

        logger.info(
            `DailyService: Updating participant subscriptions for ${dailyParticipant.user_id} (avId: ${dailyParticipant.session_id})`,
            {
                action: 'Call@UpdateParticipantSubscriptions',
                peer: dailyParticipant.user_id,
                ...Object.fromEntries(
                    Object.keys(newTracksSubbed).map((trackKind) => [
                        trackKind,
                        {
                            previousValue:
                                dailyParticipant.tracks[
                                    trackKind as DailyTrackKind
                                ]?.subscribed,
                            value: newTracksSubbed[trackKind],
                        },
                    ])
                ),
            }
        )

        const { audio, video, ...screenTracks } = newTracksSubbed

        return {
            setSubscribedTracks: {
                audio,
                video,
                // https://getteamflow.slack.com/archives/C01DR93U5U2/p1665599044344889?thread_ts=1659034967.362349&cid=C01DR93U5U2
                // @ts-expect-error Daily types need to be fixed. Figured out this api above
                custom: screenTracks,
            },
        }
    }

    private async requestUpdatedInputState() {
        const inputs = await getUpdatedInputDevices(this.call)
        logger.info(`DailyService: input devices`, inputs)
        return inputs
    }

    private async setParticipantSimulcastLayer(
        participantId: string,
        source: 'video' | 'screenVideo',
        layer: t.ParticipantSimulcastLayer
    ) {
        if (!this.call) return

        const participant =
            rootStore.participants.findParticipantById(participantId)
        if (!participant) return

        const dailyParticipants = this.call.participants()

        const updates: DailyReceiveSettingsUpdates = {}

        participant.allAvIds.forEach((avId) => {
            const dailyParticipant = dailyParticipants[avId]
            if (!dailyParticipant) return

            updates[avId] = { [source]: { layer } }
        })

        await this.call?.updateReceiveSettings(updates)
    }

    private async setParticipantVideoSimulcastLayer(
        participantId: string,
        layer: t.ParticipantSimulcastLayer
    ) {
        try {
            logger.debug(
                `DailyService.setParticipantVideoSimulcastLayer: ${participantId} layer=${layer}`
            )
            await this.setParticipantSimulcastLayer(
                participantId,
                'video',
                layer
            )
        } catch (error: unknown) {
            logger.error(`DailyService.setParticipantVideoSimulcastLayer`, {
                error,
            })
        }
    }

    private async setParticipantScreenShareSimulcastLayer(
        participantId: string,
        layer: t.ParticipantSimulcastLayer
    ) {
        try {
            logger.debug(
                `DailyService.setParticipantScreenShareSimulcastLayer: ${participantId} layer=${layer}`
            )
            await this.setParticipantSimulcastLayer(
                participantId,
                'screenVideo',
                layer
            )
        } catch (error: unknown) {
            logger.error(
                `DailyService.setParticipantScreenShareSimulcastLayer`,
                { error }
            )
        }
    }

    private async processVideo(
        mode: VideoProcessorMode
    ): Promise<VideoProcessorMode & { error?: boolean }> {
        if (
            rootStore.audioVideo.state !== t.AVState.Connected ||
            !this.call ||
            this.call.meetingState() !== 'joined-meeting'
        )
            return { type: 'none' }

        let inputSettings: DailyInputSettings

        try {
            if (mode && mode.type === 'background-blur') {
                inputSettings = await this.call.updateInputSettings({
                    video: {
                        processor: {
                            type: 'background-blur',
                            config: {
                                strength: mode.config.strength,
                            },
                        },
                    },
                })
            } else {
                inputSettings = await this.call.updateInputSettings({
                    video: {
                        processor: {
                            type: 'none',
                        },
                    },
                })
            }
        } catch (e) {
            logger.error('DailyService.processVideo error', e)
            return { type: 'none', error: true }
        }

        // Bug in Daily
        if (('inputSettings' in inputSettings) as any) {
            inputSettings = (inputSettings as any).inputSettings
        }

        const inputSettingsProcessor = inputSettings.video?.processor

        if (!inputSettingsProcessor || inputSettingsProcessor.type === 'none')
            return { type: 'none' }
        else if (inputSettingsProcessor.type === 'background-blur')
            return {
                type: 'background-blur',
                config: {
                    strength:
                        (inputSettingsProcessor.config as any)['strength'] ??
                        0.5,
                },
            }
        else {
            logger.error(
                'Unsupported input settings found ',
                inputSettingsProcessor
            )
            return { type: 'none' }
        }
    }

    private instrument = () => {
        if (!this.featureFlagService.isEnabledSync(t.Feature.InstrumentWebRTC))
            return
        if (!this.call || !this.currentCallId) {
            return logger.info(
                'Failed to start instrumentation due to missing call id'
            )
        }
        const currentCallId = this.currentCallId

        if (
            this.instrumentation &&
            this.instrumentation.id === this.currentCallId
        )
            return // it's ready already.

        // Gotta wait a sec cause Daily is a bit slow daily. (The peer connections aren't available immediately)
        void waitForMilliseconds(1000).then(() => {
            if (!this.call || this.currentCallId !== currentCallId) return
            if (
                this.instrumentation &&
                this.instrumentation.id === this.currentCallId
            )
                return // it's ready already. (have to check again b/c of time delay otherwise 🏎🏎🏎 occurs)

            const conn = getPeerConnections()

            if (conn.length === 2) {
                if (this.instrumentation) {
                    // kill the last one
                    this.instrumentation.stop()
                    this.instrumentation = null
                }
                this.instrumentation = WebRTCInstrumentation.from(
                    this.currentCallId,
                    conn[0],
                    conn[1],
                    getSendersByInboundKeys(this.call)
                )
                this.instrumentation.start()
            } // This is expected aCktUaLy :(. Cause Daily doesn't have peer connections active
            // if you haven't subscribed to anyone 👀. Check the log for WebRTCInstrumentation for success
            else
                logger.debug(
                    'Failed to start instrumentation due to peer connections mismatch',
                    {
                        connCount: conn.length,
                    }
                )
        })
    }

    private onTrackStarted() {
        return new Promise<void>((resolve) => {
            const handleTrackStarted = (e?: DailyEventObjectTrack) => {
                if (e?.participant?.local && e?.track.kind === 'video') {
                    this.call?.off('track-started', handleTrackStarted)
                    resolve()
                }
            }

            this.call?.on('track-started', handleTrackStarted)
        })
    }

    @event(t.MeetingState.JoinedMeeting)
    declare readonly onJoined: ListenerRegistrationFn<string>
}
