import { DailyNetworkStats } from '@daily-co/daily-js'

import { AV_MEDIUM_QUALITY_THRESHOLD } from '@teamflow/lib'
import * as t from '@teamflow/types'

export function getNetworkQuality(
    dailyStats: DailyNetworkStats
): t.INetworkQuality {
    const qualityThreshold = calculateQualityThreshold(dailyStats)
    const receivePacketLoss = Number(
        dailyStats.stats?.latest.totalRecvPacketLoss?.toFixed(2) ?? 0
    )
    const sendPacketLoss = Number(
        dailyStats.stats?.latest.totalSendPacketLoss?.toFixed(2) ?? 0
    )
    const packetLoss = receivePacketLoss > 0 || sendPacketLoss > 0
    return {
        quality: dailyStats.quality,
        qualityThreshold,
        packetLoss,
        receivePacketLoss,
        sendPacketLoss,
    }
}

function calculateQualityThreshold({
    quality,
    threshold,
}: DailyNetworkStats): t.QualityThreshold {
    // Daily's `quality` stat is meant to be a subjective value 0-100 shown in
    // the UI, so we rely on `threshold` instead to determine when to report
    // low quality.
    if (threshold === 'low' || threshold === 'very-low') {
        return t.QualityThreshold.LOW
    }
    if (quality <= AV_MEDIUM_QUALITY_THRESHOLD) {
        return t.QualityThreshold.MEDIUM
    }
    return t.QualityThreshold.HIGH
}
