import { autorun, reaction } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'

import { useFeatureFlag } from '@teamflow/bootstrap'
import { getGlobalVolumeMultiplier } from '@teamflow/client-call'
import rootStore from '@teamflow/store'
import { Feature } from '@teamflow/types'

import { AudioPlaybackManager } from '../helpers/AudioPlaybackManager'

interface Props {
    participantId: string
    audioTrack?: MediaStreamTrack
    /** Inject a function that reads MobX observables. This way, we prevent
     * re-renders if volume changes continuously (i.e., if volume is a function
     * of distance between participants).
     *
     * If not specified, defaults to full volume */
    getVolume?: () => number
    isScreen: boolean
    'data-testid'?: string
}

export const Audio = observer(
    ({
        participantId,
        audioTrack,
        getVolume,
        isScreen,
        'data-testid': dataTestId,
    }: Props) => {
        const [audioEl, setAudioEl] = useState<HTMLAudioElement | null>(null)

        const [manager, setManager] = useState<AudioPlaybackManager | null>(
            null
        )

        const { enabled: masterVolumeControl } = useFeatureFlag({
            flag: Feature.MasterVolumeControl,
        })

        useEffect(() => {
            if (!audioEl) {
                setManager(null)
                return
            }

            const newManager = new AudioPlaybackManager(
                audioEl,
                true,
                participantId,
                isScreen ? 'screen' : 'sfx'
            )
            setManager(newManager)

            return () => {
                newManager.destroy()
            }
        }, [audioEl, isScreen, participantId])

        useEffect(() => {
            if (manager) {
                const getMaskedVolume = () => {
                    const volume = getVolume?.() ?? 1
                    return volume * getGlobalVolumeMultiplier()
                }

                return reaction(
                    getMaskedVolume,
                    (volume) => {
                        manager.changeVolume(volume)
                    },
                    { fireImmediately: true }
                )
            }

            return undefined
        }, [getVolume, manager, masterVolumeControl])

        useEffect(() => {
            if (manager) {
                return autorun(() => {
                    const { speakerId } = rootStore.audioVideo
                    if (!speakerId) return

                    manager.setSinkId(speakerId)
                })
            }

            return undefined
        }, [manager])

        useEffect(() => {
            if (manager) {
                manager.changeTrack(audioTrack)
            }
        }, [audioTrack, manager])

        return <audio ref={setAudioEl} data-testid={dataTestId} />
    }
)
