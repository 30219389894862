import { DailyParticipant, DailyTrackState } from '@daily-co/daily-js'

import { LogManager } from '@teamflow/lib'
import { Feature, IFeatureFlagService } from '@teamflow/types'

type DailyTrackKind = keyof DailyParticipant['tracks']

type DailyParticipantTrackKindState = {
    state: DailyTrackState['state']
    start: number // state start time in ms
}

type DailyParticipantTrackStates = {
    [T in DailyTrackKind]?: DailyParticipantTrackKindState
}

const logger = LogManager.createLogger('ParticipantTrackResetter', {
    critical: true,
    call: true,
})

/**
 * Determines which Daily tracks are broken and need to be reset.
 *
 * A track is broken if its readyState is 'ended', which can happen due to a
 * Chromium bug where after a machine has been asleep for a while, other participants
 * tracks are in an ended state.
 *
 * A track is also broken if it has been stuck in the (Daily-specific) 'loading' state
 * for some time.
 */
export class ParticipantTrackResetter {
    /**
     * For each participant, tracks the time at which each track was first observed
     * to be in the loading state.
     */
    private prevStatesMap = new WeakMap<
        DailyParticipant,
        DailyParticipantTrackStates
    >()

    constructor(private featureFlagService: IFeatureFlagService) {}

    updateAndGetTracksNeedingReset(participant: DailyParticipant): Set<string> {
        const resetLoadingTracks = this.featureFlagService.isEnabledSync(
            Feature.DailyResetLoadingTracks
        )
        const trackLoadingTimeoutMs = this.featureFlagService.valueSync(
            Feature.DailyResetLoadingTracks
        )

        const prevStates = this.prevStatesMap.get(participant) ?? {}
        const updatedStates: DailyParticipantTrackStates = {}

        const tracksNeedingReset = new Set<string>()

        const now = Date.now()

        Object.entries(participant.tracks).forEach(
            ([key, { state, track, persistentTrack }]) => {
                const trackKind = key as DailyTrackKind

                const prevStateForTrack = prevStates[trackKind]
                let updatedStateForTrack: DailyParticipantTrackKindState

                if (state !== prevStateForTrack?.state) {
                    updatedStateForTrack = { state, start: now }
                    this.logTrackStateChanged(participant, trackKind, state)
                } else {
                    updatedStateForTrack = prevStateForTrack
                }

                updatedStates[trackKind] = updatedStateForTrack

                if ((persistentTrack ?? track)?.readyState === 'ended') {
                    tracksNeedingReset.add(trackKind)
                    this.logTrackEnded(participant, trackKind)
                } else if (
                    resetLoadingTracks &&
                    updatedStateForTrack.state === 'loading' &&
                    now - updatedStateForTrack.start > trackLoadingTimeoutMs
                ) {
                    tracksNeedingReset.add(trackKind)
                    this.logTrackStuckLoading(participant, trackKind)
                }
            }
        )

        this.prevStatesMap.set(participant, updatedStates)
        return tracksNeedingReset
    }

    private logTrackStateChanged(
        participant: DailyParticipant,
        trackKind: DailyTrackKind,
        state: DailyTrackState['state']
    ) {
        logger.debug(
            `Track ${trackKind} switched state to ${state} for participant ${participant.user_id} (avId: ${participant.session_id})`,
            {
                action: 'Call@TrackStateChanged',
                peer: participant.user_id,
                trackKind,
                state,
            }
        )
    }

    private logTrackEnded(
        participant: DailyParticipant,
        trackKind: DailyTrackKind
    ) {
        /* @metric client_call_audio_track-ended */
        logger.warn(
            `Detected track ${trackKind} in ended state for participant ${participant.user_id} (avId: ${participant.session_id})` +
                `, attempting to resubscribe...`,
            {
                action: 'Call@SelfHealing.TrackEnded',
                peer: participant.user_id,
                trackKind,
                message: `track ${trackKind} in ended for participant ${participant.user_id}`,
            }
        )
    }

    private logTrackStuckLoading(
        participant: DailyParticipant,
        trackKind: DailyTrackKind
    ) {
        logger.warn(
            `Detected track ${trackKind} stuck in loading state for participant ${participant.user_id} (avId: ${participant.session_id})` +
                `, attempting to resubscribe...`,
            {
                action: 'Call@SelfHealing.TrackStuckLoading',
                peer: participant.user_id,
                trackKind,
                message: `track ${trackKind} stuck in loading state for participant ${participant.user_id}`,
            }
        )
    }
}
