import assert from 'assert'

import EventEmitter from 'eventemitter3'

import { isSafari } from '@teamflow/lib'
import {
    IAudioService,
    Events,
    IAudioBufferSound,
    ISoundOptions,
} from '@teamflow/types'

import AudioBufferSound from './AudioBufferSound'
class WebAudioService implements IAudioService {
    private context?: AudioContext
    private audioBuffersByKey = new Map<string, AudioBuffer>()
    private events = new EventEmitter()

    get sharedContext() {
        assert(this.context)
        return this.context
    }

    get isSuspended() {
        return this.context?.state === 'suspended'
    }

    constructor() {
        // guard against window access on server
        if (typeof window === 'undefined') {
            return
        }

        const ctor = window.AudioContext || window.webkitAudioContext

        if (!ctor) return

        this.context = new ctor()
        this.resume = this.resume.bind(this)

        if (isSafari() && this.context.sampleRate !== 44100) {
            const buffer = this.context.createBuffer(
                1,
                1,
                this.context.sampleRate
            )
            const source = this.context.createBufferSource()
            source.buffer = buffer
            source.connect(this.context.destination)
            source.start(0)
            source.stop(0)
            source.disconnect()
            void this.context.close()
            this.context = new (window.AudioContext ||
                window.webkitAudioContext)()
        }

        if (this.isSuspended) {
            window.addEventListener('mousedown', this.resume, { once: true })
            window.addEventListener('touchstart', this.resume, { once: true })
        }
    }

    on(event: string | symbol, cb: (...args: any[]) => void, context?: any) {
        this.events.on(event, cb, context)
    }

    once(event: string | symbol, cb: (...args: any[]) => void, context?: any) {
        this.events.once(event, cb, context)
    }

    off(event: string | symbol, cb: (...args: any[]) => void, context?: any) {
        this.events.off(event, cb, context)
    }

    createSound(
        key: string,
        path: string,
        options: ISoundOptions = {}
    ): IAudioBufferSound {
        return new AudioBufferSound(key, path, this, options)
    }

    async load(key: string, url: string): Promise<AudioBuffer> {
        if (this.audioBuffersByKey.has(key)) {
            return this.audioBuffersByKey.get(key) as AudioBuffer
        }

        const request = new XMLHttpRequest()
        request.open('GET', url, true)
        request.responseType = 'arraybuffer'

        const buffer = await new Promise<AudioBuffer>((resolve) => {
            // Decode asynchronously
            request.onload = () => {
                void this.context?.decodeAudioData(
                    request.response,
                    (buffer) => {
                        resolve(buffer)
                    }
                )
            }

            request.send()
        })

        this.audioBuffersByKey.set(key, buffer)

        return buffer
    }

    private async resume() {
        window.removeEventListener('mousedown', this.resume)
        window.removeEventListener('touchstart', this.resume)

        await this.context?.resume()
        this.events.emit(Events.ContextResumed)
    }
}

declare global {
    interface Window {
        webkitAudioContext: AudioContext
    }
}

const sharedInstance = new WebAudioService()

export default sharedInstance

export { sharedInstance }
