import { DailyCallOptions } from '@daily-co/daily-js'

import { featureFlags } from '@teamflow/bootstrap'
import { api } from '@teamflow/client-api'
import { Feature } from '@teamflow/types'

import { logger } from '../logging'

type JoinOptions = DailyCallOptions & { userName: string }

export async function getJoinOptions(
    callId: string,
    userId: string,
    shardId?: string
): Promise<JoinOptions> {
    const dailyStagingEnabled = await featureFlags.isEnabled(
        Feature.DailyStaging
    )
    const baseURL = dailyStagingEnabled
        ? process.env.NEXT_PUBLIC_DAILY_BASE_URL_STAGING
        : process.env.NEXT_PUBLIC_DAILY_BASE_URL
    // the token gives the user `owner` permissions
    // which allows them to eject their own duplicates
    const { data, error } = await api.userOrg.getDailyMeetingToken({
        audioOff: true,
        videoOff: true,
        shardId,
    })
    if (!data?.token) {
        /* @metric client-call_meeting-token_errors */
        logger.error({
            action: '@getJoinOptions',
            message: 'Failed to fetch token - ',
            error: error?.message,
        })
    }
    return {
        url: `${baseURL}${callId}`,
        userName: userId,
        token: data?.token,
    }
}
