/**
 * Provides a brief description of an error that can occur when initiating screen capture.
 *
 * The full list of exceptions can be found at
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#return_value}.
 *
 * @param error
 * @param defaultErrorMessage
 */
export const getUserFacingScreenCaptureErrorMessage = (
    error: any,
    defaultErrorMessage: string
): string => {
    if (error instanceof DOMException) {
        switch (error.name) {
            case 'AbortError':
                return 'Hardware error'
            case 'NotAllowedError':
                return 'Permission denied'
            case 'NotFoundError':
                return 'Cannot find any screen'
            case 'NotReadableError':
                return 'Cannot access screen'
            case 'OverconstraintedError':
                return 'Cannot find screen'
            case 'SecurityError':
                return 'Screen capture is disabled by security policy'
            case 'TypeError':
                return 'Internal failure'
        }
    }

    return error?.message ?? defaultErrorMessage
}
