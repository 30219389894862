import Daily, { DailyCall } from '@daily-co/daily-js'

import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import * as t from '@teamflow/types'

import { getScreenShareParameters } from '../helpers/getScreenShareParameters'
import { getVideoParameters } from '../helpers/getVideoParameters'

export default function initializeCall(
    features: t.AVFeatures,
    sharedFlags: t.IFeatureFlagService
): DailyCall {
    const {
        videoKbps,
        videoFps,
        videoResolution,
        videoLowScaleDown,
        videoDefaultLayer,
        screenDefaultLayer,
    } = getVideoParameters(sharedFlags)
    const { micId, cameraId } = rootStore.audioVideo

    // Daily only supports setting screen share options when creating call object,
    // so we will prompt the user to reload after changing `screenSharePreset`
    const { screenSharePreset } = rootStore.settings
    rootStore.audioVideo.setScreenShareStuckAtPreset(screenSharePreset)

    const screenShareParams = getScreenShareParameters(
        sharedFlags,
        screenSharePreset
    )

    LogManager.global.debug(
        `DailyService: createCallObject with micId ${micId} cameraId ${cameraId}`,
        { critical: true, call: true }
    )

    rootStore.audioVideo.updateDefaultLayers(
        videoDefaultLayer,
        screenDefaultLayer
    )

    const screenLowLayer = {
        maxBitrate: screenShareParams.kbps.low * 1000,
        scaleResolutionDownBy: screenShareParams.lowScaleResolutionDownBy,
        maxFramerate: screenShareParams.fps.low,
    }

    const screenHighLayer = {
        maxBitrate: screenShareParams.kbps.high * 1000,
        scaleResolutionDownBy: 1,
        maxFramerate: screenShareParams.fps.high,
    }

    const call = Daily.createCallObject({
        subscribeToTracksAutomatically: false,
        dailyConfig: {
            noAutoDefaultDeviceChange: true,
            experimentalChromeVideoMuteLightOff: true,
            // https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpEncodingParameters
            camSimulcastEncodings: [
                {
                    maxBitrate: videoKbps.low * 1000,
                    scaleResolutionDownBy: videoLowScaleDown,
                    maxFramerate: videoFps.low,
                },
                {
                    maxBitrate: videoKbps.high * 1000,
                    scaleResolutionDownBy: 1,
                    maxFramerate: videoFps.high,
                },
            ],
            screenSimulcastEncodings: features.screenShareSimulcast
                ? features.screenShareSecondLayer
                    ? [screenLowLayer, screenHighLayer]
                    : [screenHighLayer]
                : undefined,
            userMediaAudioConstraints: {
                autoGainControl: true,
                echoCancellation: true,
                noiseSuppression: true,
            },
            userMediaVideoConstraints: {
                frameRate: videoFps.high,
                ...videoResolution,
            },
        },
        receiveSettings: {
            base: {
                video: { layer: videoDefaultLayer },
                screenVideo: { layer: screenDefaultLayer },
            },
        },
        videoSource: cameraId ?? undefined,
        audioSource: micId ?? undefined,
    })

    return call
}
