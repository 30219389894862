import { Platform } from '@teamflow/bootstrap'
import {
    LogManager,
    result,
    SCREENSHARE_APP_NATIVE_RES_BUGFIX_SIZE,
} from '@teamflow/lib'
import {
    AVFeatures,
    IFeatureFlagService,
    IPlatformInfo,
    ScreenSharePreset,
} from '@teamflow/types'

import { getScreenShareParameters } from './getScreenShareParameters'
import { getUserFacingScreenCaptureErrorMessage } from './getUserFacingScreenCaptureErrorMessage'

const logger = LogManager.createLogger('getScreenShareStream', {
    call: true,
    critical: true,
})

export async function getScreenShareStreams(
    platformInfo: IPlatformInfo,
    features: AVFeatures,
    sharedFlags: IFeatureFlagService,
    preset: ScreenSharePreset,
    audioOnlyCapture?: boolean
): Promise<MediaStream[]> {
    if (!navigator.mediaDevices) {
        throw new Error('Cannot access device')
    }

    const { screenShareAudio, screenShareSimulcast } = features
    const { height, fps } = getScreenShareParameters(sharedFlags, preset)

    function getVideoConstraints(): MediaTrackConstraints | undefined {
        const common = audioOnlyCapture
            ? {
                  displaySurface: 'browser',
              }
            : {}

        const maxResolutionParams = {
            // Chromium has a bug where it downscales the screen share stream when it's on
            // a different monitor. These constraints are a workaround that avoid that and
            // ensure we get the full screen resolution.
            // https://bugs.chromium.org/p/chromium/issues/detail?id=1056311#c19
            width: SCREENSHARE_APP_NATIVE_RES_BUGFIX_SIZE,
            height: SCREENSHARE_APP_NATIVE_RES_BUGFIX_SIZE,
        }

        if (!screenShareSimulcast) {
            // if simulcast is off, we don't constrain fps, but we still
            // need to constrain width/height to get around the Chromium bug
            if (Platform.isChrome())
                return { ...common, ...maxResolutionParams }
            return { ...common } as MediaTrackConstraints
        }

        const resolutionParams = height
            ? // `height` being defined means we're dealing with a motion preset
              { height }
            : // otherwise, we have the text preset and want the full screen resolution
              maxResolutionParams

        return {
            ...common,
            frameRate: fps.high,
            ...resolutionParams,
        }
    }

    const isElectron = platformInfo.electronService.available

    const promise: Promise<MediaStream[] | MediaStream | null> = isElectron
        ? platformInfo.electronService.requestScreenShareStream({
              maxFrameRate: fps.high,
              maxHeight: height,
          })
        : navigator.mediaDevices.getDisplayMedia({
              audio: screenShareAudio,
              video: getVideoConstraints(),
          })

    const [error, resolved] = await result(promise)
    const streams = Array.isArray(resolved) ? resolved : [resolved]

    if (error) {
        throw new Error(
            getUserFacingScreenCaptureErrorMessage(error, 'Unknown error')
        )
    }

    const finalStreams: MediaStream[] = []

    for (let stream of streams) {
        if (!stream) {
            // No stream and no error means user cancelled
            continue
        }

        const audioTracks = stream.getAudioTracks()
        const videoTracks = stream.getVideoTracks()

        if (audioTracks.length === 0 && videoTracks.length === 0) {
            logger.error({
                action: 'Call@GetScreenShareStreams',
                message: 'Screenshare did not contain any tracks',
            })
            continue
        }

        if (audioOnlyCapture && videoTracks.length > 0) {
            stream = new MediaStream(audioTracks)
            videoTracks.forEach((track) => {
                track.stop()
            })
        } else {
            // Optimizes encoder for type of content
            // 'text' prioritizes detail at expense of frame rate
            // 'motion' prioritizes frame rate at expense of detail
            // https://www.w3.org/TR/mst-content-hint/#video-content-hints
            const contentHint =
                preset === ScreenSharePreset.TEXT ? 'text' : 'motion'

            videoTracks.forEach((track) => {
                track.contentHint = contentHint
            })
        }

        finalStreams.push(stream)
    }

    return finalStreams
}
