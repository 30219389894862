import { debounce, DebouncedFunc } from 'lodash'

import { Logger, LogManager, RateLimit } from '@teamflow/lib'
import rootStore from '@teamflow/store'

import { getTrackAsPlainObj } from './getTrackAsPlainObj'

/**
 * Time to wait before retrying if we cannot play because `mediaEl` isn't ready.
 */
const PLAY_DELAY = 1000

/**
 * Manages updating the media element's source and ensuring that it is always
 * in the playing state. There are a few cases where play() can fail. It can be
 * interrupted by a later play() call, by the media element being re-parented,
 * by the source being changed, or by the browser blocking autoplay. This class
 * prevents simultaneous play() calls and takes care of retrying play() when it
 * fails. This class can be extended handle other async operations like
 * setSinkId() using the same one-at-a-time mechanism
 * (see `busy` and `flushPending`).
 */
export class MediaPlaybackManager<
    T extends HTMLMediaElement = HTMLMediaElement
> {
    protected readonly logger: Logger

    /**
     * Set to true when the manager is destroyed. Should skip any async work
     * when true.
     */
    protected canceled = false

    protected readonly disposers: (() => void)[] = []

    /**
     * Only one async operation on `mediaEl` should be happening at a time.
     * This will be true if an async operation is currently in progress.
     *
     * This, in conjunction with flushPending(), allows child classes to
     * implement additional async operations on `mediaEl`:
     *
     * Check `busy` in the async method (set a "pending" flag to true if `busy`
     * is true), call flushPending() after the async operation finishes, and
     * override flushPending() to call the new async operation if needed, and
     * super.flushPending() otherwise.
     */
    protected busy = false

    /**
     * If true, call play() after the current async operation finishes.
     */
    private pendingPlay = false

    /**
     * Guarantees that play() will be called after `PLAY_DELAY`. If there is
     * already a pending play() call, the delay will be reset.
     */
    private readonly debouncedPlay: DebouncedFunc<() => void>

    constructor(
        protected readonly mediaEl: T,
        private readonly autoplay: boolean,
        private readonly playLimiter: RateLimit,
        logMetadata: {
            action?: string
            peer?: string
            type?: string
        }
    ) {
        this.logger = LogManager.createLogger('MediaPlaybackManager', {
            critical: true,
            call: true,
            track: {
                enumerable: true,
                get: () => getTrackAsPlainObj(this.mediaEl),
            },
            ...logMetadata,
        })

        this.debouncedPlay = debounce(this.play.bind(this), PLAY_DELAY, {
            leading: false,
            trailing: true,
        })
        this.disposers.push(() => {
            this.debouncedPlay.cancel()
        })

        this.setupMediaEl()

        if (this.autoplay) {
            this.setupAutoplayListeners()
        }
    }

    /**
     * Any setup code for `mediaEl` that needs to run before `setupReactions`
     * (which calls play()).
     */
    protected setupMediaEl() {
        this.mediaEl.srcObject = null
    }

    private setupAutoplayListeners() {
        this.handlePause = this.handlePause.bind(this)
        this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
        this.play = this.play.bind(this)

        this.mediaEl.addEventListener('pause', this.handlePause)
        this.mediaEl.addEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        )

        this.disposers.push(() => {
            this.mediaEl.removeEventListener('pause', this.handlePause)
            this.mediaEl.removeEventListener(
                'visibilitychange',
                this.handleVisibilityChange
            )
        })

        // play if did not autoplay
        window.addEventListener('mousedown', this.play)
        window.addEventListener('touchstart', this.play)

        this.disposers.push(() => {
            window.removeEventListener('mousedown', this.play)
            window.removeEventListener('touchstart', this.play)
        })
    }

    changeTrack(trackOrSrc: MediaStreamTrack | string | undefined) {
        if (trackOrSrc) {
            this.logger.debug('Attaching media track')

            if (trackOrSrc instanceof MediaStreamTrack) {
                this.mediaEl.srcObject = new MediaStream([trackOrSrc])
            } else {
                this.mediaEl.src = trackOrSrc
            }

            if (this.autoplay) {
                void this.play()
            }
        } else {
            this.logger.debug('Detaching media track')

            this.mediaEl.srcObject = null
        }
    }

    /**
     * Called when current async operation finishes
     * (i.e., when `busy` becomes false).
     */
    protected flushPending() {
        if (this.pendingPlay) {
            this.pendingPlay = false
            void this.play()
        }
    }

    play() {
        this.debouncedPlay.cancel()

        if (this.busy) {
            this.pendingPlay = true
            return
        }

        // using .then to avoid making this function async
        // (to abstract away the race condition handling)
        void this.attemptPlay().then(() => {
            if (this.canceled) return
            this.flushPending()
        })
    }

    /**
     * This function will resolve even if an error occurs.
     */
    private async attemptPlay() {
        const hasSrc = !!this.mediaEl.srcObject || !!this.mediaEl.src

        if (!hasSrc || !this.mediaEl.paused) {
            return
        }

        if (this.mediaEl.readyState < this.mediaEl.HAVE_CURRENT_DATA) {
            this.logger.warn({
                message: 'Media not ready - retrying after a delay',
                readyState: this.mediaEl.readyState,
            })
            this.debouncedPlay()
            return
        }

        this.logger.debug('Playing media')

        this.busy = true
        await this.playLimiter.waitForToken()

        try {
            await this.mediaEl.play()

            this.logger.debug('Played media')
        } catch (error: any) {
            if (this.canceled) return

            if (this.isAutoplayError(error)) {
                this.logger.error({
                    message:
                        'Error while playing media - browser blocked autoplay',
                    error: error?.message,
                })

                this.handleAutoplayError()
            } else {
                /* @metric client-call_playback_errors */
                this.logger.warn({
                    message: 'Error while playing media, retrying now',
                    error: error?.message,
                })

                // will result in play() being called again in flushPending()
                this.pendingPlay = true
            }
        }

        this.busy = false
    }

    private isAutoplayError(error: any) {
        return error instanceof DOMException && error.name === 'NotAllowedError'
    }

    private handleAutoplayError() {
        rootStore.audioVideo.addProblem({
            message: 'Click to enable audio',
            link: 'interaction_required',
            throttleMs: 5000,
        })
    }

    /**
     * Only called when autoplay is true.
     */
    private handlePause() {
        this.logger.warn('Media element was paused, retrying now.')
        void this.play()
    }

    /**
     * Only called when autoplay is true.
     */
    private handleVisibilityChange() {
        // Resume playback when re-focusing TF. Safari can sometimes pause
        // streams when you play AV in a different app.
        if (document.visibilityState === 'visible') {
            void this.play()
        }
    }

    destroy() {
        this.canceled = true
        this.disposers.forEach((d) => d())

        this.logger.debug('Detaching media track on cleanup')

        this.mediaEl.srcObject = null
    }
}
