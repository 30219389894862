import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, Center, Spinner, Stack } from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import { AVDeviceError } from '@teamflow/types'

import { HELP_AV_COMMON_PROBLEMS_URL } from '../../../constants'
import { VISIT_AV_HELP_PAGE, track } from '../../../helpers/analytics'

import { BrowserType } from '../helpers/getBrowserName'

import { Steps } from './Steps'

const translationPage = 'common'
const tKeys = allTKeys.common.avPermissions

interface Props {
    browser: BrowserType
    permissionsPending: boolean
    micErrorCode?: AVDeviceError
    camErrorCode?: AVDeviceError
    onDismiss: () => void
}

export const Content = memo(
    ({
        browser,
        permissionsPending,
        micErrorCode,
        camErrorCode,
        onDismiss,
    }: Props) => {
        const { t } = useTranslation(translationPage)

        const anyBlocked = micErrorCode || camErrorCode
        const showReload = anyBlocked && browser === 'safari'

        const handleHelp = useCallback(() => {
            track(VISIT_AV_HELP_PAGE)
            window.open(HELP_AV_COMMON_PROBLEMS_URL, '_blank')
        }, [])

        const handleReload = useCallback(() => {
            window.location.reload()
        }, [])

        if (permissionsPending || micErrorCode || camErrorCode) {
            return (
                <Stack space="space16">
                    <Box marginX="space24">
                        <Steps
                            browser={browser}
                            permissionsPending={permissionsPending}
                            micErrorCode={micErrorCode}
                            camErrorCode={camErrorCode}
                        />
                    </Box>
                    <Box
                        marginBottom="space16"
                        marginRight="space16"
                        display="flex"
                        alignItems="center"
                        justifyContent="between"
                    >
                        <Button size="lg" onClick={handleHelp} kind="link">
                            {t(tKeys.help)}
                        </Button>
                        {showReload && (
                            <Button
                                size="lg"
                                kind="text"
                                onClick={handleReload}
                            >
                                {t(tKeys.reload)}
                            </Button>
                        )}
                        <Button size="lg" kind="text" onClick={onDismiss}>
                            {t(
                                camErrorCode && !micErrorCode
                                    ? tKeys.continueWithMicrophone
                                    : tKeys.dismiss
                            )}
                        </Button>
                    </Box>
                </Stack>
            )
        }

        return (
            <Center>
                <Spinner size="xl" />
            </Center>
        )
    }
)
