import { useCallback, useState } from 'react'

import {
    CHANGE_AUDIO_SOURCE,
    CHANGE_OUTPUT_SOURCE,
    CHANGE_VIDEO_SOURCE,
    LogManager,
} from '@teamflow/lib'
import rootStore from '@teamflow/store'
import { IAnalytics } from '@teamflow/types'

const logger = LogManager.createLogger('UI@useAudioVideoInputs', {
    critical: true,
    call: true,
})

export function useAudioVideoInputs({ track }: IAnalytics) {
    const [loadingMic, setLoadingMic] = useState(false)
    const [loadingSpeaker, setLoadingSpeaker] = useState(false)
    const [loadingCamera, setLoadingCamera] = useState(false)

    const changeMic = useCallback(
        async (deviceId: string, trackSystemDevice: boolean) => {
            logger.info({
                action: 'Call@setMic',
                deviceId,
                trackSystemDevice,
            })
            setLoadingMic(true)
            rootStore.audioVideo.setTrackSystemMic(trackSystemDevice)
            await rootStore.audioVideo.setMic(deviceId)
            track(CHANGE_AUDIO_SOURCE)
            setLoadingMic(false)
        },
        [track]
    )

    const changeSpeaker = useCallback(
        async (deviceId: string, trackSystemDevice: boolean) => {
            logger.info({
                action: 'Call@setSpeaker',
                deviceId,
                trackSystemDevice,
            })
            setLoadingSpeaker(true)
            rootStore.audioVideo.setTrackSystemSpeaker(trackSystemDevice)
            await rootStore.audioVideo.setSpeaker(deviceId)
            track(CHANGE_OUTPUT_SOURCE)
            setLoadingSpeaker(false)
        },
        [track]
    )

    const changeCamera = useCallback(
        async (deviceId: string, trackSystemDevice: boolean) => {
            logger.info({
                action: 'Call@setCamera',
                deviceId,
                trackSystemDevice,
            })
            setLoadingCamera(true)
            rootStore.audioVideo.setTrackSystemCamera(trackSystemDevice)
            await rootStore.audioVideo.setCamera(deviceId)
            track(CHANGE_VIDEO_SOURCE)
            setLoadingCamera(false)
        },
        [track]
    )

    return {
        changeMic,
        changeSpeaker,
        changeCamera,
        loadingMic,
        loadingSpeaker,
        loadingCamera,
    }
}
