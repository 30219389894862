import {
    AVScreenConstraints,
    IFeatureFlagService,
    RemoteJsonTypes,
    ScreenSharePreset,
} from '@teamflow/types'

export const TEXT_FPS = 5

export const TEXT_LOW_FPS = 1
export const TEXT_LOW_SCALE_RESOLUTION_DOWN_BY = 1

export const MOTION_LOW_FPS = 15
export const MOTION_LOW_SCALE_RESOLUTION_DOWN_BY = 2

function computeConstraints(options: {
    mode: 'text' | 'motion'
    height?: number
    fps: number
    kbps: number
}): AVScreenConstraints {
    // low fps shouldn't exceed options.fps
    const lowFps = Math.min(
        options.mode === 'text' ? TEXT_LOW_FPS : MOTION_LOW_FPS,
        options.fps
    )

    const lowScaleResolutionDownBy =
        options.mode === 'text'
            ? TEXT_LOW_SCALE_RESOLUTION_DOWN_BY
            : MOTION_LOW_SCALE_RESOLUTION_DOWN_BY

    const resolutionRatio = 1 / lowScaleResolutionDownBy ** 2
    const fpsRatio = lowFps / options.fps
    const lowKbps = Math.floor(options.kbps * resolutionRatio * fpsRatio)

    return {
        height: options.height,
        lowScaleResolutionDownBy,
        fps: {
            high: options.fps,
            low: lowFps,
        },
        kbps: {
            high: options.kbps,
            low: lowKbps,
        },
    }
}

export const getScreenShareConstraintsForPreset = (
    remoteKbps: RemoteJsonTypes['json-screen-share-bitrates-kbps'],
    preset: ScreenSharePreset
): AVScreenConstraints => {
    switch (preset) {
        case ScreenSharePreset.MOTION_H480_15FPS:
            return computeConstraints({
                mode: 'motion',
                height: 480,
                fps: 15,
                kbps: remoteKbps.MOTION_H480_15FPS,
            })
        case ScreenSharePreset.MOTION_H720_15FPS:
            return computeConstraints({
                mode: 'motion',
                height: 720,
                fps: 15,
                kbps: remoteKbps.MOTION_H720_15FPS,
            })
        case ScreenSharePreset.MOTION_H720_30FPS:
            return computeConstraints({
                mode: 'motion',
                height: 720,
                fps: 30,
                kbps: remoteKbps.MOTION_H720_30FPS,
            })
        case ScreenSharePreset.MOTION_H1080_30FPS:
            return computeConstraints({
                mode: 'motion',
                height: 1080,
                fps: 30,
                kbps: remoteKbps.MOTION_H1080_30FPS,
            })
        case ScreenSharePreset.MOTION_H1080_60FPS:
            return computeConstraints({
                mode: 'motion',
                height: 1080,
                fps: 60,
                kbps: remoteKbps.MOTION_H1080_60FPS,
            })
        // default: just in case we remove a preset in the future but the user's
        // setting is still set to it
        case ScreenSharePreset.TEXT:
        default:
            return computeConstraints({
                mode: 'text',
                fps: TEXT_FPS,
                kbps: remoteKbps.TEXT,
            })
    }
}

export const getScreenShareParameters = (
    sharedFlags: IFeatureFlagService,
    preset: ScreenSharePreset
): AVScreenConstraints => {
    const remoteKbps = sharedFlags.remoteJsonSync(
        'json-screen-share-bitrates-kbps'
    )
    return getScreenShareConstraintsForPreset(remoteKbps, preset)
}
