import { Cross2Icon } from '@radix-ui/react-icons'
import { observer } from 'mobx-react'
import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AVSettingsInterstitialProps } from '@teamflow/bootstrap'
import { Box, Button, Checkbox, Stack } from '@teamflow/design'
import { LogManager } from '@teamflow/lib'
import rootStore from '@teamflow/store'
import allTKeys from '@teamflow/translations'
import { JoinAccess } from '@teamflow/types'
import {
    track,
    Flow,
    Signup,
    eventWithFlow,
} from '@teamflow/web/src/helpers/analytics'

import HuddleHeader from '../../common/HuddleHeader'

import DeniedScreen from '../denied'

import { AVDeviceSelection } from './components/AVDeviceSelection'
import { AVSetupHelp } from './components/AVSetupHelp'
import { AVSetupPermissions } from './components/AVSetupPermissions'
import { AVSetupPreview } from './components/AVSetupPreview'
import { AVSetupRequestModal } from './components/AVSetupRequestModal'
import { ClickToActivateModal } from './components/ClickToActivateModal'
import { UserDetails } from './components/UserDetails'
import { useAudioMeter } from './hooks/useAudioMeter'
import { useUserMedia, UserMediaErrorReason } from './hooks/useUserMedia'

const translationPage = 'signup'
const tKeys = allTKeys.signup

const responsiveWrapperStyles = {
    display: 'flex',
    flexDirection: {
        mobile: 'column',
        desktop: 'row',
    },
    alignItems: {
        mobile: 'center',
        desktop: 'start',
    },
    justifyContent: {
        mobile: 'start',
        desktop: 'center',
    },
    paddingTop: {
        mobile: 'none',
        desktop: 'space64',
    },
    paddingBottom: {
        mobile: 'space32',
        desktop: 'space64',
    },
    paddingX: {
        mobile: 'space20',
        tablet: 'space32',
        desktop: 'space64',
    },
} as const

const logger = LogManager.createLogger('UI@AVSettingsInterstitial', {
    critical: true,
})

export default observer(function AVSettingsInterstitial({
    orgName,
    inviteCode,
    invitation,
    orgSlug = '',
    roomSlug,
    onSubmit,
    signup,
    referrer,
    checkDomain,
    flowType,
    source = Flow.None,
    embedded,
}: AVSettingsInterstitialProps) {
    const { t } = useTranslation(translationPage)

    const isMobileWidth = rootStore.layout.windowInnerWidth < 1024

    const { cameras } = rootStore.audioVideo
    const { profilePictureUrl } = rootStore.users.localUser || {}

    const [alwaysDisplaySetting, setAlwaysDisplaySetting] = useState(
        rootStore.settings.alwaysDisplayAvSetup
    )
    const [access, setAccess] = useState<{ denied: boolean; message: string }>({
        denied: false,
        message: '',
    })
    const [deviceAccess, setDeviceAccess] = useState<
        { audio: boolean; video: boolean } | undefined
    >()
    const userMedia = useUserMedia(deviceAccess)
    const audioMeter = useAudioMeter(userMedia.audioStream)
    const isMediaPending = userMedia.pending
    const isMediaDenied =
        userMedia.error?.message === UserMediaErrorReason.Denied
    const hasCamera = isMediaPending ? true : cameras.length > 0

    const handleAlwaysShowScreenChange = useCallback((checked: boolean) => {
        setAlwaysDisplaySetting(!checked)
    }, [])

    const handleSubmit = useCallback(
        (access?: JoinAccess, email?: string) => {
            rootStore.settings.update({
                alwaysDisplayAvSetup: alwaysDisplaySetting,
            })
            onSubmit?.(access, email)
        },
        [alwaysDisplaySetting, onSubmit]
    )

    const handleDismiss = useCallback(() => {
        rootStore.audioVideo.setIsSetup(true)
    }, [])

    useEffect(() => {
        if (!rootStore.settings.rememberAVState) {
            // The mic and cam should be enabled by default when you are shown the setup screen. This
            // may not be the case without doing so here b/c "rememberAVState" could be false, causing
            // the mic & cam state to set to false as that's the default value.
            logger.info({
                action: 'Call@enableMicAndCamOnAVSetup',
            })
            void rootStore.audioVideo.toggleMicEnabled(true)
            void rootStore.audioVideo.toggleCameraEnabled(true)
        }
    }, [])

    useEffect(() => {
        if (!access.denied) {
            track(eventWithFlow(Signup.AVScreenShown, source))
        }
    }, [access.denied, source])

    if (access.denied) {
        return <DeniedScreen error={access.message} />
    }

    return (
        <Box
            data-testid="signup-create-account"
            overflowY={embedded ? 'auto' : undefined}
        >
            {!embedded && <HuddleHeader showLogin />}
            {embedded && (
                <Box position="absolute" top="space8" right="space8">
                    <Button
                        leadingIcon={<Cross2Icon width={24} height={24} />}
                        kind="text"
                        level="neutral"
                        onClick={handleDismiss}
                    />
                </Box>
            )}
            <Box {...responsiveWrapperStyles}>
                <AVSetupPreview
                    stream={isMediaDenied ? undefined : userMedia.videoStream}
                    pending={isMediaPending}
                    audioMeter={audioMeter}
                    profilePictureUrl={profilePictureUrl}
                    hasCamera={hasCamera}
                />
                <Box width="size32" height="size32" flexShrink={0} />
                <Stack space="space16">
                    <UserDetails
                        checkDomain={checkDomain}
                        signup={signup}
                        orgName={orgName}
                        inviteCode={inviteCode}
                        invitation={invitation}
                        orgSlug={orgSlug}
                        roomSlug={roomSlug}
                        onSubmit={handleSubmit}
                        referrer={referrer}
                        setAccess={setAccess}
                        flowType={flowType}
                        source={source}
                        isLoading={isMediaPending}
                    >
                        <AVDeviceSelection
                            audioMeter={audioMeter}
                            isMobileWidth={isMobileWidth}
                            isMediaPending={isMediaPending}
                            isMediaDenied={isMediaDenied}
                            hasCamera={hasCamera}
                        />
                    </UserDetails>
                    {signup !== 'guest' && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap="space8"
                            width="fill"
                        >
                            <Checkbox
                                label={t(tKeys.avSetup.dontShowThisAgain)}
                                checked={!alwaysDisplaySetting}
                                onChange={handleAlwaysShowScreenChange}
                                data-testid="av-setup.dont-show-avsetup-again-checkbox"
                            />
                        </Box>
                    )}
                    <AVSetupHelp source={source} />
                </Stack>
            </Box>
            {isMediaDenied && (
                <AVSetupPermissions setDeviceAccess={setDeviceAccess} />
            )}
            {isMediaPending && !isMediaDenied && <AVSetupRequestModal />}
            {!isMediaPending && !isMediaDenied && (
                <ClickToActivateModal embedded={!!embedded} />
            )}
        </Box>
    )
})
