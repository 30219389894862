export function isSpecialEffectsCamera(cam?: MediaDeviceInfo) {
    if (!cam) {
        return false
    }

    if (!('label' in cam)) {
        return false
    }

    const label = cam.label.toLocaleLowerCase()

    return (
        label.startsWith('mmhmm ') ||
        label.startsWith('snap ') ||
        label.startsWith('manycam ') ||
        label.includes('cam link')
    )
}
