import * as Sentry from '@sentry/react'
import { EventEmitter } from 'eventemitter3'
import {
    ConnectionQuality,
    ConnectionState,
    LocalParticipant,
    LocalTrackPublication,
    MediaDeviceFailure,
    Participant as LiveKitParticipant,
    RemoteParticipant,
    RemoteTrack,
    RemoteTrackPublication,
    Room as LiveKitRoom,
    Room,
    RoomEvent,
    Track,
    TrackPublication,
    VideoEncoding,
    VideoPreset,
    VideoQuality,
} from 'livekit-client'
import { IReactionDisposer, reaction } from 'mobx'

import { event, ListenerRegistrationFn, LogManager } from '@teamflow/lib'
import rootStore, { Participant, VideoProcessorMode } from '@teamflow/store'
import * as t from '@teamflow/types'
import { AVProvider } from '@teamflow/types'

import { IAudioVideoService } from './IAudioVideoService'
import { TrackingConstants } from './const'
import getFeatures, { defaultFeatures } from './helpers/getFeatures'
import { getParticipantSubscriptions } from './helpers/getParticipantSubscriptions'
import { getScreenShareParameters } from './helpers/getScreenShareParameters'
import { getScreenShareStreams } from './helpers/getScreenShareStreams'
import { getVideoParameters } from './helpers/getVideoParameters'
import { startTimerForJoiningAVTakingTooLong } from './helpers/joiningAVTakingTooLong'
import { getConnectOptions } from './livekit/getConnectOptions'
import { getNetworkQuality } from './livekit/getNetworkQuality'
import { getRoomOptions } from './livekit/getRoomOptions'
import { getUpdatedInputDevices } from './livekit/getUpdatedInputDevices'
import { getVideoOptions } from './livekit/getVideoOptions'
import { ParticipantTrackSubscriptions, TrackSubscriptionState } from './types'

const logger = LogManager.createLogger('LiveKitService', {
    critical: true,
    call: true,
})

function toLiveKitVideoQuality(
    layer: t.ParticipantSimulcastLayer
): VideoQuality {
    switch (layer) {
        case t.ParticipantSimulcastLayer.Low:
            return VideoQuality.LOW
        case t.ParticipantSimulcastLayer.High:
            return VideoQuality.HIGH
    }
}

function updatePubSubscription(
    pub: RemoteTrackPublication,
    state: TrackSubscriptionState,
    trackCb?: () => void
) {
    const shouldSubscribe = state !== TrackSubscriptionState.UNSUBSCRIBED
    const shouldEnable = state === TrackSubscriptionState.SUBSCRIBED

    let changed = false
    if (pub.isSubscribed !== shouldSubscribe) {
        pub.setSubscribed(shouldSubscribe)
        changed = true
    }
    if (shouldSubscribe && pub.isEnabled !== shouldEnable) {
        pub.setEnabled(shouldEnable)
        changed = true
    }

    if (changed) trackCb?.()
}

interface ReceiveQualities {
    video?: VideoQuality
    screen?: VideoQuality
}

export class LiveKitService implements IAudioVideoService {
    public readonly provider: t.AVProvider = t.AVProvider.LiveKit

    /** The ID of the current call, which maybe incorrect when the shard-ID is changed but we didn't leave yet */
    private currentCallId: string | null = null

    /**
     * Promise to await before leaving/joining shards. This is to ensure operations are in order join/leave/join/..
     */
    private switchLock?: Promise<void>

    private room?: LiveKitRoom
    private checkErrorTimeout = 0
    private features: t.AVFeatures = defaultFeatures

    private events = new EventEmitter()
    private joinStartTime = 0

    /** Participant id (not avId) -> receive qualities. */
    private participantReceiveQualities = new Map<string, ReceiveQualities>()

    private reactionDisposers: IReactionDisposer[] = []

    constructor(
        private featureFlagService: t.IFeatureFlagService,
        public analytics: t.IAnalytics,
        private platformInfo: t.IPlatformInfo
    ) {
        rootStore.audioVideo.setAVAdapter({
            // one day but not today
            supportedFeatures: {
                'video-processor.background-blur': false,
                'video-processor.background-image': false, // TODO
            },
            join: this.join.bind(this),
            leave: this.leave.bind(this),
            updateSubscriptions: this.updateSubscriptions.bind(this),
            setMicDevice: this.setMicDevice.bind(this),
            setCameraDevice: this.setCameraDevice.bind(this),
            setMicEnabled: this.setMicEnabled.bind(this),
            setCameraEnabled: this.setCameraEnabled.bind(this),
            setParticipantVideoSimulcastLayer:
                this.setParticipantVideoSimulcastLayer.bind(this),
            setParticipantScreenShareSimulcastLayer:
                this.setParticipantScreenShareSimulcastLayer.bind(this),
            getScreenShareStreams: this.getScreenShareStreams.bind(this),
            startScreenShare: this.startScreenShare.bind(this),
            stopScreenShare: this.stopScreenShare.bind(this),
            getNetworkQuality: this.getNetworkQuality.bind(this),
            checkDevicePermissions: this.checkDevicePermissions.bind(this),
            getDebugData: this.getDebugData.bind(this),
            getBandwidthUsage: this.getBandwidthUsage.bind(this),
            getPeerConnections: this.getPeerConnections.bind(this),
            processVideo: (mode: VideoProcessorMode) => {
                logger.error('Tried to change video processor to ', mode)
                return Promise.resolve({ type: 'none' })
            },
            requestUpdatedInputState: this.requestUpdatedInputState.bind(this),
        })

        this.registerHydrateReaction()

        this.reactionDisposers.push(
            reaction(
                () => rootStore.audioVideo.reduceUploadBandwidth,
                () => this.updateUploadQuality()
            )
        )
    }

    /** Switch calls when this participant's shard mutates. */
    joinCorrectShard(): void {
        const { callId } = rootStore.audioVideo
        if (this.currentCallId === callId) return

        const runJumpShardSequence = () =>
            (async () => {
                if (this.currentCallId === callId) return
                if (callId) {
                    // Cleanly unsubscribe from participants in this call immediately.
                    this.removeParticipants()

                    logger.debug(
                        'LiveKitService.joinCorrectShard calling leave() (branch A)'
                    )
                    await this.leave()

                    // Don't join call if no shard assigned!
                    logger.info(`LiveKitService: Join call: ${callId}`)

                    const clearJoiningAVTimeout =
                        startTimerForJoiningAVTakingTooLong(
                            t.AVProvider.LiveKit
                        )

                    try {
                        await rootStore.audioVideo.join()
                    } finally {
                        clearJoiningAVTimeout()
                    }

                    logger.info(
                        `LiveKitService: Call joined succesfully: ${callId}`
                    )
                } else {
                    logger.debug(
                        'LiveKitService.joinCorrectShard calling leave() (branch B)'
                    )
                    await this.leave()
                }
            })().finally(() => {
                // Free lock once sequence ends even in case of error
                this.switchLock = undefined
            })

        if (this.switchLock) {
            void this.switchLock.then(() => {
                // joinCorrectShard may have been called multiple times when the same lock was active. This means
                // we must not overwrite the switch-lock in case another invocation has claimed it after the last
                // lock ended.
                if (!this.switchLock) this.switchLock = runJumpShardSequence()
            })
        } else {
            this.switchLock = runJumpShardSequence()
        }
    }

    private async init() {
        this.features = await getFeatures(this.featureFlagService)
    }

    private async leave() {
        if (!this.room) {
            return
        }

        this.room.removeAllListeners()

        if (this.room.state === ConnectionState.Disconnected) {
            return
        }

        try {
            await this.room.disconnect()
        } catch (error) {
            logger.error(`LiveKitService: Error leaving call`, { error })
        }

        rootStore.audioVideo.updateState(t.AVState.Disconnected)
        this.removeParticipants()

        logger.debug('LiveKitService: Call left')
    }

    async getCallSessionId(): Promise<string | undefined> {
        return this.room?.sid
    }

    private getLocalParticipantSessionId(): string | undefined {
        return this.room?.localParticipant.sid
    }

    private getParticipants(): LiveKitParticipant[] {
        if (!this.room) return []
        const participants: LiveKitParticipant[] = Array.from(
            this.room.participants.values()
        )
        participants.push(this.room.localParticipant)
        return participants
    }

    private updateSubscription(
        lkParticipant: RemoteParticipant,
        sub: ParticipantTrackSubscriptions
    ) {
        const audioPub = lkParticipant.getTrack(Track.Source.Microphone)
        const videoPub = lkParticipant.getTrack(Track.Source.Camera)
        const screenPub = lkParticipant.getTrack(Track.Source.ScreenShare)
        const screenAudioPub = lkParticipant.getTrack(
            Track.Source.ScreenShareAudio
        )

        if (audioPub) {
            updatePubSubscription(audioPub, sub.audio, () => {
                logger.info(
                    `LiveKitService: set subscription audio=${sub.audio} for ${lkParticipant.identity} (avId: ${lkParticipant.sid})`
                )
            })
        }

        if (videoPub) {
            updatePubSubscription(videoPub, sub.video, () => {
                logger.info(
                    `LiveKitService: set subscription video=${sub.video} for ${lkParticipant.identity} (avId: ${lkParticipant.sid})`
                )
            })
        }

        if (screenPub) {
            updatePubSubscription(screenPub, sub.screen, () => {
                logger.info(
                    `LiveKitService: set subscription screen=${sub.screen} for ${lkParticipant.identity} (avId: ${lkParticipant.sid})`
                )
            })
        }

        if (screenAudioPub) {
            updatePubSubscription(screenAudioPub, sub.screen)
        }

        this.updateParticipant(lkParticipant)
    }

    private updateSubscriptions(participants: Participant[]) {
        participants.forEach((participant) => {
            if (participant.id === rootStore.users.localUserId) return

            const subs = getParticipantSubscriptions(
                participant,
                this.featureFlagService
            )

            participant.allAvIds.forEach((avId) => {
                const lkParticipant = this.room?.participants.get(avId)
                if (!lkParticipant) return

                const isCurrent = avId === participant.currentAvId

                if (isCurrent) {
                    this.updateSubscription(lkParticipant, subs)
                } else {
                    // only media for currentAvId is displayed, so unsubscribe otherwise
                    this.updateSubscription(lkParticipant, {
                        audio: TrackSubscriptionState.UNSUBSCRIBED,
                        video: TrackSubscriptionState.UNSUBSCRIBED,
                        screen: TrackSubscriptionState.UNSUBSCRIBED,
                    })
                }
            })
        })
    }

    private async setMicEnabled(enable: boolean) {
        // NOTE: If meeting state is not joined-meeting, the joined-meeting handler will setLocalAudio
        // to the correct value at that point.
        if (!this.room || this.room.state !== ConnectionState.Connected) {
            throw new Error(
                'LiveKitService: setMicEnabled called before call joined'
            )
        }
        logger.debug(
            `LiveKitService: Turning microphone ${enable ? 'on' : 'off'}`
        )

        try {
            await this.room.localParticipant.setMicrophoneEnabled(enable)
        } catch (audioError) {
            await this.handleDevicesError({ audioError })
        }

        this.updateParticipant(this.room.localParticipant)
    }

    private async setCameraEnabled(enable: boolean) {
        // NOTE: If meeting state is not joined-meeting, the joined-meeting handler will setLocalAudio
        // to the correct value at that point.
        if (!this.room || this.room.state !== ConnectionState.Connected) {
            throw new Error(
                'LiveKitService: setCameraEnabled called before call joined'
            )
        }
        logger.debug(`LiveKitService: Turning camera ${enable ? 'on' : 'off'}`)

        const { reduceUploadBandwidth } = rootStore.audioVideo

        const { options, publishOptions } = getVideoOptions(
            this.featureFlagService,
            reduceUploadBandwidth
        )

        try {
            await this.room.localParticipant.setCameraEnabled(
                enable,
                options,
                publishOptions
            )
        } catch (videoError) {
            await this.handleDevicesError({ videoError })
        }

        this.updateParticipant(this.room.localParticipant)
    }

    private async setMicDevice(deviceId: string) {
        if (!this.room) return

        logger.debug(
            `LiveKitService.setMicDevice: Setting audioDeviceId=${deviceId}`
        )
        await this.room.switchActiveDevice('audioinput', deviceId)
        // mic can get disabled if a device is unplugged
        if (
            !this.room.localParticipant.isMicrophoneEnabled &&
            rootStore.audioVideo.micEnabled
        ) {
            await this.room.localParticipant.setMicrophoneEnabled(true)
        }
        this.updateParticipant(this.room.localParticipant)
    }

    private async setCameraDevice(deviceId: string) {
        if (!this.room) {
            logger.info(
                `LiveKitService: attempting to set camera device to ${deviceId} before room is connected`
            )
            return
        }

        logger.debug(
            `LiveKitService: setCameraDevice: Setting videoDeviceId=${deviceId}`
        )
        await this.room.switchActiveDevice('videoinput', deviceId)

        // camera can get disabled if a device is unplugged
        if (
            !this.room.localParticipant.isCameraEnabled &&
            rootStore.audioVideo.cameraEnabled
        ) {
            await this.room.localParticipant.setCameraEnabled(true)
        }
        this.updateParticipant(this.room.localParticipant)
    }

    private async getScreenShareStreams(
        preset: t.ScreenSharePreset,
        audioOnlyCapture?: boolean
    ) {
        return getScreenShareStreams(
            this.platformInfo,
            this.features,
            this.featureFlagService,
            preset,
            audioOnlyCapture
        )
    }

    private async startScreenShare(
        screenStreams: MediaStream[],
        preset: t.ScreenSharePreset
    ) {
        if (this.room?.localParticipant) {
            // we don't support multi screen for livekit yet. The UI should only allow them
            // to select one anyways if using livekit but just grab the first one here
            // so the API is the same
            const screenStream = screenStreams[0]
            const screenTrack = screenStream.getVideoTracks()[0]
            const { width, height } = screenTrack.getSettings() // (1)

            const { lowScaleResolutionDownBy, fps, kbps } =
                getScreenShareParameters(this.featureFlagService, preset)
            const { screenShareSimulcast, screenShareSecondLayer } =
                this.features

            const screenShareEncoding: VideoEncoding = {
                // (2)
                maxBitrate: kbps.high * 1000,
                maxFramerate: fps.high,
            }

            let screenShareSimulcastLayers: VideoPreset[] | undefined
            if (width && height) {
                const screenLowLayer = new VideoPreset(
                    Math.floor(width / lowScaleResolutionDownBy),
                    Math.floor(height / lowScaleResolutionDownBy),
                    kbps.low * 1000,
                    fps.low
                )

                // LiveKit automatically adds an 'original' simulcast layer
                // based on the published track's resolution (1) and video encoding (2),
                // so we only need the low layer in this list
                screenShareSimulcastLayers = screenShareSecondLayer
                    ? [screenLowLayer]
                    : []
            } else {
                logger.warn(
                    'LiveKitService: screenTrack.getSettings() returned an invalid width or height'
                )
            }

            await this.room.localParticipant.publishTrack(screenTrack, {
                source: Track.Source.ScreenShare,
                ...(screenShareSimulcast && {
                    simulcast: true,
                    screenShareEncoding,
                    screenShareSimulcastLayers,
                }),
            })

            if (screenStream.getAudioTracks().length > 0) {
                logger.debug(`LiveKitService: Shared audio within screenshare`)
                await this.room.localParticipant.publishTrack(
                    screenStream.getAudioTracks()[0],
                    {
                        source: Track.Source.ScreenShareAudio,
                    }
                )
            }
            this.updateParticipant(this.room.localParticipant)

            logger.debug(
                `LiveKitService: Started screenshare with stream id: ${screenStream.id} and preset: ${preset}`
            )
            this.analytics.track(TrackingConstants.ScreenShareStarted, {
                preset,
            })
        }
    }

    private async stopScreenShare() {
        logger.debug(`LiveKitService: Screenshare has stopped`)
        await this.room?.localParticipant.setScreenShareEnabled(false)
    }

    async destroy(): Promise<void> {
        this.events.removeAllListeners()
        this.room?.removeAllListeners()

        logger.debug('LiveKitService.onDestroy calling leave()')
        await this.leave()
        this.room = undefined

        rootStore.audioVideo.updateState(t.AVState.None)

        while (this.reactionDisposers.length) this.reactionDisposers.pop()?.()
    }

    async getNetworkQuality(): Promise<t.INetworkQuality | undefined> {
        if (!this.room) {
            return
        }

        return getNetworkQuality(this.room.localParticipant)
    }

    async getDebugData(): Promise<t.AVDebugData> {
        const meetingId = (await this.getCallSessionId()) ?? ''
        const localParticipantAvId = this.getLocalParticipantSessionId() ?? ''

        const dashboardUrl =
            meetingId &&
            localParticipantAvId &&
            `https://cloud.livekit.io/projects/p_56465b4ges2/sessions/${meetingId}/participants/${localParticipantAvId}`

        if (dashboardUrl) {
            logger.info('LiveKitService: Got dashboard url', dashboardUrl)
        }

        return {
            dashboardUrl,
            provider: this.provider,
            state: this.room?.state ?? '',
            meetingId,
            localParticipantAvId,
            constraints: getVideoParameters(this.featureFlagService),
            participants: this.getParticipants().map((p) => ({
                name: rootStore.users.getUserById(p.identity)?.fullName ?? '',
                userId: p.identity,
                avId: p.sid,
                isLocalUser: p.identity === rootStore.users.localUserId,
                isCameraEnabled: p.isCameraEnabled,
                isMicrophoneEnabled: p.isMicrophoneEnabled,
                isScreenShareEnabled: p.isScreenShareEnabled,
                joinedAt: p.joinedAt?.toISOString() ?? '',
                subscribed: this.getSubscriptionsDump(p.sid),
            })),
        }
    }

    async getBandwidthUsage() {
        const participants = this.getParticipants()

        const sources = [
            Track.Source.Microphone,
            Track.Source.Camera,
            Track.Source.ScreenShare,
        ] as const

        const keyMap = {
            [Track.Source.Microphone]: 'microphone',
            [Track.Source.Camera]: 'camera',
            [Track.Source.ScreenShare]: 'screen',
        } as const

        const initItem = {
            microphone: 0,
            camera: 0,
            screen: 0,
            total: 0,
        }

        const init: t.AVBandwidthUsage = {
            upload: { ...initItem },
            download: { ...initItem },
            subscriptions: { ...initItem },
            staged: { ...initItem },
            tracks: { ...initItem },
            highResSubscriptions: { ...initItem },
        }

        return participants.reduce((info, p) => {
            for (const source of sources) {
                const pub = p.getTrack(source)
                const key = keyMap[source]
                const bitrate = Math.round(
                    (pub?.track?.currentBitrate ?? 0) / 1000
                )
                if (p.identity === rootStore.users.localUserId) {
                    info.upload[key] += bitrate
                    info.upload.total += bitrate
                } else {
                    const hasTrack =
                        !pub?.isMuted && !!pub?.track?.mediaStreamTrack

                    info.download[key] += bitrate
                    info.download.total += bitrate

                    if (pub?.isSubscribed) {
                        if (pub.isEnabled) {
                            info.subscriptions[key]++
                            info.subscriptions.total++

                            if (
                                (pub as RemoteTrackPublication).videoQuality ===
                                toLiveKitVideoQuality(
                                    t.ParticipantSimulcastLayer.High
                                )
                            ) {
                                if (source === Track.Source.Camera) {
                                    info.highResSubscriptions.camera++
                                    info.highResSubscriptions.total++
                                }
                                if (source === Track.Source.ScreenShare) {
                                    info.highResSubscriptions.screen++
                                    info.highResSubscriptions.total++
                                }
                            }
                        } else {
                            info.staged[key]++
                            info.staged.total++
                        }
                    }

                    if (hasTrack) {
                        info.tracks[key]++
                        info.tracks.total++
                    }
                }
            }
            return info
        }, init)
    }

    getPeerConnections() {
        if (!this.room) return

        return {
            sendConn: this.room.localParticipant.engine.publisher?.pc,
            recvConn: this.room.localParticipant.engine.subscriber?.pc,
        }
    }

    private async join() {
        this.joinStartTime = Date.now()
        try {
            if (!this.room) {
                await this.init()
            }
            const { callId, shardId } = rootStore.audioVideo
            const { localUserId } = rootStore.users

            if (!localUserId || !callId || !shardId) {
                throw new Error('Cannot join call when callId not assigned')
            }

            const opts = await getConnectOptions(shardId)

            logger.info('LiveKitService: Joining LiveKit room ' + callId)

            await this.leave()

            const { videoDefaultLayer, screenDefaultLayer } =
                getVideoParameters(this.featureFlagService)

            rootStore.audioVideo.updateDefaultLayers(
                videoDefaultLayer,
                screenDefaultLayer
            )

            const roomOptions = getRoomOptions()

            logger.debug(
                `LiveKitService: set captureOptions with micId ${rootStore.audioVideo.micId} cameraId ${rootStore.audioVideo.cameraId}`
            )

            if (this.room) {
                await this.room.disconnect()
            }
            this.room = new Room(roomOptions)
            await this.room.connect(opts.url, opts.token, opts.options)

            this.room
                .on(
                    RoomEvent.ParticipantConnected,
                    this.handleParticipantConnected
                )
                .on(
                    RoomEvent.ParticipantDisconnected,
                    this.handleParticipantDisconnected
                )
                .on(RoomEvent.Reconnecting, this.handleReconnecting)
                .on(RoomEvent.Reconnected, this.handleReconnected)
                .on(RoomEvent.TrackSubscribed, this.handleTrackSubscribed)
                .on(RoomEvent.TrackUnsubscribed, this.handleTrackUnsubscribed)
                .on(RoomEvent.TrackPublished, this.handleTrackPubChanged)
                .on(RoomEvent.TrackUnpublished, this.handleTrackPubChanged)
                .on(RoomEvent.TrackMuted, this.handleTrackPubChanged)
                .on(RoomEvent.TrackUnmuted, this.handleTrackPubChanged)
                .on(RoomEvent.LocalTrackPublished, this.handleTrackPubChanged)
                .on(
                    RoomEvent.LocalTrackUnpublished,
                    this.handleLocalTrackUnpublished
                )
                .on(RoomEvent.MediaDevicesError, () => {
                    void this.handleDevicesError()
                })
                .on(RoomEvent.Disconnected, () => {
                    this.handleDisconnected()
                })
                .on(
                    RoomEvent.ConnectionQualityChanged,
                    this.handleConnectionQualityChanged
                )
                .on(
                    RoomEvent.ConnectionStateChanged,
                    this.handleConnectionStateChanged
                )

            await this.joinedMeeting()

            this.currentCallId = callId

            if (rootStore.audioVideo.screenStreams.length) {
                try {
                    await rootStore.audioVideo.startScreenShare()
                } catch (error) {
                    logger.error('LiveKitService.join startScreenShare', {
                        error,
                    })
                }
            }

            logger.info(
                `LiveKitService: Joined call ${callId} with avId ${this.room.localParticipant.sid}`
            )

            this.analytics.track(TrackingConstants.JoinedCall, {
                avProvider: AVProvider.LiveKit,
                livekitSession: this.room.sid,
                livekitParticipant: this.room.localParticipant.sid,
                ms: Date.now() - this.joinStartTime,
            })
        } catch (error) {
            logger.error('LiveKitService: joinRoom', { error })
            Sentry.captureException(error)

            // re-throw
            throw error
        }
    }

    private updateUploadQuality() {
        if (!this.room) return

        const track = this.room.localParticipant.getTrack(
            Track.Source.Camera
        )?.videoTrack

        if (track) {
            const wasCameraEnabled = this.room.localParticipant.isCameraEnabled

            // flush the old capture options
            this.room.localParticipant.unpublishTrack(track)

            // unpublishTrack() turns off the camera, so here we turn it on;
            // setCameraEnabled() will set the new publish options based
            // on `reduceUploadBandwidth`
            if (wasCameraEnabled) {
                void this.setCameraEnabled(true)
            }
        }
    }

    private async joinedMeeting() {
        await rootStore.audioVideo.requestDevices()

        this.initParticipants()

        const avId = this.room?.localParticipant.sid
        this.events.emit(t.MeetingState.JoinedMeeting, avId)
    }

    private handleDevicesError = async (manualEnableErrors?: {
        audioError?: any
        videoError?: any
    }) => {
        if (!this.room) {
            return
        }
        const audioError =
            manualEnableErrors?.audioError ??
            this.room.localParticipant.lastMicrophoneError
        const videoError =
            manualEnableErrors?.videoError ??
            this.room.localParticipant.lastCameraError

        if (!audioError && !videoError) {
            return
        }
        const audioFailure = MediaDeviceFailure.getFailure(audioError)
        const videoFailure = MediaDeviceFailure.getFailure(videoError)

        if (audioFailure) {
            /* This is a cross vendor metric!

                @danger
                @metric client-call_device-error
             */
            logger.error(
                `LiveKitService: Audio Error: ${audioFailure} ${audioError?.message}`,
                {
                    action: 'Call@DeviceError',
                    type: 'audio',
                    kind: audioFailure,
                    error: audioError?.message,
                }
            )
        }
        if (videoFailure) {
            /* This is a cross-vendor metric!

                @danger
                @metric client-call_device-error
             */
            logger.error(
                `LiveKitService: Camera Error: ${videoFailure} ${videoError?.message}`,
                {
                    action: 'Call@DeviceError',
                    type: 'video',
                    kind: videoFailure,
                    error: videoError?.message,
                }
            )
        }

        const failure = audioFailure ?? videoFailure

        switch (failure) {
            case MediaDeviceFailure.DeviceInUse:
                this.handleInUseError(!audioError, !videoError)
                break
            case MediaDeviceFailure.NotFound:
                this.handleNotFoundError(!audioError, !videoError)
                break
            case MediaDeviceFailure.PermissionDenied:
                // if manualEnableErrors.audioError or manualEnableErrors.videoError,
                // then the error happened when the user is manually enabling their
                // mic/camera, so we pass the error to the store immediately
                if (
                    manualEnableErrors?.audioError &&
                    audioFailure === MediaDeviceFailure.PermissionDenied
                ) {
                    rootStore.audioVideo.updateMicError(
                        t.AVDeviceError.PermissionDenied
                    )
                }
                if (
                    manualEnableErrors?.videoError &&
                    videoFailure === MediaDeviceFailure.PermissionDenied
                ) {
                    rootStore.audioVideo.updateCameraError(
                        t.AVDeviceError.PermissionDenied
                    )
                }

                // otherwise, call handlePermissionDeniedError, which gives time for the
                // bad device to wake up before checking again
                if (
                    !manualEnableErrors?.audioError &&
                    !manualEnableErrors?.videoError
                ) {
                    await this.handlePermissionDeniedError()
                }
                break
        }
    }

    private handleConnectionQualityChanged = (quality: ConnectionQuality) => {
        logger.info(`LiveKitService: connection quality changed to ${quality}`)

        if (quality === ConnectionQuality.Poor) {
            this.analytics.track(TrackingConstants.NetworkQualityLow)
        }
    }

    private handleConnectionStateChanged = (roomState: ConnectionState) => {
        logger.info(
            `LiveKitService: room connection state changed to ${roomState}`
        )
    }

    private handleInUseError(audio: boolean, video: boolean) {
        if (!this.platformInfo.isWindows) return
        if (audio) {
            rootStore.audioVideo.updateMicError(t.AVDeviceError.InUse)
        }
        if (video) {
            rootStore.audioVideo.updateCameraError(t.AVDeviceError.InUse)
        }
    }

    private handleNotFoundError(audio: boolean, video: boolean) {
        if (audio) {
            rootStore.audioVideo.updateMicError(t.AVDeviceError.NotFound)
        }
        if (video) {
            rootStore.audioVideo.updateCameraError(t.AVDeviceError.NotFound)
        }
    }

    private async handlePermissionDeniedError() {
        // ignore error if no camera found
        const devices = await LiveKitRoom.getLocalDevices('videoinput')
        if (devices.length > 0) {
            // this might be because device is 'waking up' wait and check again
            window.clearTimeout(this.checkErrorTimeout)
            this.checkErrorTimeout = window.setTimeout(() => {
                void this.checkDevicePermissions()
            }, 2000)
        }
    }

    private handleDisconnected = () => {
        logger.info('LiveKitService: Disconnected from LiveKit')
        rootStore.audioVideo.updateState(t.AVState.Disconnected)
        this.removeParticipants()
    }

    private getParticipantMedia(lkParticipant: LiveKitParticipant): t.AVMedia {
        const audioPub = lkParticipant.getTrack(Track.Source.Microphone)
        const videoPub = lkParticipant.getTrack(Track.Source.Camera)
        const screenPub = lkParticipant.getTrack(Track.Source.ScreenShare)
        const screenAudioPub = lkParticipant.getTrack(
            Track.Source.ScreenShareAudio
        )

        const local = lkParticipant instanceof LocalParticipant

        const videoBlocked =
            local && !!this.room?.localParticipant.lastCameraError
        const audioBlocked =
            local && !!this.room?.localParticipant.lastMicrophoneError

        return {
            videoIsOn: !!videoPub && !videoPub.isMuted,
            audioIsOn: !!audioPub && !audioPub.isMuted,
            videoState: videoBlocked
                ? 'blocked'
                : this.getTrackStatusFromPub(videoPub),
            audioState: audioBlocked
                ? 'blocked'
                : this.getTrackStatusFromPub(audioPub),
            screenVideoStates: [this.getTrackStatusFromPub(screenPub)],

            // pub.track will be undefined if we are not subscribed to the participant
            videoTrack: videoPub?.track?.mediaStreamTrack,
            audioTrack: audioPub?.track?.mediaStreamTrack,
            screenVideoTracks: screenPub?.track?.mediaStreamTrack
                ? [screenPub?.track?.mediaStreamTrack]
                : [],
            screenAudioTrack: screenAudioPub?.track?.mediaStreamTrack,
            screenAudioState: this.getTrackStatusFromPub(screenAudioPub),
        }
    }

    private updateParticipant(lkParticipant?: LiveKitParticipant): void {
        if (!lkParticipant) return

        const participant = rootStore.participants.findParticipantById(
            lkParticipant.identity
        )

        const media = this.getParticipantMedia(lkParticipant)

        participant?.updateMedia({
            avId: lkParticipant.sid,
            local: lkParticipant instanceof LocalParticipant,
            media,
        })
    }

    private removeParticipant(lkParticipant: LiveKitParticipant) {
        const participant = rootStore.participants.findParticipantById(
            lkParticipant.identity
        )
        participant?.resetMedia({
            avId: lkParticipant.sid,
            persistTracks: true,
        })
    }

    private removeParticipants() {
        const { participants } = rootStore.participants
        participants.forEach((participant) => {
            participant.resetAllMedia({
                persistTracks: true,
            })
        })
    }

    /**
     * Hydrate participants added to the store after the LiveKit call is joined.
     */
    private registerHydrateReaction() {
        this.reactionDisposers.push(
            reaction(
                () => rootStore.participants.participantsInSameAVConnectedSpace,
                (participants, prevParticipants) => {
                    const lkParticipantsByIdentity = new Map(
                        this.getParticipants().map((lkParticipant) => [
                            lkParticipant.identity,
                            lkParticipant,
                        ])
                    )

                    const prevIds = new Set(prevParticipants?.map((p) => p.id))
                    const newParticipants = participants.filter(
                        (p) => !prevIds.has(p.id)
                    )

                    newParticipants.forEach((p) =>
                        this.updateParticipant(
                            lkParticipantsByIdentity.get(p.id)
                        )
                    )
                },
                { fireImmediately: true }
            )
        )
    }

    private async checkDevicePermissions() {
        const participant = this.room?.localParticipant
        if (!participant) return

        const audioFailure = MediaDeviceFailure.getFailure(
            this.room?.localParticipant.lastMicrophoneError
        )
        const videoFailure = MediaDeviceFailure.getFailure(
            this.room?.localParticipant.lastCameraError
        )

        if (audioFailure === MediaDeviceFailure.PermissionDenied) {
            logger.warn('LiveKitService: audio capture disallowed')
            rootStore.audioVideo.updateMicError(
                t.AVDeviceError.PermissionDenied
            )
        }
        if (videoFailure === MediaDeviceFailure.PermissionDenied) {
            logger.warn('LiveKitService: video capture disallowed')
            rootStore.audioVideo.updateCameraError(
                t.AVDeviceError.PermissionDenied
            )
        }
    }

    private initParticipant(p: LiveKitParticipant) {
        logger.info(
            `LiveKitService: Initializing media for ${p.identity} / ${p.sid}`
        )
        this.updateParticipant(p)
    }

    private initParticipants(): void {
        const participants = this.getParticipants()
        participants.forEach((p) => this.initParticipant(p))
    }

    public getSubscriptionsDump(avId: string) {
        const lkParticipant = this.room?.participants.get(avId)
        if (!lkParticipant) return null

        return {
            video:
                lkParticipant.getTrack(Track.Source.Camera)?.isSubscribed ??
                false,
            audio:
                lkParticipant.getTrack(Track.Source.Microphone)?.isSubscribed ??
                false,
            screenVideo:
                lkParticipant.getTrack(Track.Source.ScreenShare)
                    ?.isSubscribed ?? false,
        }
    }

    private async requestUpdatedInputState(): Promise<t.AVInputDevices> {
        try {
            const inputs = await getUpdatedInputDevices(this.room)
            logger.info(`LiveKitService: input devices`, inputs)
            return inputs
        } catch {
            await this.handleDevicesError()
            return { micId: null, cameraId: null }
        }
    }

    private updateParticipantReceiveQuality(
        participantId: string,
        source: keyof ReceiveQualities,
        quality: VideoQuality
    ) {
        const receiveQualities =
            this.participantReceiveQualities.get(participantId) ?? {}
        receiveQualities[source] = quality
        this.participantReceiveQualities.set(participantId, receiveQualities)
    }

    private async setParticipantSimulcastLayer(
        participantId: string,
        source: 'video' | 'screen',
        layer: t.ParticipantSimulcastLayer
    ) {
        const quality = toLiveKitVideoQuality(layer)
        this.updateParticipantReceiveQuality(participantId, source, quality)

        const participant =
            rootStore.participants.findParticipantById(participantId)
        if (!participant) return

        participant.allAvIds.forEach((avId) => {
            const lkParticipant = this.room?.participants.get(avId)
            if (!lkParticipant) return

            const track = lkParticipant.getTrack(
                source === 'video'
                    ? Track.Source.Camera
                    : Track.Source.ScreenShare
            )
            if (track?.isSubscribed) {
                track.setVideoQuality(quality)
            }
        })
    }

    private async setParticipantVideoSimulcastLayer(
        participantId: string,
        layer: t.ParticipantSimulcastLayer
    ) {
        try {
            logger.debug(
                `LiveKitService.setParticipantVideoSimulcastLayer: ${participantId} layer=${layer}`
            )
            await this.setParticipantSimulcastLayer(
                participantId,
                'video',
                layer
            )
        } catch (error: unknown) {
            logger.error(`LiveKitService.setParticipantVideoSimulcastLayer`, {
                error,
            })
        }
    }

    private async setParticipantScreenShareSimulcastLayer(
        participantId: string,
        layer: t.ParticipantSimulcastLayer
    ) {
        try {
            logger.debug(
                `LiveKitService.setParticipantScreenShareSimulcastLayer: ${participantId} layer=${layer}`
            )
            await this.setParticipantSimulcastLayer(
                participantId,
                'screen',
                layer
            )
        } catch (error: unknown) {
            logger.error(
                `LiveKitService.setParticipantScreenShareSimulcastLayer`,
                { error }
            )
        }
    }

    private handleParticipantConnected = (participant: RemoteParticipant) => {
        this.initParticipant(participant)
    }

    private handleParticipantDisconnected = (
        participant: RemoteParticipant
    ) => {
        this.removeParticipant(participant)
    }

    private handleTrackPubChanged = (
        _: any,
        participant: LiveKitParticipant
    ) => {
        if (participant instanceof RemoteParticipant) {
            this.updateParticipant(participant)
        }
    }

    private handleLocalTrackUnpublished = (
        pub: LocalTrackPublication,
        participant: LocalParticipant
    ) => {
        if (pub.source === Track.Source.ScreenShare) {
            logger.debug('LiveKitService: stopping screen share')
            void this.stopScreenShare()
        }
        this.updateParticipant(participant)
    }

    private handleTrackSubscribed = (
        _track: RemoteTrack,
        publication: RemoteTrackPublication,
        participant: RemoteParticipant
    ) => {
        if (publication.source === Track.Source.Camera) {
            const quality =
                this.participantReceiveQualities.get(participant.identity)
                    ?.video ??
                toLiveKitVideoQuality(rootStore.audioVideo.defaultVideoLayer)
            publication.setVideoQuality(quality)
        } else if (publication.source === Track.Source.ScreenShare) {
            const quality =
                this.participantReceiveQualities.get(participant.identity)
                    ?.screen ??
                toLiveKitVideoQuality(rootStore.audioVideo.defaultScreenLayer)
            publication.setVideoQuality(quality)
        }

        this.updateParticipant(participant)
    }

    private handleTrackUnsubscribed = (
        _track: RemoteTrack,
        _publication: RemoteTrackPublication,
        participant: RemoteParticipant
    ) => {
        this.updateParticipant(participant)
    }

    private handleReconnecting = () => {
        rootStore.audioVideo.updateState(t.AVState.Reconnecting)
    }

    private handleReconnected = () => {
        rootStore.audioVideo.updateState(t.AVState.Connected)
        this.initParticipants()
    }

    private getTrackStatusFromPub(pub?: TrackPublication): t.TrackState {
        if (pub === undefined || pub.isMuted) {
            return 'off'
        }
        if (!pub.isSubscribed) {
            return 'sendable'
        }
        return 'playable'
    }

    @event(t.MeetingState.JoinedMeeting)
    declare readonly onJoined: ListenerRegistrationFn<string>
}
