import { RoomOptions } from 'livekit-client'

import rootStore from '@teamflow/store'

export function getRoomOptions(): RoomOptions {
    return {
        dynacast: true,
        // we don't use track.attach() and instead manage simulcast/visibility manually
        adaptiveStream: false,

        videoCaptureDefaults: {
            deviceId: rootStore.audioVideo.cameraId ?? undefined,
        },

        audioCaptureDefaults: {
            deviceId: rootStore.audioVideo.micId ?? undefined,
            autoGainControl: true,
            echoCancellation: true,
            noiseSuppression: true,
        },

        // video capture/publish options are set when the track is actually published
    }
}
