import { autorun } from 'mobx'
import { useCallback, useEffect, useState } from 'react'

import { useFeatureFlag } from '@teamflow/bootstrap'
import rootStore from '@teamflow/store'
import { Feature } from '@teamflow/types'

import { AudioPlaybackManager } from '../helpers/AudioPlaybackManager'

/**
 * Returns a play() function that can be called to play the audio. The sinkId
 * is set according to rootStore.audio.speakerId, and the volume is set according to
 * rootStore.settings.masterVolume.
 */
export function useAudio(
    url: string,
    ignoreMasterVolume = false
): {
    play: () => void
    sinkIdSupported: boolean
} {
    const [manager, setManager] = useState<AudioPlaybackManager | null>(null)
    const [sinkIdSupported, setSinkIdSupported] = useState(false)

    const { enabled: masterVolumeControl } = useFeatureFlag({
        flag: Feature.MasterVolumeControl,
    })

    useEffect(() => {
        const audioEl = new Audio()

        const newManager = new AudioPlaybackManager(
            audioEl,
            false,
            undefined,
            'sfx'
        )
        setManager(newManager)
        setSinkIdSupported(newManager.sinkIdSupported)

        return () => {
            newManager.destroy()

            // elements created using Audio() are subject to garbage collection
            // only if paused or playback ends
            audioEl.pause()
        }
    }, [])

    useEffect(() => {
        if (manager && masterVolumeControl) {
            return autorun(() => {
                manager.changeVolume(
                    ignoreMasterVolume ? 1 : rootStore.settings.masterVolume
                )
            })
        }

        return undefined
    }, [ignoreMasterVolume, manager, masterVolumeControl])

    useEffect(() => {
        if (manager) {
            return autorun(() => {
                const { speakerId } = rootStore.audioVideo
                if (!speakerId) return

                manager.setSinkId(speakerId)
            })
        }

        return undefined
    }, [manager])

    useEffect(() => {
        if (manager) {
            manager.changeTrack(url)
        }
    }, [manager, url])

    const play = useCallback(() => {
        if (manager) {
            manager.play()
        }
    }, [manager])

    return { play, sinkIdSupported }
}
