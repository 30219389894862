import * as t from '@teamflow/types'

export const getVideoParameters = (
    sharedFlags: t.IFeatureFlagService
): t.AVConstraints => {
    const {
        VIDEO_WIDTH,
        VIDEO_HEIGHT,
        VIDEO_LOW_SCALE_DOWN,
        VIDEO_KBPS_LOW,
        VIDEO_KBPS_HIGH,
        VIDEO_FPS_LOW,
        VIDEO_FPS_HIGH,
        VIDEO_DEFAULT_LAYER,
        SCREEN_DEFAULT_LAYER,
    } = sharedFlags.remoteJsonSync('json-video-parameters')

    return {
        videoResolution: {
            width: VIDEO_WIDTH,
            height: VIDEO_HEIGHT,
        },
        videoLowScaleDown: VIDEO_LOW_SCALE_DOWN,
        videoKbps: {
            low: VIDEO_KBPS_LOW,
            high: VIDEO_KBPS_HIGH,
        },
        videoFps: {
            low: VIDEO_FPS_LOW,
            high: VIDEO_FPS_HIGH,
        },
        videoDefaultLayer: VIDEO_DEFAULT_LAYER,
        screenDefaultLayer: SCREEN_DEFAULT_LAYER,
    }
}
